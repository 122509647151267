import * as React from 'react';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { TruckDetailsSidebarDataT } from './models';
import history from 'common/utils/history';
import useCommonUrlFactoryContext from 'common/utils/hooks/useCommonUrlFactoryContext';
import CommonEditableTruckDetailsLayout from 'common/layouts/CommonEditableTruckDetailsLayout/CommonEditableTruckDetailsLayout';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

type PropsT = SidebarContentPropsT<TruckDetailsSidebarDataT, CommonSidebarDataT>;

const EditableTruckDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onGoBack, onClose, setNeedCloseConfirmation, onOpenNextSidebar } = props;

    const commonUrlFactory = useCommonUrlFactoryContext();

    const partnerContext = usePartnerContext();

    const partnerId = data.partnerId || partnerContext.partnerId;
    const partnerType = partnerContext.partnerType || null;

    if (!onClose) {
        return null;
    }

    const handleShowMap = () => {
        history.push(commonUrlFactory.truckDetails(data.partnerId, data.truckId));
    };

    const handleOpenTruckDetails = (truckId: TruckIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.truckDetails,
            partnerId,
            truckId,
        });
    };

    const handleOpenContactDetails = (contractId: CarrierContractIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.carrierContract,
            contractId,
            initIsEditing: false,
        });
    };

    const handleOpenUserDetails = (userId: UserIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const handleOpenTrailerDetails = (trailerId: TrailerIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.trailerDetails,
            partnerId,
            trailerId,
        });
    };

    return (
        <CommonEditableTruckDetailsLayout
            truckId={data.truckId}
            partnerId={data.partnerId}
            isDisableShowOnMap={!!data.isDisableShowOnMap}
            isReadOnly={!!data.isReadOnly}
            onClose={onClose}
            onGoBack={onGoBack || undefined}
            isShowMap={false}
            onToggleShowMap={handleShowMap}
            setNeedCloseConfirmation={setNeedCloseConfirmation}
            onOpenTrailerDetails={handleOpenTrailerDetails}
            onOpenTruckDetails={handleOpenTruckDetails}
            onOpenContactDetails={handleOpenContactDetails}
            onOpenUserDetails={handleOpenUserDetails}
        />
    );
};

export default EditableTruckDetailsSidebarContent;
