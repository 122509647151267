import * as React from 'react';

import cs from 'classnames';

import classNames from 'classnames/bind';
import styles from './Logo.scss';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    isSmall?: boolean;
};

const Logo: React.FC<PropsT> = React.memo((props) => {
    const { className, isSmall } = props;

    return <div className={cs(cx('logo', { 'logo--isSmall': isSmall }), className)} />;
});

export default Logo;
