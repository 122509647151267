import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { TabEnum } from './constants';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchTrailersPageQueryT } from 'common/store/trailers/models';
import { TrailersSortEnum, TrailerStatusEnum } from 'common/utils/api/models';
import { PageSortT } from 'common/utils/query';
import { ALL_BROKER_USERS_VALUE } from 'common/components/dropdowns/UserMultipleDropdown/constants';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<TrailersSortEnum> | null,
    activeTab: TabEnum,
): FetchTrailersPageQueryT => {
    const includeStatuses: TrailerStatusEnum[] = [];
    const status = queryFilters[QueryFiltersKeysEnum.status] as Array<TrailerStatusEnum>;
    if (status?.length) {
        includeStatuses.push(...status);
    }

    let excludeStatuses: TrailerStatusEnum[] = [];
    if (activeTab === TabEnum.archive) {
        excludeStatuses = [
            ...excludeStatuses,
            TrailerStatusEnum.free,
            TrailerStatusEnum.inTransit,
            TrailerStatusEnum.unavailable,
        ];
    } else {
        excludeStatuses = [...excludeStatuses, TrailerStatusEnum.archived];
    }

    const parsedTrailerType = queryFilters?.[QueryFiltersKeysEnum.dictTrailerTypeId]?.map((typeId) => {
        return parseInt(typeId, 10);
    });

    const lastModifiedByUsers = queryFilters[QueryFiltersKeysEnum.lastModifyByUserId]?.filter((userId) => {
        return userId !== ALL_BROKER_USERS_VALUE;
    });

    const lastModifiedByBroker = !!queryFilters[QueryFiltersKeysEnum.lastModifyByUserId]?.some((userId) => {
        return userId === ALL_BROKER_USERS_VALUE;
    });

    const addedByUsers = queryFilters[QueryFiltersKeysEnum.createdByUserId]?.filter((userId) => {
        return userId !== ALL_BROKER_USERS_VALUE;
    });

    const addedByBroker = !!queryFilters[QueryFiltersKeysEnum.createdByUserId]?.some((userId) => {
        return userId === ALL_BROKER_USERS_VALUE;
    });

    return {
        includeStatuses,
        excludeStatuses,

        trailerType: parsedTrailerType?.length ? parsedTrailerType : undefined,
        plateNumber: queryFilters[QueryFiltersKeysEnum.plateNumber] || undefined,

        countriesOfRegistration: queryFilters?.[QueryFiltersKeysEnum.country] || undefined,

        telematicUpdatedDateFrom: queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateFrom] || undefined,
        telematicUpdatedDateTo: queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateTo] || undefined,

        contractIds: queryFilters?.[QueryFiltersKeysEnum.contractId] || undefined,

        addedByBroker: addedByBroker || undefined,
        addedByUsers: addedByUsers?.length ? addedByUsers : undefined,

        createdDateFrom: queryFilters[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        createdDateTo: queryFilters[QueryFiltersKeysEnum.createdDateTo] || undefined,

        lastModifiedByBroker: lastModifiedByBroker || undefined,
        lastModifiedByUsers: lastModifiedByUsers?.length ? lastModifiedByUsers : undefined,

        updatedDateFrom: queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom] || undefined,
        updatedDateTo: queryFilters[QueryFiltersKeysEnum.lastModifyDateTo] || undefined,

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
