import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './ContractName.scss';
import cs from 'classnames';
import { TFunction } from 'i18next';

export type PropsT = {
    className?: string;
    name?: string;
    nameFallback?: string | null;
};

const cx = classNames.bind(styles);

export const getContractName = (name: string | undefined, t: TFunction): string => {
    return name || t('common:carrier-contracts.table.empty-name');
};

const ContractName: React.FC<PropsT> = memo((props) => {
    const { className, name, nameFallback } = props;

    const { t } = useTranslation();

    return (
        <div
            className={cs(
                cx('name', {
                    'name--empty': !name,
                }),
                className,
            )}
        >
            {nameFallback ? name || nameFallback || '' : getContractName(name, t)}
        </div>
    );
});

export default ContractName;
