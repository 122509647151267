import React from 'react';
import classNames from 'classnames/bind';

import styles from './TruckActionHistory.scss';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import { isNonNil } from 'common/utils';

const cx = classNames.bind(styles);

type PropsT = {
    createdDate?: string;
    createdByName?: string;
    createdById?: UserIdT;
    createdByBroker?: boolean;

    modifiedDate?: string;
    modifiedByName?: string;
    modifiedById?: UserIdT;
    modifiedByBroker?: boolean;

    onOpenUserDetails: (userId: UserIdT, isBrokerUser?: boolean) => void;
};

const TruckActionHistory: React.FC<PropsT> = (props) => {
    const {
        createdDate,
        createdByName,
        createdById,
        createdByBroker,
        modifiedDate,
        modifiedByName,
        modifiedById,
        modifiedByBroker,
        onOpenUserDetails,
    } = props;

    const { t } = useTranslation();

    const creationDetailRows: Array<InfoTableRowT> = [
        createdDate
            ? {
                  icon: null,
                  name: t('common:trucks-page.columns.create-date'),
                  rightNode: createdDate ? <DateFormatter date={createdDate} format="DD MMM YYYY, HH:mm" /> : null,
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'create-date',
                  hasBottomBorder: true,
              }
            : null,
        createdDate
            ? {
                  icon: null,
                  name: t('common:trucks-page.columns.created-by'),
                  rightNode: (
                      <UserLinkFormatter
                          fullName={createdByName}
                          userId={createdById}
                          byBroker={createdByBroker}
                          onOpenUserDetails={onOpenUserDetails}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'created-by',
              }
            : null,
    ].filter(isNonNil);

    const modifyDetailRows: Array<InfoTableRowT> = [
        modifiedDate
            ? {
                  icon: null,
                  name: t('common:trucks-page.columns.last-modify-date'),
                  rightNode: modifiedDate ? <DateFormatter date={modifiedDate} format="DD MMM YYYY, HH:mm" /> : null,
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'modify-date',
                  hasBottomBorder: true,
              }
            : null,
        modifiedDate
            ? {
                  icon: null,
                  name: t('common:trucks-page.columns.last-modify-by'),
                  rightNode: (
                      <UserLinkFormatter
                          fullName={modifiedByName}
                          userId={modifiedById}
                          byBroker={modifiedByBroker}
                          onOpenUserDetails={onOpenUserDetails}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'modified-by',
              }
            : null,
    ].filter(isNonNil);

    return (
        <>
            <InfoTable className={cx('table')} rows={creationDetailRows} testSelector="creation-details" />
            <InfoTable className={cx('table')} rows={modifyDetailRows} testSelector="modify-details" />
        </>
    );
};

export default TruckActionHistory;
