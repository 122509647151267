import * as React from 'react';

const useModalDialog = <T extends {}>() => {
    const [data, setData] = React.useState<T | null>(null);

    return React.useMemo(() => {
        return {
            isShow: !!data,
            data,
            setData,
            onClose: () => {
                setData(null);
            },
            onCancel: () => {
                setData(null);
            },
        };
    }, [data]);
};

export default useModalDialog;
