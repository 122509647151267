import {
    AddressTypeEnum,
    ApiInvitePartnerRequestT,
    ApiSystemSettingsT,
    DocumentTypeEnum,
    FixPageableCommonQueryT,
    OverwritePageRequestWithSortQueryT,
    OverwritePageRequestWithSortT,
    PartnerTypeEnum,
    PartnerTypeT,
    TruckStatusEnum,
    UpdateRateStrategyEnum,
} from 'common/utils/api/models';
import BaseTranziitApi, {
    throwNotImplementedError,
    TranziitApiResultT,
} from 'common/utils/api/tranziit/base-tranziit-api';
import toNumber from 'lodash/toNumber';
import { authApi } from 'common/utils/api/auth/factory';
import { MS_IN_MIN } from 'common/utils/time';
import { isNonNil } from 'common/utils';

class BrokerTranziitApi extends BaseTranziitApi {
    private static instance: BrokerTranziitApi;

    private constructor() {
        super(authApi, {
            basepath: '/api',
            timeout: MS_IN_MIN,
        });
    }

    public static getInstance(): BrokerTranziitApi {
        if (!BrokerTranziitApi.instance) {
            BrokerTranziitApi.instance = new BrokerTranziitApi();
        }

        return BrokerTranziitApi.instance;
    }

    getRFQDefaultOffer = async (
        rfqId: Api2Paths.ApiV2Rfqs$RfqIdOffersDefault.Get.Parameters.RfqId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs$RfqIdOffersDefault.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqIdOffersDefault.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/rfqs/${rfqId}/offers/default`,
            expectedResponseContentType: 'json',
        });
    };

    getOrderDetails = async (
        orderId: ApiPaths.ApiV1BrokerOrder$OrderId.Get.Parameters.OrderId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerOrder$OrderId.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerOrder$OrderId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/order/${orderId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchAssetTracksByTransportOrder = async (
        transportationOrderId: Api2Paths.ApiV2BrokerOrders$TransportationOrderIdAssetsCoordinates.Get.Parameters.TransportationOrderId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerOrders$TransportationOrderIdAssetsCoordinates.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerOrders$TransportationOrderIdAssetsCoordinates.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/orders/${transportationOrderId}/assets-coordinates`,
            expectedResponseContentType: 'json',
        });
    };

    fetchTransportOrdersPage = async (
        carrierId: Api2Paths.ApiV2BrokerTransportationOrders$CarrierId.Get.Parameters.CarrierId,
        apiFetchOrdersRequest: OverwritePageRequestWithSortT<Api2Paths.ApiV2BrokerTransportationOrders$CarrierId.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerTransportationOrders$CarrierId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerTransportationOrders$CarrierId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/transportation-orders/${carrierId}`,
            query: apiFetchOrdersRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchShipperContractDetails = async (
        partnerId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Get.Parameters.ShipperId,
        shipperContractId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Get.Parameters.ContractId,
    ): Promise<
        TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Get.Responses.$200>
    > => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies/${partnerId}/shipper-contracts/${shipperContractId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchShipperContractLaneDetails = async (
        partnerId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Get.Parameters.ShipperId,
        shipperContractId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Get.Parameters.ContractId,
        laneId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Get.Parameters.LaneId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Get.Responses.$200>(
            {
                method: 'GET',
                path: `/v1/broker/companies/${partnerId}/shipper-contracts/${shipperContractId}/lanes/${laneId}`,
                expectedResponseContentType: 'json',
            },
        );
    };

    uploadShipperContract = async (
        partnerId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Post.Parameters.ShipperId,
        query: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Post.QueryParameters,
        file: File,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Post.Responses.$201>> => {
        const formData = new FormData();
        formData.append('file', file);

        return this.doFetch<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Post.Responses.$201>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            method: 'POST',
            path: `/v1/broker/companies/${partnerId}/shipper-contracts`,
            query,
            data: formData,
            expectedResponseContentType: 'unknown',
        });
    };

    updateShipperContract = async (
        partnerId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Put.Parameters.ShipperId,
        contractId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Put.Parameters.ContractId,
        query: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Put.QueryParameters,
        file: File | null,
    ): Promise<
        TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Put.Responses.$200>
    > => {
        const formData = new FormData();

        if (file) {
            formData.append('file', file);
        }

        return this.doFetch<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractId.Put.Responses.$200>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            method: 'PUT',
            path: `/v1/broker/companies/${partnerId}/shipper-contracts/${contractId}`,
            query,
            data: formData,
            expectedResponseContentType: 'unknown',
        });
    };

    updateShipperContractLane = async (
        companyId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Put.Parameters.ShipperId,
        contractId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Put.Parameters.ContractId,
        laneId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Put.Parameters.LaneId,
        data: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Put.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Put.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneId.Put.Responses.$200>(
            {
                method: 'PUT',
                path: `/v1/broker/companies/${companyId}/shipper-contracts/${contractId}/lanes/${laneId}`,
                data,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    revokeShipperContractLane = async (
        companyId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneIdRevoke.Put.Parameters.ShipperId,
        contractId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneIdRevoke.Put.Parameters.ContractId,
        laneId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneIdRevoke.Put.Parameters.LaneId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneIdRevoke.Put.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes$LaneIdRevoke.Put.Responses.$200>(
            {
                method: 'PUT',
                path: `/v1/broker/companies/${companyId}/shipper-contracts/${contractId}/lanes/${laneId}/revoke`,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    revokeShipperContract = async (
        partnerId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdRevoke.Put.Parameters.ShipperId,
        contractId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdRevoke.Put.Parameters.ContractId,
        reason: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdRevoke.Put.Parameters.Reason,
    ): Promise<
        TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdRevoke.Put.Responses.$200>
    > => {
        const query: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdRevoke.Put.QueryParameters = {};
        if (reason) {
            query.reason = reason;
        }

        return this.doFetch<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdRevoke.Put.Responses.$200>(
            {
                method: 'PUT',
                path: `/v1/broker/companies/${partnerId}/shipper-contracts/${contractId}/revoke`,
                query,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    fetchTrailersPage = async (
        apiFetchTrailersRequest: OverwritePageRequestWithSortT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Get.QueryParameters>,
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Get.Parameters.CarrierId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trailers`,
            query: apiFetchTrailersRequest,
            expectedResponseContentType: 'json',
        });
    };

    addPartnerTrailer = async (
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Post.RequestBody,
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Post.Parameters.CarrierId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Post.Responses.$201>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers.Post.Responses.$201>({
            data,
            method: 'POST',
            path: `/v2/broker/companies/${carrierId}/trailers`,
            expectedResponseContentType: 'unknown',
        });
    };

    deleteTrailer = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Delete.Parameters.CarrierId,
        trailerId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Delete.Responses.$204>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Delete.Responses.$204>({
            method: 'DELETE',
            path: `/v2/broker/companies/${carrierId}/trailers/${trailerId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    assignTruckToContract = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksChangeContract.Post.Parameters.CarrierId,
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksChangeContract.Post.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksChangeContract.Post.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksChangeContract.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/broker/companies/${carrierId}/trailers/change-contract`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    assignTrailerToContract = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersChangeContract.Post.Parameters.CarrierId,
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersChangeContract.Post.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersChangeContract.Post.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersChangeContract.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/broker/companies/${carrierId}/trailers/change-contract`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    activateTrailer = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersActivate.Put.Parameters.CarrierId,
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersActivate.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersActivate.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersActivate.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/broker/companies/${carrierId}/trailers/activate`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    archiveTrailer = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersArchive.Put.Parameters.CarrierId,
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersArchive.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersArchive.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersArchive.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/broker/companies/${carrierId}/trailers/archive`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchTrailerDetails = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Get.Parameters.CarrierId,
        trailerId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Get.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trailers/${trailerId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchTrailersActiveFleetCount = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersActiveFleetCount.Get.Parameters.CarrierId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersActiveFleetCount.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersActiveFleetCount.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trailers/active-fleet-count`,
            expectedResponseContentType: 'json',
        });
    };

    updateTrailer = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Put.Parameters.CarrierId,
        trailerId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Put.Parameters.Id,
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailers$Id.Put.Responses.$200>({
            data,
            method: 'PUT',
            path: `/v2/broker/companies/${carrierId}/trailers/${trailerId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchActiveTrucksByIdPlateNumber = async (
        plateNumber: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.Parameters.PlateNumber,
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.Parameters.CarrierId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.Responses.$200>> => {
        const apiFetchTrucksRequest: OverwritePageRequestWithSortQueryT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.QueryParameters> =
            {
                plateNumber,
                excludeStatuses: [TruckStatusEnum.archived],
            };

        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trucks`,
            query: apiFetchTrucksRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchTrucksPage = async (
        apiFetchTrucksRequest: OverwritePageRequestWithSortT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.QueryParameters>,
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.Parameters.CarrierId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trucks`,
            query: apiFetchTrucksRequest,
            expectedResponseContentType: 'json',
        });
    };

    addPartnerTruck = async (
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Post.RequestBody,
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Post.Parameters.CarrierId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Post.Responses.$201>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks.Post.Responses.$201>({
            method: 'POST',
            path: `/v2/broker/companies/${carrierId}/trucks`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    activateTruck = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.Parameters.CarrierId,
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/broker/companies/${carrierId}/trucks/activate`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    updatePartnerTruck = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Put.Parameters.CarrierId,
        truckId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Put.Parameters.Id,
        truckUpdate: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/broker/companies/${carrierId}/trucks/${truckId}`,
            data: truckUpdate,
            expectedResponseContentType: 'unknown',
        });
    };

    archiveTruck = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.Parameters.CarrierId,
        data: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActivate.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/broker/companies/${carrierId}/trucks/archive`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchPartnerTruckDetails = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Get.Parameters.CarrierId,
        truckId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Get.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trucks/${truckId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchTrucksActiveFleetCount = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActiveFleetCount.Get.Parameters.CarrierId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActiveFleetCount.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksActiveFleetCount.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trucks/active-fleet-count`,
            expectedResponseContentType: 'json',
        });
    };

    deleteTruck = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Delete.Parameters.Id,
        truckId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Delete.Responses.$204>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucks$Id.Delete.Responses.$204>({
            method: 'DELETE',
            path: `/v2/broker/companies/${carrierId}/trucks/${truckId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchCompanyContacts = async (
        companyId: ApiPaths.ApiV1Broker$CompanyIdContacts.Get.Parameters.CompanyId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Broker$CompanyIdContacts.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Broker$CompanyIdContacts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/${companyId}/contacts`,
            expectedResponseContentType: 'json',
        });
    };

    addUserToPartner = async (
        newUser: Api2Paths.ApiV2BrokerCompany$CompanyIdRegistrationUserInvite.Post.RequestBody,
        companyId: Api2Paths.ApiV2BrokerCompany$CompanyIdRegistrationUserInvite.Post.Parameters.CompanyId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerCompany$CompanyIdRegistrationUserInvite.Post.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompany$CompanyIdRegistrationUserInvite.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/broker/company/${companyId}/registration/user/invite`,
            data: newUser,
            expectedResponseContentType: 'unknown',
        });
    };

    addContactToPartner = async (
        newContact: ApiPaths.ApiV1BrokerContact.Post.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerContact.Post.Responses.$201>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerContact.Post.Responses.$201>({
            method: 'POST',
            path: '/v1/broker/contact',
            data: newContact,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchPartnersPage = async (
        apiFetchPartnersRequest: OverwritePageRequestWithSortT<ApiPaths.ApiV1BrokerCompanies.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies`,
            query: apiFetchPartnersRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnersStatistics = async (): Promise<
        TranziitApiResultT<ApiPaths.ApiV1BrokerCompaniesUpdatedCount.Get.Responses.$200>
    > => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompaniesUpdatedCount.Get.Responses.$200>({
            method: 'GET',
            path: '/v1/broker/companies/updatedCount',
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnerDetails = async (
        companyId: ApiPaths.ApiV1BrokerCompany$CompanyId.Get.Parameters.CompanyId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompany$CompanyId.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompany$CompanyId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/company/${companyId}`,
            expectedResponseContentType: 'json',
        });
    };

    verifyPartnerAddress = async (
        companyId:
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyLegaladdress.Put.Parameters.CompanyId
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyPostaladdress.Put.Parameters.CompanyId
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyBillingAddress.Put.Parameters.CompanyId,
        type: AddressTypeEnum,
    ): Promise<
        TranziitApiResultT<
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyLegaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyPostaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyBillingAddress.Put.Responses.$200
        >
    > => {
        const paths: Record<AddressTypeEnum, string> = {
            [AddressTypeEnum.legal]: `/v1/broker/company/${companyId}/verify/legaladdress`,
            [AddressTypeEnum.postal]: `/v1/broker/company/${companyId}/verify/postaladdress`,
            [AddressTypeEnum.billing]: `/v1/broker/company/${companyId}/verify/billing-address`,
        };

        return this.doFetch<
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyLegaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyPostaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdVerifyBillingAddress.Put.Responses.$200
        >({
            method: 'PUT',
            path: paths[type],
            expectedResponseContentType: 'unknown',
        });
    };

    unVerifyPartnerAddress = async (
        companyId:
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyLegaladdress.Put.Parameters.CompanyId
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyPostaladdress.Put.Parameters.CompanyId
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyBillingAddress.Put.Parameters.CompanyId,
        type: AddressTypeEnum,
    ): Promise<
        TranziitApiResultT<
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyLegaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyPostaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyBillingAddress.Put.Responses.$200
        >
    > => {
        const paths: Record<AddressTypeEnum, string> = {
            [AddressTypeEnum.legal]: `/v1/broker/company/${companyId}/un-verify/legaladdress`,
            [AddressTypeEnum.postal]: `/v1/broker/company/${companyId}/un-verify/postaladdress`,
            [AddressTypeEnum.billing]: `/v1/broker/company/${companyId}/un-verify/billing-address`,
        };

        return this.doFetch<
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyLegaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyPostaladdress.Put.Responses.$200
            | ApiPaths.ApiV1BrokerCompany$CompanyIdUnVerifyBillingAddress.Put.Responses.$200
        >({
            method: 'PUT',
            path: paths[type],
            expectedResponseContentType: 'unknown',
        });
    };

    lockPartner = async (
        companyId: ApiPaths.ApiV1BrokerCompanyLock$CompanyId.Post.Parameters.CompanyId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanyLock$CompanyId.Post.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompanyLock$CompanyId.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/company/lock/${companyId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    unlockPartner = async (
        companyId: ApiPaths.ApiV1BrokerCompanyLock$CompanyId.Post.Parameters.CompanyId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanyUnlock$CompanyId.Post.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompanyUnlock$CompanyId.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/company/unlock/${companyId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    updatePartnerDetails = async (
        companyId: ApiPaths.ApiV1BrokerCompany$CompanyIdV2.Put.Parameters.CompanyId,
        partnerDetails: ApiPaths.ApiV1BrokerCompany$CompanyIdV2.Put.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompany$CompanyIdV2.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompany$CompanyIdV2.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/company/${companyId}/v2`,
            data: partnerDetails,
            expectedResponseContentType: 'json',
        });
    };

    updateEvidenceOfTax = async (
        documentId: ApiPaths.ApiV1BrokerDocument$DocumentIdUpdateEvidenceOfTax.Post.Parameters.DocumentId,
        evidenceOfTaxDocumentUpdate: ApiPaths.ApiV1BrokerDocument$DocumentIdUpdateEvidenceOfTax.Post.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDocument$DocumentIdUpdateEvidenceOfTax.Post.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerDocument$DocumentIdUpdateEvidenceOfTax.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/document/${documentId}/update-evidence-of-tax`,
            data: evidenceOfTaxDocumentUpdate,
            expectedResponseContentType: 'unknown',
        });
    };

    searchAssetsForAssigment = async (
        tourId: Api2Paths.ApiV1BrokerTours$TourIdSearchAssetsForAssignment.Post.Parameters.TourId,
        radiusM: Api2Paths.ApiV1BrokerTours$TourIdSearchAssetsForAssignment.Post.Parameters.Radius,
        data: Api2Paths.ApiV1BrokerTours$TourIdSearchAssetsForAssignment.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1BrokerTours$TourIdSearchAssetsForAssignment.Post.Responses.$200>> => {
        const query: Api2Paths.ApiV1BrokerTours$TourIdSearchAssetsForAssignment.Post.QueryParameters = {
            radius: radiusM,
        };

        return this.doFetch<Api2Paths.ApiV1BrokerTours$TourIdSearchAssetsForAssignment.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/tours/${tourId}/search-assets-for-assignment`,
            query,
            data,
            expectedResponseContentType: 'json',
        });
    };

    findTrailersByPlateNumber = async (
        tourId: Api2Paths.ApiV1BrokerTours$TourIdSearchTrailersForAssignment.Post.Parameters.TourId,
        query: Api2Paths.ApiV1BrokerTours$TourIdSearchTrailersForAssignment.Post.QueryParameters,
        data: Api2Paths.ApiV1BrokerTours$TourIdSearchTrailersForAssignment.Post.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerTours$TourIdSearchTrailersForAssignment.Post.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerTours$TourIdSearchTrailersForAssignment.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/tours/${tourId}/search-trailers-for-assignment`,
            query,
            data,
            expectedResponseContentType: 'json',
        });
    };

    calculateTourCostsForAssets = async (
        tourId: Api2Paths.ApiV1BrokerTours$TourIdCalculateTourCostsForAssets.Post.Parameters.TourId,
        query: Api2Paths.ApiV1BrokerTours$TourIdCalculateTourCostsForAssets.Post.QueryParameters,
        data: Api2Paths.ApiV1BrokerTours$TourIdCalculateTourCostsForAssets.Post.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerTours$TourIdCalculateTourCostsForAssets.Post.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerTours$TourIdCalculateTourCostsForAssets.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/tours/${tourId}/calculate-tour-costs-for-assets`,
            query,
            data,
            expectedResponseContentType: 'json',
        });
    };

    fetchTourAssignDetails = async (
        tourId: Api2Paths.ApiV1BrokerTours$TourIdAssignDetails.Get.Parameters.TourId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1BrokerTours$TourIdAssignDetails.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1BrokerTours$TourIdAssignDetails.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/tours/${tourId}/assign/details`,
            expectedResponseContentType: 'json',
        });
    };

    findTrucksByPlateNumber = async (
        tourId: Api2Paths.ApiV1BrokerTours$TourIdSearchTrucksForAssignment.Post.Parameters.TourId,
        query: Api2Paths.ApiV1BrokerTours$TourIdSearchTrucksForAssignment.Post.QueryParameters,
        data: Api2Paths.ApiV1BrokerTours$TourIdSearchTrucksForAssignment.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1BrokerTours$TourIdSearchTrucksForAssignment.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1BrokerTours$TourIdSearchTrucksForAssignment.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/tours/${tourId}/search-trucks-for-assignment`,
            query,
            data,
            expectedResponseContentType: 'json',
        });
    };

    assignOrder = async (
        tourId: Api2Paths.ApiV1BrokerTours$TourIdAssign$AssignmentContextId.Put.Parameters.TourId,
        assignmentContextId: Api2Paths.ApiV1BrokerTours$TourIdAssign$AssignmentContextId.Put.Parameters.AssignmentContextId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1BrokerTours$TourIdAssign$AssignmentContextId.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1BrokerTours$TourIdAssign$AssignmentContextId.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/tours/${tourId}/assign/${assignmentContextId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    updateBrokerWindowAppointment = async (
        dispatchId: Api2Paths.ApiV2BrokerDispatches$DispatchIdWindows.Put.Parameters.DispatchId,
        data: Api2Paths.ApiV2BrokerDispatches$DispatchIdWindows.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerDispatches$DispatchIdWindows.Put.Responses.$204>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerDispatches$DispatchIdWindows.Put.Responses.$204>({
            method: 'PUT',
            path: `/v2/broker/dispatches/${dispatchId}/windows`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchAvailableCarriers = async (): Promise<
        TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>
    > => {
        const query: ApiPaths.ApiV1BrokerCompanies.Get.QueryParameters = {
            size: 9999,
            page: 0,
            includeProfileTypes: ['CARRIER'],
        };

        return this.doFetch<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>({
            method: 'GET',
            path: '/v1/broker/companies',
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchBrokerAdminUsers = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerUsers.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerUsers.Get.QueryParameters = {
            roles: ['COMPANY_ADMIN'],
        };
        return this.doFetch<ApiPaths.ApiV1BrokerUsers.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/users`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnerDispatchers = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerUsers.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerUsers.Get.QueryParameters = {
            roles: ['DISPATCHER', 'COMPANY_ADMIN'],
        };
        return this.doFetch<ApiPaths.ApiV1BrokerUsers.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/users`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnerManagers = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerUsers.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerUsers.Get.QueryParameters = {
            roles: ['KEY_ACCOUNT_MANAGER', 'COMPANY_ADMIN'],
        };

        return this.doFetch<ApiPaths.ApiV1BrokerUsers.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/users`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    createPartner = async (
        data: ApiPaths.ApiV1BrokerCompany.Post.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompany.Post.Responses.$201>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompany.Post.Responses.$201>({
            method: 'POST',
            path: '/v1/broker/company',
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchRatesPage = async (
        query: OverwritePageRequestWithSortT<ApiPaths.ApiV1Manualrates.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualrates.Get.Responses.$200>> => {
        if (!query.countriesFrom?.length && !query.countriesTo?.length) {
            return [
                null,
                {
                    content: [],
                    totalPages: 0,
                    totalElements: 0,
                },
            ];
        }

        return this.doFetch<ApiPaths.ApiV1Manualrates.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/manualrates`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    updateRates = async (
        ids: Array<number | undefined>,
        value: number,
        strategy: UpdateRateStrategyEnum,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualrates.Put.Responses.$200>> => {
        const apiUpdateRateRequest: ApiPaths.ApiV1Manualrates.Put.RequestBody = {
            ids: ids.filter(isNonNil),
            value,
            strategy,
        };

        return this.doFetch<ApiPaths.ApiV1Manualrates.Put.Responses.$200>({
            method: 'PUT',
            path: '/v1/manualrates',
            data: apiUpdateRateRequest,
            expectedResponseContentType: 'unknown',
        });
    };

    uploadRatesCSVFile = async (
        file: File,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1ManualratesFile.Put.Responses.$200>> => {
        const formData = new FormData();
        formData.append('file', file);

        return this.doFetch<ApiPaths.ApiV1ManualratesFile.Put.Responses.$200>({
            method: 'PUT',
            path: '/v1/manualrates/file',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchRulesPage = async (
        query: OverwritePageRequestWithSortT<ApiPaths.ApiV1Manualraterules.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualraterules.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Manualraterules.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/manualraterules`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchRuleDetails = async (
        ruleId: ApiPaths.ApiV1Manualraterule$RuleId.Get.Parameters.RuleId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualraterule$RuleId.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Manualraterule$RuleId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/manualraterule/${ruleId}`,
            expectedResponseContentType: 'json',
        });
    };

    updateRule = async (
        ruleId: ApiPaths.ApiV1Manualraterule$RuleId.Put.Parameters.RuleId,
        ruleDraft: Omit<ApiPaths.ApiV1Manualraterule$RuleId.Put.RequestBody, 'active' | 'id'>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualraterule$RuleId.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Manualraterule$RuleId.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/manualraterule/${ruleId}`,
            data: ruleDraft,
            expectedResponseContentType: 'unknown',
        });
    };

    activateRule = async (
        ruleId: ApiPaths.ApiV1Manualraterule$RuleIdActivate.Put.Parameters.RuleId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualraterule$RuleIdActivate.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Manualraterule$RuleIdActivate.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/manualraterule/${ruleId}/activate`,
            expectedResponseContentType: 'unknown',
        });
    };

    deactivateRule = async (
        ruleId: ApiPaths.ApiV1Manualraterule$RuleIdDeactivate.Put.Parameters.RuleId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualraterule$RuleIdDeactivate.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Manualraterule$RuleIdDeactivate.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/manualraterule/${ruleId}/deactivate`,
            expectedResponseContentType: 'unknown',
        });
    };

    deleteRule = async (
        ruleId: ApiPaths.ApiV1Manualraterule$RuleId.Delete.Parameters.RuleId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualraterule$RuleId.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Manualraterule$RuleId.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/v1/manualraterule/${ruleId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    createRule = async (
        ruleDraft: Omit<ApiPaths.ApiV1Manualraterule$RuleId.Put.RequestBody, 'active' | 'id'>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Manualraterule$RuleId.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Manualraterule$RuleId.Put.Responses.$200>({
            method: 'POST',
            path: `/v1/manualraterule`,
            data: ruleDraft,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchSystemSettings = async (): Promise<TranziitApiResultT<ApiSystemSettingsT>> => {
        const query: ApiPaths.ApiV1Settings.Get.QueryParameters = {
            groups: ['RATE_STRATEGIES', 'NEW_ORDER', 'ASSIGNMENT', 'PRICING', 'DRIVER', 'ALERTS', 'SPOT'],
        };

        const [error, response] = await this.doFetch<ApiPaths.ApiV1Settings.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/settings`,
            query,
            expectedResponseContentType: 'json',
        });

        if (error) {
            return [error, null];
        }

        const apiSettings = response?.reduce<ApiSystemSettingsT>((acc, item) => {
            const { name, value } = item;

            acc[name] = value;

            return acc;
        }, {});

        return [null, apiSettings || null];
    };

    updateSystemSettings = async (
        settingsChanges: Partial<ApiSystemSettingsT>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Settings.Put.Responses.$200>> => {
        const body = Object.entries(settingsChanges).reduce<ApiPaths.ApiV1Settings.Put.RequestBody>(
            (acc, [key, value]) => {
                acc.push({
                    // @ts-ignore
                    name: key,
                    // @ts-ignore
                    value,
                });

                return acc;
            },
            [],
        );

        return this.doFetch<ApiPaths.ApiV1Settings.Put.Responses.$200>({
            method: 'PUT',
            path: '/v1/settings',
            data: body,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchDispatchesPage = async (
        apiFetchDispatchesRequest: FixPageableCommonQueryT & Api2Paths.ApiV2BrokerDispatches.Get.QueryParameters,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerDispatches.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerDispatches.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/dispatches`,
            query: apiFetchDispatchesRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchBrokerOrdersPage = async (
        apiFetchBrokerOrdersRequest: OverwritePageRequestWithSortT<ApiPaths.ApiV1BrokerOrders.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerOrders.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerOrders.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/orders`,
            query: apiFetchBrokerOrdersRequest,
            expectedResponseContentType: 'json',
        });
    };

    getDispatchDetails = async (
        dispatchId: Api2Paths.ApiV2BrokerDispatches$DispatchId.Get.Parameters.DispatchId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerDispatches$DispatchId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerDispatches$DispatchId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/dispatches/${dispatchId}`,
            expectedResponseContentType: 'json',
        });
    };

    getDoneStatistics = async (
        query: ApiPaths.ApiV1BrokerStatisticsRatesDone.Get.QueryParameters,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerStatisticsRatesDone.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerStatisticsRatesDone.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/statistics/rates/done`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    getRejectStatistics = async (
        query: ApiPaths.ApiV1BrokerStatisticsRatesReject.Get.QueryParameters,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerStatisticsRatesReject.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerStatisticsRatesReject.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/statistics/rates/reject`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    deleteContact = async (
        contactId: ApiPaths.ApiV1BrokerContact$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerContact$Id.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerContact$Id.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/v1/broker/contact/${contactId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    deleteUser = async (
        userId: ApiPaths.ApiV1BrokerUser$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerUser$Id.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerUser$Id.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/v1/broker/user/${userId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    cancelUserInvite = async (
        userId: ApiPaths.ApiV1BrokerUser$UserIdCancelInvite.Delete.Parameters.UserId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerUser$UserIdCancelInvite.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerUser$UserIdCancelInvite.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/v1/broker/user/${userId}/cancel-invite`,
            expectedResponseContentType: 'unknown',
        });
    };

    restoreUser = async (
        userId: ApiPaths.ApiV1BrokerUser$IdRestore.Put.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerUser$IdRestore.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerUser$IdRestore.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/user/${userId}/restore`,
            expectedResponseContentType: 'unknown',
        });
    };

    updateContact = async (
        contactId: ApiPaths.ApiV1BrokerContact$ContactId.Put.Parameters.ContactId,
        contactChanges: ApiPaths.ApiV1BrokerContact$ContactId.Put.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerContact$ContactId.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerContact$ContactId.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/contact/${contactId}`,
            data: contactChanges,
            expectedResponseContentType: 'json',
        });
    };

    assignUserRole = async (
        userId: Api2Paths.ApiV2BrokerUsers$UserIdAssignRole.Post.Parameters.UserId,
        newRole: Api2Paths.ApiV2BrokerUsers$UserIdAssignRole.Post.Parameters.NewRole,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerUsers$UserIdAssignRole.Post.Responses.$200>> => {
        const query: Api2Paths.ApiV2BrokerUsers$UserIdAssignRole.Post.QueryParameters = {
            newRole,
        };
        return this.doFetch<Api2Paths.ApiV2BrokerUsers$UserIdAssignRole.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/broker/users/${userId}/assign-role`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    setSuccessor = async (
        userId: Api2Paths.ApiV2BrokerCompaniesReplaceRegularUserAssignmentFromCompanies.Post.Parameters.OldUser,
        successorUserId: Api2Paths.ApiV2BrokerCompaniesReplaceRegularUserAssignmentFromCompanies.Post.Parameters.NewUser,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerCompaniesReplaceRegularUserAssignmentFromCompanies.Post.Responses.$200>
    > => {
        const query: Api2Paths.ApiV2BrokerCompaniesReplaceRegularUserAssignmentFromCompanies.Post.QueryParameters = {
            newUser: successorUserId,
            oldUser: userId,
        };

        return this.doFetch<Api2Paths.ApiV2BrokerCompaniesReplaceRegularUserAssignmentFromCompanies.Post.Responses.$200>(
            {
                method: 'POST',
                path: `/v2/broker/companies/replace-regular-user-assignment-from-companies`,
                query,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    setAdminSuccessors = async (
        userId: Api2Paths.ApiV2BrokerCompaniesReplaceAdminUserAssignmentFromCompanies.Post.Parameters.OldAdminUser,
        newDispatcherUserId: Api2Paths.ApiV2BrokerCompaniesReplaceAdminUserAssignmentFromCompanies.Post.Parameters.NewDispatchUser,
        newKeyAccountManagerUserId: Api2Paths.ApiV2BrokerCompaniesReplaceAdminUserAssignmentFromCompanies.Post.Parameters.NewKamUser,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerCompaniesReplaceAdminUserAssignmentFromCompanies.Post.Responses.$200>
    > => {
        const query: Api2Paths.ApiV2BrokerCompaniesReplaceAdminUserAssignmentFromCompanies.Post.QueryParameters = {
            oldAdminUser: userId,
            newDispatchUser: newDispatcherUserId,
            newKamUser: newKeyAccountManagerUserId,
        };

        return this.doFetch<Api2Paths.ApiV2BrokerCompaniesReplaceAdminUserAssignmentFromCompanies.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/broker/companies/replace-admin-user-assignment-from-companies`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchContactDetails = async (
        contactId: ApiPaths.ApiV1BrokerContact$Id.Get.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerContact$Id.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerContact$Id.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/contact/${contactId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchContactDetailsByUserId = async (
        userId: ApiPaths.ApiV1BrokerUser$IdContact.Put.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerUser$IdContact.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerUser$IdContact.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/user/${userId}/contact`,
            expectedResponseContentType: 'json',
        });
    };

    fetchDocuments = async (
        companyId: ApiPaths.ApiV1Broker$CompanyIdDocuments.Get.Parameters.CompanyId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Broker$CompanyIdDocuments.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Broker$CompanyIdDocuments.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/${companyId}/documents`,
            expectedResponseContentType: 'json',
        });
    };

    fetchDocument = async (
        documentId: ApiPaths.ApiV1BrokerDocument$DocumentIdGetFile.Get.Parameters.DocumentId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDocument$DocumentIdGetFile.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerDocument$DocumentIdGetFile.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/document/${documentId}/getFile`,
            expectedResponseContentType: 'blob',
        });
    };

    approvePartnerDocument = async (
        documentId: ApiPaths.ApiV1BrokerDocument$DocumentIdApprove.Put.Parameters.DocumentId,
        validTill: ApiPaths.ApiV1BrokerDocument$DocumentIdApprove.Put.Parameters.ValidTill,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDocument$DocumentIdApprove.Put.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerDocument$DocumentIdApprove.Put.QueryParameters = {
            validTill,
        };

        return this.doFetch<ApiPaths.ApiV1BrokerDocument$DocumentIdApprove.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/document/${documentId}/approve`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    approvePartnerEvidenceOfTax = async (
        documentId: ApiPaths.ApiV1BrokerDocument$DocumentIdApproveEvidenceOfTax.Put.Parameters.DocumentId,
        data: ApiPaths.ApiV1BrokerDocument$DocumentIdApproveEvidenceOfTax.Put.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDocument$DocumentIdApproveEvidenceOfTax.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerDocument$DocumentIdApproveEvidenceOfTax.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/document/${documentId}/approve-evidence-of-tax`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    uploadDocument = async (
        documentType: DocumentTypeEnum,
        file: File,
        companyId: ApiPaths.ApiV1BrokerCompany$CompanyIdDocument.Post.Parameters.CompanyId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompany$CompanyIdDocument.Post.Responses.$201>> => {
        const formData = new FormData();
        formData.append('file', file);

        const query: ApiPaths.ApiV1BrokerCompany$CompanyIdDocument.Post.QueryParameters = {
            documentType,
        };
        return this.doFetch<ApiPaths.ApiV1BrokerCompany$CompanyIdDocument.Post.Responses.$201>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            expectedResponseContentType: 'unknown',
            data: formData,
            method: 'POST',
            path: `/v1/broker/company/${companyId}/document`,
            query,
        });
    };

    rejectPartnerDocument = async (
        documentId: ApiPaths.ApiV1BrokerDocument$DocumentIdReject.Put.Parameters.DocumentId,
        reason: ApiPaths.ApiV1BrokerDocument$DocumentIdReject.Put.Parameters.Reason,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDocument$DocumentIdReject.Put.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerDocument$DocumentIdReject.Put.QueryParameters = {
            reason,
        };

        return this.doFetch<ApiPaths.ApiV1BrokerDocument$DocumentIdReject.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/document/${documentId}/reject`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    revokePartnerDocument = async (
        documentId: ApiPaths.ApiV1BrokerDocument$DocumentIdRevoke.Put.Parameters.DocumentId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDocument$DocumentIdRevoke.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerDocument$DocumentIdRevoke.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/document/${documentId}/revoke`,
            expectedResponseContentType: 'unknown',
        });
    };

    updatePartnerDocument = async (
        documentId: ApiPaths.ApiV1BrokerDocument$DocumentIdUpdate.Post.Parameters.DocumentId,
        data: ApiPaths.ApiV1BrokerDocument$DocumentIdUpdate.Post.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDocument$DocumentIdUpdate.Post.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerDocument$DocumentIdUpdate.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/document/${documentId}/update`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    findCarriersByName = async (
        name: ApiPaths.ApiV1BrokerCompanies.Get.Parameters.Name,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerCompanies.Get.QueryParameters = {
            size: 10,
            includeProfileTypes: [PartnerTypeEnum.carrier],
            name,
        };

        return this.doFetch<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    findShippersByName = async (
        name: ApiPaths.ApiV1BrokerCompanies.Get.Parameters.Name,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerCompanies.Get.QueryParameters = {
            size: 10,
            includeProfileTypes: [PartnerTypeEnum.shipper],
            name,
        };

        return this.doFetch<ApiPaths.ApiV1BrokerCompanies.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    findPartnerUsersByName = async (
        companyId: ApiPaths.ApiV1Broker$CompanyIdContacts.Get.Parameters.CompanyId,
        query: string,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Broker$CompanyIdContacts.Get.Responses.$200>> => {
        // TODO wait backend, there is no such endpoint
        return this.doFetch<ApiPaths.ApiV1Broker$CompanyIdContacts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/${companyId}/contacts`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarrierContracts = async (
        partnerId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts.Get.Parameters.CarrierId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies/${partnerId}/carrier-contracts`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarrierApprovedContracts = async (
        partnerId: Api2Paths.ApiV1BrokerCompanies$CarrierIdApprovedCarrierContracts.Get.Parameters.CarrierId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdApprovedCarrierContracts.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdApprovedCarrierContracts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies/${partnerId}/approved-carrier-contracts`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarrierContractDetails = async (
        carrierId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Get.Parameters.CarrierId,
        contractId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Get.Parameters.ContractId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies/${carrierId}/carrier-contracts/${contractId}`,
            expectedResponseContentType: 'json',
        });
    };

    updateCarrierContract = async (
        carrierId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Put.Parameters.CarrierId,
        contractId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Put.Parameters.ContractId,
        data: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Put.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Put.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractId.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/broker/companies/${carrierId}/carrier-contracts/${contractId}`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    revokeCarrierContract = async (
        carrierId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdRevoke.Put.Parameters.CarrierId,
        contractId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdRevoke.Put.Parameters.ContractId,
        data: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdRevoke.Put.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdRevoke.Put.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdRevoke.Put.Responses.$200>(
            {
                method: 'PUT',
                path: `/v1/broker/companies/${carrierId}/carrier-contracts/${contractId}/revoke`,
                data,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    rejectCarrierContract = async (
        carrierId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdReject.Put.Parameters.CarrierId,
        contractId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdReject.Put.Parameters.ContractId,
        query: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdReject.Put.QueryParameters,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdReject.Put.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdReject.Put.Responses.$200>(
            {
                method: 'PUT',
                path: `/v1/broker/companies/${carrierId}/carrier-contracts/${contractId}/reject`,
                query,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    approveCarrierContract = async (
        carrierId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdApprove.Put.Parameters.CarrierId,
        contractId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdApprove.Put.Parameters.ContractId,
        body: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdApprove.Put.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdApprove.Put.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdApprove.Put.Responses.$200>(
            {
                method: 'PUT',
                path: `/v1/broker/companies/${carrierId}/carrier-contracts/${contractId}/approve`,
                data: body,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    fetchVehicleSchedule = async (
        vehicleId: ApiPaths.ApiV2CarrierVehiclescheduleVehicle$VehicleId.Get.Parameters.VehicleId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV2CarrierVehiclescheduleVehicle$VehicleId.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV2CarrierVehiclescheduleVehicle$VehicleId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/vehicleschedule/vehicle/${vehicleId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchVehicleScheduleAroundDate = async (
        vehicleId: ApiPaths.ApiV2BrokerVehiclescheduleVehicle$VehicleIdAroundDate.Get.Parameters.VehicleId,
        date: ApiPaths.ApiV2BrokerVehiclescheduleVehicle$VehicleIdAroundDate.Get.Parameters.DateOfInterest,
    ): Promise<
        TranziitApiResultT<ApiPaths.ApiV2BrokerVehiclescheduleVehicle$VehicleIdAroundDate.Get.Responses.$200>
    > => {
        const query: ApiPaths.ApiV2BrokerVehiclescheduleVehicle$VehicleIdAroundDate.Get.QueryParameters = {
            dateOfInterest: date,
        };

        return this.doFetch<ApiPaths.ApiV2BrokerVehiclescheduleVehicle$VehicleIdAroundDate.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/vehicleschedule/vehicle/${vehicleId}/around-date`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    getTransportOrderDetails = async (
        carrierId: Api2Paths.ApiV2BrokerTransportationOrders$CarrierId$TransportationOrderId.Get.Parameters.CarrierId,
        transportOrderId: Api2Paths.ApiV2BrokerTransportationOrders$CarrierId$TransportationOrderId.Get.Parameters.TransportationOrderId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerTransportationOrders$CarrierId$TransportationOrderId.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerTransportationOrders$CarrierId$TransportationOrderId.Get.Responses.$200>(
            {
                method: 'GET',
                path: `/v2/broker/transportation-orders/${carrierId}/${transportOrderId}/`,
                expectedResponseContentType: 'json',
            },
        );
    };

    fetchRFQDetails = async (data: TODO): Promise<TODO> => {
        return throwNotImplementedError();
    };

    fetchDocumentsDict = async (
        partnerType: PartnerTypeT,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerDictdocuments.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerDictdocuments.Get.QueryParameters = {
            companyType: partnerType,
        };

        return this.doFetch<ApiPaths.ApiV1BrokerDictdocuments.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/dictdocuments`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    invitePartnerDriver = async (
        companyId: TODO,
        newDriver: TODO,
    ): Promise<TranziitApiResultT<NOT_IMPLEMENTED_TYPE>> => {
        return throwNotImplementedError();
    };

    deletePartnerDriver = async (
        companyId: TODO,
        driverId: TODO,
    ): Promise<TranziitApiResultT<NOT_IMPLEMENTED_TYPE>> => {
        return throwNotImplementedError();
    };

    archivePartnerDriver = async (
        companyId: TODO,
        driverId: TODO,
    ): Promise<TranziitApiResultT<NOT_IMPLEMENTED_TYPE>> => {
        return throwNotImplementedError();
    };

    activatePartnerDriver = async (
        companyId: TODO,
        driverId: TODO,
    ): Promise<TranziitApiResultT<NOT_IMPLEMENTED_TYPE>> => {
        return throwNotImplementedError();
    };

    updatePartnerDriver = async (
        driverId: TODO,
        driverChanges: TODO,
    ): Promise<TranziitApiResultT<NOT_IMPLEMENTED_TYPE>> => {
        return throwNotImplementedError();
    };

    fetchDriversPage = async (
        companyId: Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.Parameters.CarrierCompanyId,
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.Responses.$200>> => {
        // conflict statuses in filters
        if (query.statuses?.length === 0) {
            return [null, { content: [] }];
        }

        return this.doFetch<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/drivers/${companyId}`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnerDriverDetails = async (
        companyId: Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId$DriverId.Get.Parameters.CarrierCompanyId,
        driverId: Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId$DriverId.Get.Parameters.DriverId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId$DriverId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId$DriverId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/drivers/${companyId}/${driverId}`,
            expectedResponseContentType: 'json',
        });
    };

    searchDriver = async (
        companyId: Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.Parameters.CarrierCompanyId,
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerDrivers$CarrierCompanyId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/drivers/${companyId}`,
            expectedResponseContentType: 'json',
            query,
        });
    };

    invitePartner = async (
        data: ApiInvitePartnerRequestT,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanySendInvite.Post.Responses.$200>> => {
        const query: ApiPaths.ApiV1BrokerCompanySendInvite.Post.QueryParameters = {
            email: data.email,
            role: data.partnerType,
        };

        return this.doFetch<ApiPaths.ApiV1BrokerCompanySendInvite.Post.Responses.$200>({
            method: 'POST',
            path: '/v1/broker/company/sendInvite',
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchDispatchesStatistics = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV2BrokerDispatchesStatistic.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2BrokerDispatchesStatistic.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/dispatches/statistic`,
            expectedResponseContentType: 'json',
        });
    };

    updateAdditionalService = async (
        serviceId: ApiPaths.ApiV1AdditionalServices$Id.Put.Parameters.Id,
        cost: number,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1AdditionalServices$Id.Put.Responses.$200>> => {
        const bodyData: ApiPaths.ApiV1AdditionalServices$Id.Put.RequestBody = {
            cost,
        };

        return this.doFetch<ApiPaths.ApiV1AdditionalServices$Id.Put.Responses.$200>({
            method: 'PUT',
            path: `/v1/additionalServices/${serviceId}`,
            data: bodyData,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchAdditionalServices = async (): Promise<
        TranziitApiResultT<ApiPaths.ApiV1AdditionalServices.Get.Responses.$200>
    > => {
        return this.doFetch<ApiPaths.ApiV1AdditionalServices.Get.Responses.$200>({
            method: 'GET',
            path: '/v1/additionalServices',
            expectedResponseContentType: 'json',
        });
    };

    checkPartnerTrailerGPSStatus = async (
        plateNumber: Api2Paths.ApiV2CarrierTrailersHasGps.Get.Parameters.PlateNumber,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailersHasGps.Get.Responses.$200>> => {
        const query: Api2Paths.ApiV2CarrierTrailersHasGps.Get.QueryParameters = {
            plateNumber,
        };

        return this.doFetch<Api2Paths.ApiV2CarrierTrailersHasGps.Get.Responses.$200>({
            method: 'GET',
            path: '/v2/carrier/trailers/has-gps',
            query,
            expectedResponseContentType: 'json',
        });
    };

    checkPartnerTruckGPSStatus = async (
        plateNumber: Api2Paths.ApiV2CarrierTrucksHasGps.Get.Parameters.PlateNumber,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucksHasGps.Get.Responses.$200>> => {
        const query: Api2Paths.ApiV2CarrierTrucksHasGps.Get.QueryParameters = {
            plateNumber,
        };

        return this.doFetch<Api2Paths.ApiV2CarrierTrucksHasGps.Get.Responses.$200>({
            method: 'GET',
            path: '/v2/carrier/trucks/has-gps',
            query,
            expectedResponseContentType: 'json',
        });
    };

    addVehicleScheduleEvent = async (
        data: ApiPaths.ApiV2BrokerVehiclescheduleVehicle.Post.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV2BrokerVehiclescheduleVehicle.Post.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV2BrokerVehiclescheduleVehicle.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/broker/vehicleschedule/vehicle`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    uploadPartnerPaper = async (
        tourId: ApiPaths.ApiV1BrokerTour$TourIdDocuments.Post.PathParameters['tourId'],
        dictDocumentId: DictDocumentIdT,
        file: File,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerTour$TourIdDocuments.Post.Responses.$200>> => {
        const formData = new FormData();
        formData.append('file', file);

        const query: ApiPaths.ApiV1BrokerTour$TourIdDocuments.Post.QueryParameters = {
            dictCarrierDocumentId: toNumber(dictDocumentId),
        };

        return this.doFetch<ApiPaths.ApiV1BrokerTour$TourIdDocuments.Post.Responses.$200>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'POST',
            path: `/v1/broker/tour/${tourId}/documents`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    deletePartnerPaper = async (
        tourId: ApiPaths.ApiV1BrokerTour$TourIdDocuments$DocumentId.Delete.PathParameters['tourId'],
        documentId: ApiPaths.ApiV1BrokerTour$TourIdDocuments$DocumentId.Delete.PathParameters['documentId'],
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerTour$TourIdDocuments$DocumentId.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerTour$TourIdDocuments$DocumentId.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/v1/broker/tour/${tourId}/documents/${documentId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnerPapers = async (
        tourId: ApiPaths.ApiV1BrokerTour$TourIdDocuments.Get.PathParameters['tourId'],
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerTour$TourIdDocuments.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerTour$TourIdDocuments.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/tour/${tourId}/documents`,
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnerRFQCount = async (
        companyId: ApiPaths.ApiV1BrokerCompanies$CompanyIdRfqCount.Get.PathParameters['companyId'],
        query: ApiPaths.ApiV1BrokerCompanies$CompanyIdRfqCount.Get.QueryParameters,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies$CompanyIdRfqCount.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompanies$CompanyIdRfqCount.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies/${companyId}/rfq/count`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    sentTransportOrderToSpot = async (
        tourId: Api2Paths.ApiV2SpotTours$TourIdSendToSpot.Post.Parameters.TourId,
        data: Api2Paths.ApiV2SpotTours$TourIdSendToSpot.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2SpotTours$TourIdSendToSpot.Post.Responses.$204>> => {
        return this.doFetch<Api2Paths.ApiV2SpotTours$TourIdSendToSpot.Post.Responses.$204>({
            method: 'POST',
            path: `/v2/spot/tours/${tourId}/send-to-spot`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchRFQsPage = async (data: TODO): Promise<TranziitApiResultT<TODO>> => {
        return throwNotImplementedError();
    };

    fetchBrokerInvoicesPage = async (
        apiFetchOrdersRequest: OverwritePageRequestWithSortT<ApiPaths.ApiV1BrokerStripeInvoices.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerStripeInvoices.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerStripeInvoices.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/stripe/invoices`,
            query: apiFetchOrdersRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarrierContract = async (
        carrierId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdSourceFile.Get.Parameters.CarrierId,
        contractId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdSourceFile.Get.Parameters.ContractId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdSourceFile.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts$ContractIdSourceFile.Get.Responses.$200>(
            {
                method: 'GET',
                path: `/v1/broker/companies/${carrierId}/carrier-contracts/${contractId}/source-file`,
                expectedResponseContentType: 'blob',
            },
        );
    };

    fetchShipperContract = async (
        shipperId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdSourceFile.Get.Parameters.ShipperId,
        contractId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdSourceFile.Get.Parameters.ContractId,
    ): Promise<
        TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdSourceFile.Get.Responses.$200>
    > => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdSourceFile.Get.Responses.$200>(
            {
                method: 'GET',
                path: `/v1/broker/companies/${shipperId}/shipper-contracts/${contractId}/source-file`,
                expectedResponseContentType: 'blob',
            },
        );
    };

    uploadCarrierContract = async (
        file: File,
        carrierId: Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts.Post.Parameters.CarrierId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts.Post.Responses.$200>> => {
        const formData = new FormData();
        formData.append('file', file);

        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$CarrierIdCarrierContracts.Post.Responses.$200>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            expectedResponseContentType: 'unknown',
            data: formData,
            method: 'POST',
            path: `/v1/broker/companies/${carrierId}/carrier-contracts`,
        });
    };

    fetchPartnerShipperContracts = async (
        shipperId: ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Get.Parameters.ShipperId,
        query: OverwritePageRequestWithSortT<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1BrokerCompanies$ShipperIdShipperContracts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/companies/${shipperId}/shipper-contracts`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    createShipperContractLaneRFQ = async (
        shipperId: Api2Paths.ApiV1BrokerCompanies$ShipperIdLaneQuotations.Post.Parameters.ShipperId,
        data: Api2Paths.ApiV1BrokerCompanies$ShipperIdLaneQuotations.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$ShipperIdLaneQuotations.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$ShipperIdLaneQuotations.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/companies/${shipperId}/lane-quotations`,
            data,
            expectedResponseContentType: 'json',
        });
    };

    fetchShipperContractLaneRFQOffersAverage = async (
        rfqId: Api2Paths.ApiV2Rfqs$RfqIdOffersAverage.Get.Parameters.RfqId,
        query: Api2Paths.ApiV2Rfqs$RfqIdOffersAverage.Get.QueryParameters,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs$RfqIdOffersAverage.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqIdOffersAverage.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/rfqs/${rfqId}/offers/average`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    createShipperContractLane = async (
        shipperId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Post.Parameters.ShipperId,
        contractId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Post.Parameters.ContractId,
        data: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Post.RequestBody,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Post.Responses.$201>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Post.Responses.$201>(
            {
                method: 'POST',
                path: `/v1/broker/companies/${shipperId}/shipper-contracts/${contractId}/lanes`,
                data,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    fetchPartnerShipperContractLanes = async (
        shipperId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Get.Parameters.ShipperId,
        contractId: Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Get.Parameters.ContractId,
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Get.QueryParameters>,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1BrokerCompanies$ShipperIdShipperContracts$ContractIdLanes.Get.Responses.$200>(
            {
                method: 'GET',
                path: `/v1/broker/companies/${shipperId}/shipper-contracts/${contractId}/lanes`,
                query,
                expectedResponseContentType: 'json',
            },
        );
    };

    importPartnerXLSTrailers = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersImport.Post.Parameters.CarrierId,
        file: File,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersImport.Post.Responses.$201>> => {
        const formData = new FormData();
        formData.append('file', file);

        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersImport.Post.Responses.$201>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'POST',
            path: `/v2/broker/companies/${carrierId}/trailers/import`,
            expectedResponseContentType: 'unknown',
        });
    };

    importPartnerXLSTrucks = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksImport.Post.Parameters.CarrierId,
        file: File,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksImport.Post.Responses.$201>> => {
        const formData = new FormData();
        formData.append('file', file);

        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksImport.Post.Responses.$201>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'POST',
            path: `/v2/broker/companies/${carrierId}/trucks/import`,
            expectedResponseContentType: 'unknown',
        });
    };

    exportPartnerXLSTrailers = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersExport.Get.Parameters.CarrierId,
        query: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersExport.Get.QueryParameters,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersExport.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrailersExport.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trailers/export`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    exportPartnerXLSTrucks = async (
        carrierId: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksExport.Get.Parameters.CarrierId,
        query: Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksExport.Get.QueryParameters,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksExport.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2BrokerCompanies$CarrierIdTrucksExport.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/broker/companies/${carrierId}/trucks/export`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };
}

const brokerTranziitApi = BrokerTranziitApi.getInstance();

export default brokerTranziitApi;
