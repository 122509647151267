import { ApiCarrierTruckDetailsT, ApiTruckDetailsT } from 'common/store/trucks/models';

export const checkIsApiCarrierTruckDetails = (details: ApiTruckDetailsT | null): details is ApiCarrierTruckDetailsT => {
    if (!details) {
        return false;
    }

    // TODO add condition
    return !!details;
};
