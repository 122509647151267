import * as React from 'react';
import { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import TableCellLink from 'common/components/Table/cell-renderers/TableCellLink/TableCellLink';

type PropsT = {
    onOpenUserDetails: (userId: UserIdT | null) => void;
    userFullName: string | null | undefined;
    userId: UserIdT | null | undefined;
};

const EventAuthorCell: React.FC<PropsT> = React.memo((props) => {
    const { userId, userFullName, onOpenUserDetails } = props;

    return (
        <TableCellLink
            theme={LinkThemeEnum.boldBrandDark}
            size={LinkSizeEnum.medium}
            onClick={() => {
                if (!userId) {
                    return;
                }

                onOpenUserDetails(userId);
            }}
        >
            {userFullName}
        </TableCellLink>
    );
});

export default EventAuthorCell;
