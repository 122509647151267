import React from 'react';
import classNames from 'classnames/bind';

import styles from './TruckDetails.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrucksDictById } from 'common/store/trucks-dict/selectors';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import { AssetTypeEnum, CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { ApiTruckDetailsT } from 'common/store/trucks/models';
import FieldValue from 'common/components/FieldValue/FieldValue';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import LastGPSPositionAlert from 'common/components/LastGPSPositionAlert/LastGPSPositionAlert';
import { fetchCarrierContracts } from 'common/store/carrier-contracts/actions';
import {
    selectCarrierContractById,
    selectFetchCarrierContractsRequest,
} from 'common/store/carrier-contracts/selectors';
import { getTruckDictTypeLabel } from 'common/store/trucks-dict/utils';
import TruckIcon from 'common/icons/TruckIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import LinkedTrailerAlert from 'common/layouts/CommonEditableTruckDetailsLayout/LinkedTrailerAlert/LinkedTrailerAlert';
import Money from 'common/components/Money/Money';
import { CountryCodeT, TruckStatusEnum } from 'common/utils/api/models';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DetailsIcon from 'common/icons/DetailsIcon';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';
import TruckActionHistory from 'common/layouts/CommonEditableTruckDetailsLayout/TruckActionHistory/TruckActionHistory';
import { checkIsApiCarrierTruckDetails } from 'common/store/trucks/type-guards';
import ArchiveReasonAlert from 'common/components/ArchiveReasonAlert/ArchiveReasonAlert';
import { AlertGroup } from 'common/layouts/AlertGroup/AlertGroup';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    isLoading: boolean;
    truckDetails: ApiTruckDetailsT | null;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
    onOpenUserDetails: (userId: UserIdT, isBrokerUser?: boolean) => void;
    onOpenContactDetails: (contractId: CarrierContractIdT) => void;
};

const TruckDetails: React.FC<PropsT> = (props) => {
    const { partnerId, truckDetails, isLoading, onOpenUserDetails, onOpenTrailerDetails, onOpenContactDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const countryByCode = useSelector(selectCountriesByCode);
    const trucksDictById = useSelector(selectTrucksDictById);

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
        dispatch(fetchTrucksDict());
    }, []);

    React.useEffect(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);

    const fetchContractsRequests = useSelector(selectFetchCarrierContractsRequest(partnerId));
    const contractById = useSelector(selectCarrierContractById(partnerId));
    const contract = contractById[truckDetails?.contractId as string] || null;

    const ratePerKm = truckDetails?.contractId ? contract?.truckRateKm : truckDetails?.ratePerKm;

    const truck = trucksDictById?.[truckDetails?.dictTruckInfo?.id as string];

    let contractNode: React.ReactNode = null;

    if (fetchContractsRequests?.loading) {
        contractNode = <ControlLoaderIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.brandAccent} />;
    }

    if (!fetchContractsRequests?.loading && truckDetails && truckDetails?.contractId) {
        contractNode = t('common:dedicated.contract', {
            number: contract?.name || '',
        });
    }

    if (!fetchContractsRequests?.loading && truckDetails && !truckDetails?.contractId) {
        contractNode = t('common:dedicated.none');
    }

    let contractRightNode: React.ReactNode = null;
    if (contract?.id) {
        contractRightNode = (
            <TransparentTrigger
                spaces="xs"
                onClick={() => {
                    onOpenContactDetails(contract.id);
                }}
                leftIcon={<DetailsIcon strokeColor={StyleGuideColorsEnum.gray} />}
                reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
            />
        );
    }

    return (
        <div className={cx('wrap')}>
            <AlertGroup className={cx('alerts')}>
                {truckDetails?.linkedTrailerId && (
                    <LinkedTrailerAlert
                        trailerId={truckDetails?.linkedTrailerId}
                        plateNumber={truckDetails?.linkedTrailerPlateNumber}
                        onOpenTrailerDetails={onOpenTrailerDetails}
                    />
                )}
                <LastGPSPositionAlert
                    companyId={partnerId}
                    assetType={AssetTypeEnum.truck}
                    plateNumber={truckDetails?.plateNumber || ''}
                />
                {truckDetails?.status === TruckStatusEnum.archived && !!truckDetails?.archivingReason && (
                    <ArchiveReasonAlert reason={truckDetails.archivingReason} />
                )}
            </AlertGroup>
            {isLoading && <LoaderOverlay />}
            <div className={cx('fields')}>
                <FieldGroup>
                    <FieldValue
                        className={cx('field--plate-number')}
                        label={t('common:trucks-page.add-truck.fields.plate-number.label')}
                        value={truckDetails?.plateNumber || ''}
                    />
                    <FieldGroupEmptyItem className={cx('field', 'field--placeholder')} />
                </FieldGroup>
                <FieldGroup>
                    <FieldValue
                        className={cx('field--country-code')}
                        icon={<FlagIcon countryCode={truckDetails?.countryCode} />}
                        label={t('common:trucks-page.add-truck.fields.country.label')}
                        value={countryByCode?.[truckDetails?.countryCode as CountryCodeT]?.userLangDisplayName}
                    />
                    <FieldValue
                        className={cx('field--vin')}
                        label={t('common:trucks-page.add-truck.fields.truck-vin.label')}
                        value={truckDetails?.vin || ''}
                    />
                </FieldGroup>
                <FieldValue
                    icon={
                        <TruckIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                        />
                    }
                    label={t('common:trucks-page.add-truck.fields.truck-model.label')}
                    value={getTruckDictTypeLabel(truck)}
                />
                <FieldGroup>
                    <FieldValue
                        icon={<EmissionIcon strokeColor={StyleGuideColorsEnum.charcoal} />}
                        className={cx('field--emission-standard')}
                        label={t('common:trucks-page.add-truck.fields.emission-standard.label')}
                        value={<EmissionClassLabel emissionClass={truckDetails?.dictTruckInfo?.emissionClass} />}
                    />
                    <FieldValue
                        className={cx('field--cabin-color')}
                        label={t('common:trucks-page.add-truck.fields.cabin-color.label')}
                        value={truckDetails?.cabinColor || ''}
                    />
                </FieldGroup>
                <FieldGroup>
                    <FieldValue
                        className={cx('field--contract')}
                        label={t('common:trucks-page.add-truck.fields.contract.label')}
                        value={contractNode}
                        tooltipPosition={TooltipPositionEnum.centerRight}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                {t('common:trucks-page.add-truck.fields.contract.tooltip')}
                            </TooltipContent>
                        }
                        rightNode={contractRightNode}
                    />
                    <FieldValue
                        className={cx('field--rate')}
                        label={t('common:trucks-page.add-truck.fields.rate.label')}
                        value={<Money amount={ratePerKm} currency={CurrencyEnum.EUR} />}
                    />
                </FieldGroup>
            </div>
            <TruckActionHistory
                onOpenUserDetails={onOpenUserDetails}
                createdDate={truckDetails?.createdDate}
                createdByBroker={checkIsApiCarrierTruckDetails(truckDetails) && truckDetails?.addedByBroker}
                createdByName={truckDetails?.createdBy?.fullName}
                createdById={truckDetails?.createdBy?.id}
                modifiedDate={truckDetails?.lastModifiedDate}
                modifiedByBroker={checkIsApiCarrierTruckDetails(truckDetails) && truckDetails?.lastModifiedByBroker}
                modifiedByName={truckDetails?.lastModifiedBy?.fullName}
                modifiedById={truckDetails?.lastModifiedBy?.id}
            />
        </div>
    );
};

export default TruckDetails;
