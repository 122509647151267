import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './UserDropdownOptionLabel.scss';
import Avatar from 'common/components/Avatar/Avatar';
import LogoIcon from 'common/icons/LogoIcon';
import { DEFAULT_ICON_SIZE } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    label: string;
    isAllBrokerUsers?: boolean;
    isPlaceholder?: boolean;
};

const UserDropdownOptionLabel: React.FC<PropsT> = (props) => {
    const { className, label, isAllBrokerUsers, isPlaceholder } = props;

    return (
        <div className={cs(cx('option'), className)}>
            {isAllBrokerUsers ? (
                <LogoIcon className={cx('avatar')} size={DEFAULT_ICON_SIZE} />
            ) : (
                <Avatar className={cx('avatar')} hash={isPlaceholder ? null : label} />
            )}
            <div className={cx('label')}>{label}</div>
        </div>
    );
};

export default UserDropdownOptionLabel;
