import { FieldsEnum, FormValuesT } from './TrailerDetailsForm/constants';
import { ApiTrailerAddRequestT, ApiTrailerUpdateRequestT, CountryCodeT } from 'common/utils/api/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { FieldsEnum as DetailsFieldsEnum } from 'common/layouts/CommonEditableTruckDetailsLayout/EditTruckSidebarContent/TruckDetailsForm/constants';
import isNumber from 'lodash/isNumber';

const prepareApiCreateTrailer = (detailsFormValues: FormValuesT): ApiTrailerAddRequestT | null => {
    const dictTrailerId = detailsFormValues[FieldsEnum.trailerDictId];
    const parsedDictTrailerId = dictTrailerId ? parseInt(dictTrailerId, 10) : undefined;
    if (!isNumber(parsedDictTrailerId) || !dictTrailerId) {
        return null;
    }

    let pricing: Partial<ApiTrailerAddRequestT> = {};
    if (detailsFormValues[DetailsFieldsEnum.contract] === null) {
        pricing = {
            ratePerKm: parseSimpleNumber(detailsFormValues[FieldsEnum.ratePerKm]),
        };
    } else {
        pricing = {
            contractId: detailsFormValues[DetailsFieldsEnum.contract] || undefined,
        };
    }

    return {
        dictTrailerId: parsedDictTrailerId,
        vin: detailsFormValues[FieldsEnum.vin] || '',
        plateNumber: detailsFormValues[FieldsEnum.plateNumber] || '',
        countryOfRegistration: (detailsFormValues[FieldsEnum.countryCode] as CountryCodeT) || undefined,
        ...pricing,
    };
};

const prepareApiModifyTrailer = (trailerId: TrailerIdT, detailsFormValues: FormValuesT): ApiTrailerUpdateRequestT => {
    let pricing: Partial<ApiTrailerUpdateRequestT> = {};
    if (detailsFormValues[DetailsFieldsEnum.contract] === null) {
        pricing = {
            ratePerKm: parseSimpleNumber(detailsFormValues[FieldsEnum.ratePerKm]),
        };
    } else {
        pricing = {
            contractId: detailsFormValues[DetailsFieldsEnum.contract] || undefined,
        };
    }

    return {
        ...pricing,
    };
};

export { prepareApiCreateTrailer, prepareApiModifyTrailer };
