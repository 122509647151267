import React from 'react';
import classNames from 'classnames/bind';
import styles from './TruckFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { createJsonParams } from 'common/utils/query';
import { CommonSidebarDataT, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { TruckFiltersSidebarDataT } from 'common/layouts/SideBars/contents/TruckFiltersSidebarContent/models';
import { useQueryParam } from 'use-query-params';
import { QueryFiltersT, QueryKeysEnum } from 'common/layouts/TrucksPageLayout/query-models';
import { TruckStatusEnum } from 'common/utils/api/models';
import TruckStatusMultipleDropdown from 'common/components/dropdowns/TruckStatusMultipleDropdown/TruckStatusMultipleDropdown';
import TruckModelMultipleDropdown from 'common/components/dropdowns/TruckModelMultipleDropdown/TruckModelMultipleDropdown';
import EmissionClassMultipleDropdown from 'common/components/dropdowns/EmissionClassMultipleDropdown/EmissionClassMultipleDropdown';
import ContractMultipleDropdown from 'common/components/dropdowns/ContractMultipleDropdown/ContractMultipleDropdown';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import CountryMultipleDropdown from 'common/components/dropdowns/CountryMultipleDropdown/CountryMultipleDropdown';
import UserMultipleDropdown from 'common/components/dropdowns/UserMultipleDropdown/UserMultipleDropdown';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<TruckFiltersSidebarDataT, CommonSidebarDataT>;

const TRUCK_EXCLUDE_STATUSES: Array<TruckStatusEnum> = [TruckStatusEnum.archived];

const TruckFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.trucksFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);

            setQueryFilters({
                ...queryFilters,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values);
    }, [initialValues, formik.values]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:trucks-page.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    className={cx('field', 'field--truck-model')}
                    name={FieldsEnum.model}
                    error={formik.errors[FieldsEnum.model]}
                    meta={formik.getFieldMeta(FieldsEnum.model)}
                    label={t('common:trucks-page.filters.fields.truck-model.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <TruckModelMultipleDropdown
                            placeholder={t('common:trucks-page.filters.fields.truck-model.placeholder')}
                            values={formik.values[FieldsEnum.model]}
                            onChange={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--plate-number')}
                        name={FieldsEnum.plateNumber}
                        error={formik.errors[FieldsEnum.plateNumber]}
                        meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                        label={t('common:trucks-page.filters.fields.plate-number.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.plateNumber}
                                value={formik.values[FieldsEnum.plateNumber]}
                                placeholder={t('common:trucks-page.filters.fields.plate-number.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--country')}
                        name={FieldsEnum.country}
                        error={formik.errors[FieldsEnum.country]}
                        meta={formik.getFieldMeta(FieldsEnum.country)}
                        label={t('common:trucks-page.filters.fields.country.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CountryMultipleDropdown
                                placeholder={t('common:trucks-page.filters.fields.country.placeholder')}
                                values={formik.values[FieldsEnum.country]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--emission-class')}
                        name={FieldsEnum.emissionClass}
                        error={formik.errors[FieldsEnum.emissionClass]}
                        meta={formik.getFieldMeta(FieldsEnum.emissionClass)}
                        label={t('common:trucks-page.filters.fields.emission-class.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <EmissionClassMultipleDropdown
                                values={formik.values[FieldsEnum.emissionClass]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                placeholder={t('common:trucks-page.filters.fields.emission-class.placeholder')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--status')}
                        name={FieldsEnum.status}
                        error={formik.errors[FieldsEnum.status]}
                        meta={formik.getFieldMeta(FieldsEnum.status)}
                        label={t('common:trucks-page.filters.fields.status.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <TruckStatusMultipleDropdown
                                excludeStatuses={TRUCK_EXCLUDE_STATUSES}
                                value={formik.values[FieldsEnum.status]}
                                placeholder={t('common:trucks-page.filters.fields.status.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.lastTelematicUpdateDates}
                        error={formik.errors[FieldsEnum.lastTelematicUpdateDates]}
                        meta={formik.getFieldMeta(FieldsEnum.lastTelematicUpdateDates)}
                        label={t('common:trucks-page.filters.fields.telematic-update-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.lastTelematicUpdateDates]}
                                placeholder={t('common:trucks-page.filters.fields.telematic-update-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--contract')}
                        name={FieldsEnum.contractId}
                        error={formik.errors[FieldsEnum.contractId]}
                        meta={formik.getFieldMeta(FieldsEnum.contractId)}
                        label={t('common:trucks-page.filters.fields.contract.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <ContractMultipleDropdown
                                hasNoneOption
                                values={formik.values[FieldsEnum.contractId]}
                                overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                onChange={(values, labels) => {
                                    props.onChange(values);
                                    formik.setFieldValue(FieldsEnum.contractName, labels);
                                }}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                companyId={partnerId}
                                inputPlaceholder={t('common:trucks-page.filters.fields.contract.input-placeholder')}
                                placeholder={t('common:trucks-page.filters.fields.contract.placeholder')}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('common:trucks-page.filters.fields.creation-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('common:trucks-page.filters.fields.creation-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--user')}
                        name={FieldsEnum.createdByUserId}
                        error={formik.errors[FieldsEnum.createdByUserId]}
                        meta={formik.getFieldMeta(FieldsEnum.createdByUserId)}
                        label={t('common:trucks-page.filters.fields.creation-user.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <UserMultipleDropdown
                                companyId={partnerId}
                                initialValues={formik.values[FieldsEnum.createdByUserId]}
                                initialLabels={formik.values[FieldsEnum.createdByUserName]}
                                values={formik.values[FieldsEnum.createdByUserId]}
                                placeholder={t('common:trucks-page.filters.fields.creation-user.placeholder')}
                                onSelect={(userIds, userNames) => {
                                    props.onChange(userIds);
                                    formik.setFieldValue(FieldsEnum.createdByUserName, userNames);
                                }}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                                isShowAllBrokerUsersOption
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.lastModifyDates}
                        error={formik.errors[FieldsEnum.lastModifyDates]}
                        meta={formik.getFieldMeta(FieldsEnum.lastModifyDates)}
                        label={t('common:trucks-page.filters.fields.last-modify-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.lastModifyDates]}
                                placeholder={t('common:trucks-page.filters.fields.last-modify-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--user')}
                        name={FieldsEnum.lastModifyByUserId}
                        error={formik.errors[FieldsEnum.lastModifyByUserId]}
                        meta={formik.getFieldMeta(FieldsEnum.lastModifyByUserId)}
                        label={t('common:trucks-page.filters.fields.last-modify-user.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <UserMultipleDropdown
                                companyId={partnerId}
                                initialValues={formik.values[FieldsEnum.lastModifyByUserId]}
                                initialLabels={formik.values[FieldsEnum.lastModifyByUserName]}
                                values={formik.values[FieldsEnum.lastModifyByUserId]}
                                placeholder={t('common:trucks-page.filters.fields.last-modify-user.placeholder')}
                                onSelect={(userId, userNames) => {
                                    props.onChange(userId);
                                    formik.setFieldValue(FieldsEnum.lastModifyByUserName, userNames);
                                }}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                                isShowAllBrokerUsersOption
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout isTransparent hasPaddings>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:trucks-page.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default TruckFiltersSidebarContent;
