import React from 'react';

import classNames from 'classnames/bind';
import styles from './DictTrailerMultipleDropdown.scss';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import { ApiTrailerDictTypeT, ApiTrailerTypeT } from 'common/utils/api/models';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrailersDict, selectTrailersDictRequest } from 'common/store/trailers-dict/selectors';
import { useTranslation } from 'react-i18next';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import flatten from 'lodash/flatten';
import DropdownMultipleInput, {
    RenderTriggerPropsT,
} from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import DropdownBaseButtonTrigger from 'design-system/components/dropdowns/base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

enum TrailerIconTypesEnum {
    default = 'default',
}

const cx = classNames.bind(styles);

type OptionValueT = TrailerDictIdT | null;

type PropsT = {
    selectedTrailerType?: ApiTrailerTypeT | null;
    placeholder?: string;
    values: Array<OptionValueT>;
    isDisabled?: boolean;
    onChange: (values: Array<OptionValueT>) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
    overlayPosition?: DropdownOverlayPositionEnum;
    isShortLabel?: boolean;
};

type TrailerDropdownOptionT = {
    value: OptionValueT;
    label: string;
    iconType: TrailerIconTypesEnum;
};

const DictTrailerMultipleDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        selectedTrailerType,
        values,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
        overlayPosition,
        isShortLabel,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const trailersDict = useSelector(selectTrailersDict);
    const fetchRequest = useSelector(selectTrailersDictRequest);

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const rawTrailerOptions = React.useMemo((): ApiTrailerDictTypeT[] => {
        if (!selectedTrailerType) {
            return flatten(Object.values(trailersDict || {}));
        }

        return trailersDict?.[selectedTrailerType as string] || [];
    }, [trailersDict, selectedTrailerType]);

    const trailerOptions = React.useMemo((): TrailerDropdownOptionT[] => {
        return rawTrailerOptions.map((trailer) => ({
            value: String(trailer.id) || null,
            label: isShortLabel
                ? trailer.model || ''
                : t('common:trailer-subtype-template', {
                      length: trailer.length,
                      model: trailer.model,
                      eur1Pallets: trailer.eur1Pallets,
                      eur2Pallets: trailer.eur2Pallets,
                  }),
            iconType: TrailerIconTypesEnum.default,
        }));
    }, [rawTrailerOptions, isShortLabel, t]);

    const renderTrigger = (
        props: RenderTriggerPropsT,
        options: Array<TrailerDropdownOptionT>,
        placeholder: string | undefined,
    ): React.ReactNode => {
        if (!options?.length) {
            return (
                <DropdownBaseButtonTrigger
                    {...props}
                    renderLeftIcon={(iconMeta) => {
                        return <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />;
                    }}
                >
                    {placeholder || ''}
                </DropdownBaseButtonTrigger>
            );
        }

        if (options.length === 1) {
            const firstOption = options?.[0];

            return (
                <DropdownBaseButtonTrigger
                    {...props}
                    renderLeftIcon={(iconMeta) => {
                        return <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />;
                    }}
                >
                    {firstOption?.label}
                </DropdownBaseButtonTrigger>
            );
        }

        return (
            <DropdownBaseButtonTrigger
                {...props}
                renderLeftIcon={(iconMeta) => {
                    return <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />;
                }}
                renderRightIcon={() => (
                    <PillLabel isNoWrap theme={PillLabelThemeEnum.charcoal} testSelector="number" isSymmetrical>
                        {options.length}
                    </PillLabel>
                )}
            >
                {t('common:dropdown-multiple-input.multiple-values-selected')}
            </DropdownBaseButtonTrigger>
        );
    };

    const renderOption = (option: TrailerDropdownOptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return (
            <div className={cx('option')} key={option.label}>
                {option.label}
            </div>
        );
    };

    const handleSelect = (values: Array<OptionValueT>): void => {
        onChange(values);
    };

    const getOptionValue = (option: TrailerDropdownOptionT): OptionValueT => option.value;

    const handleReset = () => {
        onChange([]);
    };

    return (
        <DropdownMultipleInput<TrailerDropdownOptionT, OptionValueT>
            selectedValues={values}
            placeholder={placeholder}
            options={trailerOptions}
            onSelect={handleSelect}
            isDisabled={isDisabled || !trailersDict || fetchRequest.loading}
            renderOption={renderOption}
            isLoading={fetchRequest.loading}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            renderTrigger={renderTrigger}
            testSelector="dict-trailer-ids"
            onReset={hasClearControl ? handleReset : undefined}
        />
    );
});

export default DictTrailerMultipleDropdown;
