import * as React from 'react';

import useModalDialog from 'common/utils/hooks/useModalDialog';
import { ImportXLSAssetsDialogModalDataT } from '../ImportXLSAssetsDialogModal';
import { pickFile } from 'common/utils/pick-file';

export const useImportXLSAssetsModalDialog = () => {
    const modalDialog = useModalDialog<ImportXLSAssetsDialogModalDataT>();

    return React.useMemo(() => {
        return {
            ...modalDialog,
            onStartImportXLS: () => {
                pickFile(
                    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    (file) => {
                        modalDialog.setData({
                            file,
                        });
                    },
                );
            },
        };
    }, [modalDialog]);
};
