import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './HumanReadableFullDateCell.scss';

import isNil from 'lodash/isNil';
import { formatHumanReadableFullDate } from 'common/utils/time';
import { useTranslation } from 'react-i18next';

type PropsT = {
    date: string | null | undefined;
    className?: string;
    isBold?: boolean;
    isBigFont?: boolean;
    isAllowNever?: boolean;
};

const cx = classNames.bind(styles);

const HumanReadableFullDateCell: React.FC<PropsT> = React.memo((props) => {
    const { date, isBold, isBigFont, className, isAllowNever } = props;

    const { t } = useTranslation();

    if (isNil(date)) {
        return null;
    }

    return (
        <div className={cs(cx('date', { 'date--isBold': isBold, 'date--isBigFont': isBigFont }), className)}>
            {formatHumanReadableFullDate(t, date, { isAllowNever })}
        </div>
    );
});

export default HumanReadableFullDateCell;
