import { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';
import { ApiDistanceByCountryT, CountryCodeT } from 'common/utils/api/models';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { UnitTypeEnum } from 'common/constants';
import { convertToKm } from 'common/utils/distance';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const getDistanceByCountryRows = (distanceByCountry: ApiDistanceByCountryT | null | undefined) => {
    const { t } = useTranslation();

    return distanceByCountry
        ? Object.keys(distanceByCountry).map((countryCode): InfoTableRowT => {
              const distance = distanceByCountry?.[countryCode];

              return {
                  icon: <FlagIcon countryCode={countryCode} />,
                  name:
                      !countryCode || countryCode === 'null' ? (
                          t('common:rfq-details.unspecified')
                      ) : (
                          <AsyncCountryFormatter countryCode={countryCode as CountryCodeT} />
                      ),
                  value: <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(distance)} />,
                  emptyValue: t('common:info-table.placeholders.empty'),
                  isBoldValue: true,
                  testSelector: `payload-mileage-${countryCode}`,
              };
          })
        : [];
};
