import * as React from 'react';

import { StyleGuideColorsEnum } from 'common/constants';
import InfoIcon from 'common/icons/InfoIcon';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import { Trans } from 'react-i18next';

export type PropsT = {
    className?: string;
    reason: React.ReactNode;
};

const ArchiveReasonAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, reason } = props;

    return (
        <Alert
            className={className}
            icon={<InfoIcon fillColor={StyleGuideColorsEnum.white} />}
            size={AlertSizeEnum.small}
            theme={AlertThemeEnum.gray}
        >
            <Trans
                i18nKey="common:alerts.archive-reason"
                components={{
                    reason,
                }}
            />
        </Alert>
    );
});

export default ArchiveReasonAlert;
