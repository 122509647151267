import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ModelCell.scss';

import { ApiTruckT } from 'common/store/trucks/models';

type PropsT = {
    truck: ApiTruckT;
};

const cx = classNames.bind(styles);

const ModelCell: React.FC<PropsT> = React.memo((props) => {
    const { truck } = props;

    return (
        <div>
            <div className={cx('model')}>{truck.truckModel}</div>
            <div className={cx('cabin-color')}>{truck.cabinColor}</div>
        </div>
    );
});

export default ModelCell;
