import React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useTranslation } from 'react-i18next';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import XLSIcon from 'common/icons/XLSIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';

type PropsT = {
    className?: string;
    testSelector?: string;

    isLoading: boolean;

    isAllowImport: boolean;
    onImportAssets: () => void;

    isAllowExport: boolean;
    onExportAssets: () => void;
    onExportFilteredAssets: () => void;

    overlayPosition?: DropdownOverlayPositionEnum;
};

const AssetsXLSDropdownControl: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        testSelector,
        isLoading,
        isAllowImport,
        onImportAssets,
        isAllowExport,
        onExportAssets,
        onExportFilteredAssets,
        overlayPosition,
    } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): Array<DropdownControlOptionT | SpecialOptionEnum | null> => {
        return [
            isAllowImport
                ? {
                      label: <DropdownControlOptionLabel label={t('common:trucks-page.actions.import')} />,
                      onSelect: onImportAssets,
                  }
                : null,
            isAllowImport ? SpecialOptionEnum.separator : null,
            isAllowExport
                ? {
                      label: <DropdownControlOptionLabel label={t('common:trucks-page.actions.export-all')} />,
                      onSelect: onExportAssets,
                  }
                : null,
            isAllowExport
                ? {
                      label: <DropdownControlOptionLabel label={t('common:trucks-page.actions.export-filtered')} />,
                      onSelect: onExportFilteredAssets,
                  }
                : null,
        ];
    }, [t, isAllowImport, onImportAssets, isAllowExport, onExportAssets, onExportFilteredAssets]);

    return (
        <DropdownControl
            className={className}
            options={options}
            isDisabled={isLoading}
            testSelector={`${testSelector}_xls-actions`}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    isDisabled={isLoading}
                    leftIcon={
                        isLoading ? (
                            <ControlLoaderIcon fillColor={StyleGuideColorsEnum.light} size={DEFAULT_ICON_SIZE} />
                        ) : (
                            <XLSIcon fillColor={StyleGuideColorsEnum.charcoal} />
                        )
                    }
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default AssetsXLSDropdownControl;
