import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './EmissionClassCell.scss';

import { ApiTruckT } from 'common/store/trucks/models';
import EmissionClassPill from 'common/components/status-pill/EmissionClassPill/EmissionClassPill';

type PropsT = {
    truck: ApiTruckT;
};

const cx = classNames.bind(styles);

const EmissionClassCell: React.FC<PropsT> = React.memo((props) => {
    const { truck } = props;

    return (
        <div className={cx('emission-class')}>
            <EmissionClassPill isSymmetrical emissionClass={truck.emissionClass} />
        </div>
    );
});

export default EmissionClassCell;
