import { CompanyTrailersStateT } from './types';
import { SharableStateT } from '../models';
import { initialCompanyTrailersState } from './reducer';

const EMPTY_QUERY = {};

const selectCompanyState = (state: SharableStateT, companyId: CompanyIdT): CompanyTrailersStateT =>
    state.trailers[companyId] || initialCompanyTrailersState;

export const selectTrailersPages =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['pages'] =>
        selectCompanyState(state, companyId).pages;

export const selectTrailersQuery =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['query'] =>
        selectCompanyState(state, companyId).query || EMPTY_QUERY;

export const selectTrailersTotal =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['total'] =>
        selectCompanyState(state, companyId).total;

export const selectTrailersByIds =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['byId'] =>
        selectCompanyState(state, companyId).byId;

export const selectAddTrailerRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['addRequest'] =>
        selectCompanyState(state, companyId).addRequest;

export const selectDeleteTrailersRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['deleteRequest'] =>
        selectCompanyState(state, companyId).deleteRequest;

export const selectFetchTrailerDetailsRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['fetchDetailsRequest'] =>
        selectCompanyState(state, companyId).fetchDetailsRequest;

export const selectUpdateTrailersRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['updateRequest'] =>
        selectCompanyState(state, companyId).updateRequest;

export const selectTrailersDetailsByIds =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['detailsById'] =>
        selectCompanyState(state, companyId).detailsById;

export const selectFetchTrailersStatsRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['fetchStatsRequest'] =>
        selectCompanyState(state, companyId).fetchStatsRequest;

export const selectTrailersStats =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['stats'] =>
        selectCompanyState(state, companyId).stats;

export const selectTrailersExportXLSRequest =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['exportXLSRequest'] =>
        selectCompanyState(state, companyId).exportXLSRequest;

export const selectTrailersImportXLSRequest =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrailersStateT['importXLSRequest'] =>
        selectCompanyState(state, companyId).importXLSRequest;
