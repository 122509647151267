import { TFunction } from 'i18next';

import * as formCommonValidators from './form-common-validators';
import moment from 'moment';

export const checkNotZero = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, string | null | undefined>>,
): ReturnType<typeof formCommonValidators.checkNotZero> => {
    return formCommonValidators.checkNotZero(fields, values, () => {
        return t('common:form-errors.required');
    });
};

export const checkNotEmpty = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, object | number | string | null | undefined>>,
    label?: string,
): ReturnType<typeof formCommonValidators.checkNotEmpty> => {
    return formCommonValidators.checkNotEmpty(fields, values, () => {
        return label || t('common:form-errors.required');
    });
};

export const checkFullRange = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, DateRangeT | null | undefined>>,
): ReturnType<typeof formCommonValidators.checkFullRange> => {
    return formCommonValidators.checkFullRange(fields, values, () => {
        return t('common:form-errors.not-full-range');
    });
};

export const checkDateIsAfterDay = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, Date | null | undefined>>,
    limit: string | number | Date | null | undefined,
): ReturnType<typeof formCommonValidators.checkFullRange> => {
    return formCommonValidators.checkDateIsAfterDay(fields, values, limit, () => {
        return t('common:form-errors.date-is-after', {
            date: moment(limit).format('DD MMM YYYY'),
        });
    });
};

export const checkDateIsBeforeDay = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, Date | null | undefined>>,
    limit: string | number | Date | null | undefined,
): ReturnType<typeof formCommonValidators.checkFullRange> => {
    return formCommonValidators.checkDateIsBeforeDay(fields, values, limit, () => {
        return t('common:form-errors.date-is-before', {
            date: moment(limit).format('DD MMM YYYY'),
        });
    });
};

export const checkRangeNotIncludeDisabledDates = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, DateRangeT | null | undefined>>,
    disabledDates: string[],
): ReturnType<typeof formCommonValidators.checkRangeNotIncludeDisabledDates> => {
    return formCommonValidators.checkRangeNotIncludeDisabledDates(fields, values, disabledDates, () => {
        return t('common:form-errors.range-has-disabled-date');
    });
};

export const checkNotEmptyString = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, string | null | undefined>>,
): ReturnType<typeof formCommonValidators.checkNotEmptyString> => {
    return formCommonValidators.checkNotEmptyString(fields, values, () => {
        return t('common:form-errors.required');
    });
};

export const checkMinLength = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    minLength: number,
): ReturnType<typeof formCommonValidators.checkMinLength> => {
    return formCommonValidators.checkMinLength(fields, values, minLength, () => {
        return t('common:form-errors.too-short');
    });
};

export const checkMaxLength = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    maxLength: number,
): ReturnType<typeof formCommonValidators.checkMaxLength> => {
    return formCommonValidators.checkMaxLength(fields, values, maxLength, () => {
        return t('common:form-errors.too-long');
    });
};

export const checkMinValue = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    minValue: number,
): ReturnType<typeof formCommonValidators.checkMinValue> => {
    return formCommonValidators.checkMinValue(fields, values, minValue, ({ value }) => {
        return t('common:form-errors.too-small-number', {
            value,
        });
    });
};

export const checkIsPositiveNumberValue = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
): ReturnType<typeof formCommonValidators.checkStrictMinValue> => {
    return formCommonValidators.checkStrictMinValue(fields, values, 0, ({ value }) => {
        return t('common:form-errors.not-positive-number', {
            value,
        });
    });
};

export const checkMaxValue = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    maxValue: number,
): ReturnType<typeof formCommonValidators.checkMaxValue> => {
    return formCommonValidators.checkMaxValue(fields, values, maxValue, ({ value }) => {
        return t('common:form-errors.too-large-number', {
            value,
        });
    });
};

export const checkRoundValue = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
): ReturnType<typeof formCommonValidators.checkRoundValue> => {
    return formCommonValidators.checkRoundValue(fields, values, () => {
        return t('common:form-errors.not-rounded-number');
    });
};

export const checkEmail = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, object | number | string | null | undefined>>,
): ReturnType<typeof formCommonValidators.checkEmail> => {
    return formCommonValidators.checkEmail(fields, values, () => {
        return t('common:form-errors.invalid-email');
    });
};

export const checkDate = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, Date | null | undefined>,
): ReturnType<typeof formCommonValidators.checkDate> => {
    return formCommonValidators.checkDate(fields, values, () => {
        return t('common:form-errors.invalid-date');
    });
};

export const checkBirthday = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, Date | null | undefined>,
): ReturnType<typeof formCommonValidators.checkBirthday> => {
    return formCommonValidators.checkBirthday(fields, values, () => {
        return t('common:form-errors.invalid-birthday');
    });
};

export const checkPhoneNumber = <KeyT extends string>(
    t: TFunction,
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
): ReturnType<typeof formCommonValidators.checkPhoneNumber> => {
    return formCommonValidators.checkPhoneNumber(fields, values, () => {
        return t('common:form-errors.invalid-phone');
    });
};
