import * as React from 'react';
import { ReactNode } from 'react';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { useTranslation } from 'react-i18next';
import { clientConfig } from 'common/utils/client-config';

type PropsT = {
    fullName: ReactNode;
    userId: UserIdT | null | undefined;
    onOpenUserDetails: (userId: UserIdT, isBrokerUser: boolean) => void;
    byBroker?: boolean;
};

const UserLinkFormatter: React.FC<PropsT> = React.memo((props) => {
    const { fullName, userId, onOpenUserDetails, byBroker } = props;

    const { t } = useTranslation();

    if (byBroker && !clientConfig.isBrokerProject) {
        return <div>{t('common:broker-name')}</div>;
    }

    if (!userId) {
        return null;
    }

    return (
        <Link
            onClick={() => {
                onOpenUserDetails(userId, !!byBroker);
            }}
            theme={LinkThemeEnum.boldBrandDark}
        >
            {fullName}
        </Link>
    );
});

export default UserLinkFormatter;
