import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TableCellLink.scss';
import Link, { LinkPropsT } from 'common/components/Link/Link';
import { useClickInterceptorProps } from 'common/components/ClickInterceptorLabel/hooks/use-click-interceptor-props';

type PropsT = LinkPropsT & {
    wrapClassName?: string;
};

const cx = classNames.bind(styles);

const TableCellLink: React.FC<PropsT> = React.memo((props) => {
    const clickInterceptorProps = useClickInterceptorProps({
        onClick: props.onClick || null,
    });

    return (
        <div className={props.wrapClassName}>
            <span className={cx('link')}>
                <Link {...props} {...clickInterceptorProps} />
            </span>
        </div>
    );
});

export default TableCellLink;
