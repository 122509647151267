import {
    ADD_TRUCK_REQUEST_BEGIN,
    ADD_TRUCK_REQUEST_ERROR,
    ADD_TRUCK_REQUEST_SUCCESS,
    CompanyTrucksStateT,
    DELETE_TRUCKS_REQUEST_BEGIN,
    DELETE_TRUCKS_REQUEST_ERROR,
    DELETE_TRUCKS_REQUEST_SUCCESS,
    FETCH_TRUCK_DETAILS_REQUEST_BEGIN,
    FETCH_TRUCK_DETAILS_REQUEST_ERROR,
    FETCH_TRUCK_DETAILS_REQUEST_SUCCESS,
    FETCH_TRUCKS_PAGE_REQUEST_BEGIN,
    FETCH_TRUCKS_PAGE_REQUEST_ERROR,
    FETCH_TRUCKS_PAGE_REQUEST_SUCCESS,
    FETCH_TRUCKS_STATS_REQUEST_BEGIN,
    FETCH_TRUCKS_STATS_REQUEST_ERROR,
    FETCH_TRUCKS_STATS_REQUEST_SUCCESS,
    RESET_TRUCKS,
    TrucksActionT,
    TrucksStateT,
    UPDATE_TRUCKS_REQUEST_BEGIN,
    UPDATE_TRUCKS_REQUEST_ERROR,
    UPDATE_TRUCKS_REQUEST_SUCCESS,
    EXPORT_XLS_TRUCKS_REQUEST_SUCCESS,
    IMPORT_XLS_TRUCKS_REQUEST_BEGIN,
    IMPORT_XLS_TRUCKS_REQUEST_ERROR,
    IMPORT_XLS_TRUCKS_REQUEST_SUCCESS,
    EXPORT_XLS_TRUCKS_REQUEST_BEGIN,
    EXPORT_XLS_TRUCKS_REQUEST_ERROR,
} from './types';
import requestStatus from 'common/utils/request-status';
import {
    pageBeginReducer,
    pageErrorReducer,
    pageSuccessReducer,
    resetPagesCacheReducer,
} from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCompanyTrucksState: CompanyTrucksStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
    addRequest: requestStatus.initial,
    deleteRequest: requestStatus.initial,
    updateRequest: requestStatus.initial,
    detailsById: {},
    fetchDetailsRequest: requestStatus.initial,
    stats: null,
    fetchStatsRequest: requestStatus.initial,
    importXLSRequest: requestStatus.initial,
    exportXLSRequest: requestStatus.initial,
};

const initialState: TrucksStateT = {};

export default (state = initialState, action: TrucksActionT | DestroySessionActionT): TrucksStateT => {
    switch (action.type) {
        case FETCH_TRUCKS_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query, companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageBeginReducer(companyState, pageNumber, query),
                },
            };
        }

        case FETCH_TRUCKS_PAGE_REQUEST_SUCCESS: {
            const { query, pageNumber, pageResponse, companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                [companyId]: {
                    ...companyState,
                    ...pageSuccessReducer(companyState, pageNumber, query, pageResponse),
                },
            };
        }
        case FETCH_TRUCKS_PAGE_REQUEST_ERROR: {
            const { query, error, pageNumber, companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageErrorReducer(companyState, pageNumber, query, error),
                },
            };
        }

        case ADD_TRUCK_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    addRequest: requestStatus.loading,
                },
            };
        }

        case ADD_TRUCK_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    addRequest: requestStatus.setError(error),
                },
            };
        }

        case ADD_TRUCK_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    addRequest: requestStatus.ok,
                },
            };
        }

        case DELETE_TRUCKS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteRequest: requestStatus.loading,
                },
            };
        }

        case DELETE_TRUCKS_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteRequest: requestStatus.setError(error),
                },
            };
        }

        case DELETE_TRUCKS_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteRequest: requestStatus.ok,
                },
            };
        }

        case RESET_TRUCKS: {
            const { companyId, mutationListOptions } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...initialState,
                [companyId]: {
                    ...initialCompanyTrucksState,
                    ...resetPagesCacheReducer(initialCompanyTrucksState, companyState, mutationListOptions),
                },
            };
        }

        case UPDATE_TRUCKS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateRequest: requestStatus.loading,
                },
            };
        }

        case UPDATE_TRUCKS_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateRequest: requestStatus.setError(error),
                },
            };
        }

        case UPDATE_TRUCKS_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateRequest: requestStatus.ok,
                },
            };
        }

        case FETCH_TRUCK_DETAILS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchDetailsRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_TRUCK_DETAILS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchDetailsRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_TRUCK_DETAILS_REQUEST_SUCCESS: {
            const { companyId, truckId, details } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchDetailsRequest: requestStatus.ok,
                    detailsById: {
                        ...companyState.detailsById,
                        [truckId]: details,
                    },
                },
            };
        }

        case FETCH_TRUCKS_STATS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchStatsRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_TRUCKS_STATS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchStatsRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_TRUCKS_STATS_REQUEST_SUCCESS: {
            const { companyId, stats } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchStatsRequest: requestStatus.ok,
                    stats,
                },
            };
        }

        case IMPORT_XLS_TRUCKS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    importXLSRequest: requestStatus.loading,
                },
            };
        }

        case IMPORT_XLS_TRUCKS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    importXLSRequest: requestStatus.setError(error),
                },
            };
        }

        case IMPORT_XLS_TRUCKS_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    importXLSRequest: requestStatus.ok,
                },
            };
        }

        case EXPORT_XLS_TRUCKS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    exportXLSRequest: requestStatus.loading,
                },
            };
        }

        case EXPORT_XLS_TRUCKS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    exportXLSRequest: requestStatus.setError(error),
                },
            };
        }

        case EXPORT_XLS_TRUCKS_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyTrucksState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    exportXLSRequest: requestStatus.ok,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
