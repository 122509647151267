import React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const WrenchIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                    d="M10 2.286A7.72 7.72 0 0 0 2.286 10 7.72 7.72 0 0 0 10 17.714 7.72 7.72 0 0 0 17.714 10 7.72 7.72 0 0 0 10 2.286zM12.597 14.5l-2.906-2.906c-1.08.335-2.301.103-3.15-.758a3.113 3.113 0 0 1-.552-3.652L8.084 9.28 9.41 7.956 7.313 5.86a3.113 3.113 0 0 1 4.268 4.076l2.79 2.79-1.774 1.774z"
                    fill="none"
                />
                <path
                    d="M10 1c4.963 0 9 4.037 9 9s-4.037 9-9 9-9-4.037-9-9 4.037-9 9-9zm0 1.286A7.72 7.72 0 0 0 2.286 10 7.72 7.72 0 0 0 10 17.714 7.72 7.72 0 0 0 17.714 10 7.72 7.72 0 0 0 10 2.286zM7.313 5.86a3.113 3.113 0 0 1 3.651.553c.952.951 1.145 2.366.617 3.523l2.79 2.79-1.774 1.774-2.906-2.906c-1.08.335-2.301.09-3.15-.758a3.113 3.113 0 0 1-.552-3.652L8.084 9.28 9.41 7.956z"
                    fill={fillColor}
                />
            </g>
        </svg>
    );
};

WrenchIcon.displayName = 'WrenchIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.orange,
};

export { storyProps };

export default WrenchIcon;
