import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './StatusCell.scss';
import { ApiUserStatusT, UserStatusEnum } from 'common/utils/api/models';

type PropsT = {
    userStatus: ApiUserStatusT | undefined;
};

const cx = classNames.bind(styles);

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { children, userStatus } = props;

    return (
        <div
            className={cx('status', {
                'status--pending': userStatus === UserStatusEnum.pending,
            })}
        >
            {children}
        </div>
    );
});

export default StatusCell;
