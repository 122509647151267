import { TrailerStatusEnum } from 'common/utils/api/models';
import { CountryCodeT } from 'common/store/countries-dict/models';

export enum FieldsEnum {
    dictTrailerTypeId = 'dict-trailer-type-id',
    status = 'status',
    plateNumber = 'plate-number',
    country = 'country',
    lastTelematicUpdateDates = 'last-telematic-update-dates',
    contractId = 'contractId',
    contractName = 'contractName',
    createDates = 'create-dates',
    createdByUserId = 'create-dated-by-user-id',
    createdByUserName = 'create-dated-by-user-name',
    lastModifyDates = 'last-modify-dates',
    lastModifyByUserId = 'last-modify-by-user-id',
    lastModifyByUserName = 'last-modify-by-user-name',
}

export type FormValuesT = {
    [FieldsEnum.dictTrailerTypeId]: Array<TrailerDictIdT>;
    [FieldsEnum.plateNumber]: string;
    [FieldsEnum.status]: Array<TrailerStatusEnum>;
    [FieldsEnum.country]: Array<CountryCodeT>;
    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.createdByUserId]: Array<UserIdT>;
    [FieldsEnum.createdByUserName]: Array<string>;
    [FieldsEnum.lastModifyDates]: DateRangeT | null;
    [FieldsEnum.lastModifyByUserId]: Array<UserIdT>;
    [FieldsEnum.lastModifyByUserName]: Array<string>;
    [FieldsEnum.lastTelematicUpdateDates]: DateRangeT | null;
    [FieldsEnum.contractId]: Array<CarrierContractIdT>;
    [FieldsEnum.contractName]: Array<string>;
};
