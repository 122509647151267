import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';

export type ImportXLSAssetsDialogModalDataT = {
    file: File;
};

type PropsT = {
    assetType: AssetTypeEnum;
    data: ImportXLSAssetsDialogModalDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onImportXLS: (data: ImportXLSAssetsDialogModalDataT) => void;
    requestStatus: RequestStatusT;
};

const CONFIRMATION_T_MAP: Record<
    AssetTypeEnum,
    {
        title: string;
        message: string;
    }
> = {
    [AssetTypeEnum.truck]: {
        title: 'common:trucks-page.xls-import-confirmation.title',
        message: 'common:trucks-page.xls-import-confirmation.message',
    },
    [AssetTypeEnum.trailer]: {
        title: 'common:trailers-page.xls-import-confirmation.title',
        message: 'common:trailers-page.xls-import-confirmation.message',
    },
};

const SUCCESS_T_MAP: Record<
    AssetTypeEnum,
    {
        title: string;
        message: string;
    }
> = {
    [AssetTypeEnum.truck]: {
        title: 'common:trucks-page.success-xls-import-dialog.title',
        message: 'common:trucks-page.success-xls-import-dialog.message',
    },
    [AssetTypeEnum.trailer]: {
        title: 'common:trailers-page.success-xls-import-dialog.title',
        message: 'common:trailers-page.success-xls-import-dialog.message',
    },
};

const TEST_SELECTOR = 'import-xls-assets-dialog-modal';

const ImportXLSAssetsDialogModal: React.FC<PropsT> = React.memo((props) => {
    const { assetType, data, onClose, onCancel, onImportXLS, requestStatus } = props;

    const { t } = useTranslation();

    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    React.useEffect(() => {
        setIsSuccess(!!requestStatus?.ok);
    }, [requestStatus?.ok]);

    useOnlyChangesEffect(() => {
        if (requestStatus?.error) {
            onClose();
        }
    }, [requestStatus?.error]);

    if (!data) {
        return null;
    }

    const isLoading = !!requestStatus?.loading;

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                {isSuccess ? (
                    <ModalDialog
                        width={DEFAULT_MODAL_DIALOG_WIDTH}
                        testSelector={TEST_SELECTOR}
                        actions={[
                            {
                                children: t('common:error-modal.actions.ok'),
                                theme: ButtonThemeEnum.secondary,
                                testSelector: `${TEST_SELECTOR}_ok`,
                                isLoading,
                                isDisabled: isLoading,
                                onClick: () => {
                                    onClose();
                                },
                            },
                        ]}
                        icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                        title={t(SUCCESS_T_MAP[assetType].title)}
                        message={t(SUCCESS_T_MAP[assetType].message)}
                    />
                ) : (
                    <ModalDialog
                        width={DEFAULT_MODAL_DIALOG_WIDTH}
                        testSelector={TEST_SELECTOR}
                        actions={[
                            {
                                children: t('common:error-modal.actions.cancel'),
                                theme: ButtonThemeEnum.secondary,
                                testSelector: `${TEST_SELECTOR}_cancel`,
                                isDisabled: isLoading,
                                onClick: () => {
                                    onCancel();
                                },
                            },
                            {
                                children: t('common:error-modal.actions.update'),
                                theme: ButtonThemeEnum.danger,
                                testSelector: `${TEST_SELECTOR}_update`,
                                isLoading,
                                isDisabled: isLoading,
                                onClick: () => {
                                    onImportXLS(data);
                                },
                            },
                        ]}
                        icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                        title={t(CONFIRMATION_T_MAP[assetType].title)}
                        message={t(CONFIRMATION_T_MAP[assetType].message)}
                    />
                )}
            </ModalContent>
        </Modal>
    );
});

export default ImportXLSAssetsDialogModal;
