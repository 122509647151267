import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty, checkRangeNotIncludeDisabledDates } from 'common/utils/form-validators';
import isNumber from 'lodash/isNumber';
import { CountrySettingsByCodeT } from 'common/store/country-settings-dict/models';
import { checkIsAvailableCountryForServiceMaintananceAsset } from 'common/store/country-settings-dict/utils/check-location';

const REQUIRED_FIELDS = [FieldsEnum.dateRange, FieldsEnum.dropoffLocation] as const;

type MetaT = {
    disabledDates: string[];
    countriesSettingsByCode: CountrySettingsByCodeT;
};

const validate = (t: TFunction, values: FormValuesT, meta: MetaT): FormErrorsT => {
    const { disabledDates, countriesSettingsByCode } = meta;

    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkRangeNotIncludeDisabledDates(t, [FieldsEnum.dateRange], values, disabledDates),
    };

    if (
        values[FieldsEnum.dropoffLocation] &&
        (!isNumber(values[FieldsEnum.dropoffLocation]?.point?.lng) ||
            !isNumber(values[FieldsEnum.dropoffLocation]?.point?.lat))
    ) {
        errors[FieldsEnum.dropoffLocation] = t('common:assets.set-unavailable.errors.wrong-location');
    }

    const dropoffLocation = values[FieldsEnum.dropoffLocation];
    if (!checkIsAvailableCountryForServiceMaintananceAsset(dropoffLocation, countriesSettingsByCode)) {
        errors[FieldsEnum.dropoffLocation] = t('common:assets.set-unavailable.errors.unsupported-country');
    }

    return errors;
};

export default validate;
