import { CountrySettingsByCodeT, CountrySettingsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const COUNTRY_SETTINGS_DICT_REQUEST = 'common/country-settings-dict/COUNTRY_SETTINGS_DICT_REQUEST';
export const COUNTRY_SETTINGS_DICT_REQUEST_BEGIN = 'common/country-settings-dict/COUNTRY_SETTINGS_DICT_REQUEST_BEGIN';
export const COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS =
    'common/country-settings-dict/COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS';
export const COUNTRY_SETTINGS_DICT_REQUEST_ERROR = 'common/country-settings-dict/COUNTRY_SETTINGS_DICT_REQUEST_ERROR';

export type FetchCountrySettingsDictActionT = {
    type: typeof COUNTRY_SETTINGS_DICT_REQUEST;
};

export type FetchCountrySettingsDictBeginActionT = {
    type: typeof COUNTRY_SETTINGS_DICT_REQUEST_BEGIN;
};

export type FetchCountrySettingsDictSuccessActionT = {
    type: typeof COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS;
    list: Array<CountrySettingsT>;
};

export type FetchCountrySettingsDictErrorActionT = {
    type: typeof COUNTRY_SETTINGS_DICT_REQUEST_ERROR;
    error: Error;
};

export type CountrySettingsDictActionT =
    | FetchCountrySettingsDictActionT
    | FetchCountrySettingsDictBeginActionT
    | FetchCountrySettingsDictSuccessActionT
    | FetchCountrySettingsDictErrorActionT;

export type CountrySettingsDictStateT = {
    byCountryCode: CountrySettingsByCodeT;
    request: RequestStatusT;
};
