import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectTrailersDictById, selectTrailersDictRequest } from 'common/store/trailers-dict/selectors';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';

type PropsT = {
    isShortLabel?: boolean;
    trailerDictId?: TrailerDictIdT | null;
};

const TrailerDictIdFilterPill: React.FC<PropsT> = React.memo((props) => {
    const { isShortLabel, trailerDictId } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const dictTrailerById = useSelector(selectTrailersDictById);
    const fetchRequest = useSelector(selectTrailersDictRequest);

    if (!trailerDictId) {
        return null;
    }

    if (fetchRequest.loading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;
    }

    const dictTrailer = dictTrailerById[trailerDictId as string];
    if (!dictTrailer) {
        return null;
    }

    return (
        <span>
            {isShortLabel
                ? dictTrailer.model || ''
                : t('common:trailer-subtype-template', {
                      length: dictTrailer.length,
                      model: dictTrailer.model,
                      eur1Pallets: dictTrailer.eur1Pallets,
                      eur2Pallets: dictTrailer.eur2Pallets,
                  })}
        </span>
    );
});

export default TrailerDictIdFilterPill;
