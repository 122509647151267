import {
    COUNTRY_SETTINGS_DICT_REQUEST,
    COUNTRY_SETTINGS_DICT_REQUEST_BEGIN,
    COUNTRY_SETTINGS_DICT_REQUEST_ERROR,
    COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS,
    FetchCountrySettingsDictActionT,
    FetchCountrySettingsDictBeginActionT,
    FetchCountrySettingsDictErrorActionT,
    FetchCountrySettingsDictSuccessActionT,
} from './types';
import { CountrySettingsT } from './models';

export const fetchCountrySettingsDict = (): FetchCountrySettingsDictActionT => ({
    type: COUNTRY_SETTINGS_DICT_REQUEST,
});

export const fetchCountrySettingsDictBegin = (): FetchCountrySettingsDictBeginActionT => ({
    type: COUNTRY_SETTINGS_DICT_REQUEST_BEGIN,
});

export const fetchCountrySettingsDictSuccess = (
    list: Array<CountrySettingsT>,
): FetchCountrySettingsDictSuccessActionT => ({
    type: COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS,
    list,
});

export const fetchCountrySettingsDictError = (error: Error): FetchCountrySettingsDictErrorActionT => ({
    type: COUNTRY_SETTINGS_DICT_REQUEST_ERROR,
    error,
});
