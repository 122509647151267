import isEmpty from 'lodash/isEmpty';
import { CountryCodeT } from 'common/store/countries-dict/models';
import { CountrySettingsByCodeT } from 'common/store/country-settings-dict/models';

export const checkIsAvailableCountryForShip = (
    location: LocationT | null,
    countriesSettingsByCode: CountrySettingsByCodeT,
): boolean => {
    if (isEmpty(countriesSettingsByCode) || !location) {
        return true;
    }

    const countryCode = location?.addressComponents?.countryCode;
    if (!countryCode) {
        // check only has countryCode
        return true;
    }

    return !!countriesSettingsByCode[countryCode.toUpperCase() as CountryCodeT]?.ship;
};

export const checkIsAvailableCountryForDropAsset = (
    location: LocationT | null,
    countriesSettingsByCode: CountrySettingsByCodeT,
): boolean => {
    return checkIsAvailableCountryForShip(location, countriesSettingsByCode);
};

export const checkIsAvailableCountryForServiceMaintananceAsset = (
    location: LocationT | null,
    countriesSettingsByCode: CountrySettingsByCodeT,
): boolean => {
    return checkIsAvailableCountryForShip(location, countriesSettingsByCode);
};
