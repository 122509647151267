import { FieldsEnum, FormValuesT } from './constants';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'common/layouts/TrailersPageLayout/query-models';
import { parseDateFromQuery } from 'common/utils/query';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.dictTrailerTypeId]: queryFilters?.[QueryFiltersKeysEnum.dictTrailerTypeId] || [],
        [FieldsEnum.plateNumber]: queryFilters?.[QueryFiltersKeysEnum.plateNumber] || '',
        [FieldsEnum.status]: queryFilters?.[QueryFiltersKeysEnum.status] || [],
        [FieldsEnum.country]: queryFilters?.[QueryFiltersKeysEnum.country] || [],
        [FieldsEnum.createDates]: null,
        [FieldsEnum.createdByUserId]: queryFilters?.[QueryFiltersKeysEnum.createdByUserId] || [],
        [FieldsEnum.createdByUserName]: queryFilters?.[QueryFiltersKeysEnum.createdByUserName] || [],
        [FieldsEnum.lastModifyDates]: null,
        [FieldsEnum.lastModifyByUserId]: queryFilters?.[QueryFiltersKeysEnum.lastModifyByUserId] || [],
        [FieldsEnum.lastModifyByUserName]: queryFilters?.[QueryFiltersKeysEnum.lastModifyByUserName] || [],
        [FieldsEnum.lastTelematicUpdateDates]: null,
        [FieldsEnum.contractId]: queryFilters?.[QueryFiltersKeysEnum.contractId] || [],
        [FieldsEnum.contractName]: queryFilters?.[QueryFiltersKeysEnum.contractName] || [],
    };

    if (queryFilters[QueryFiltersKeysEnum.createdDateFrom]) {
        values[FieldsEnum.createDates] = {
            from: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.createdDateFrom]),
            to: null,
        };
    }

    if (queryFilters[QueryFiltersKeysEnum.createdDateTo]) {
        values[FieldsEnum.createDates] = {
            from: values[FieldsEnum.createDates]?.from,
            to: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.createdDateTo]),
        };
    }

    if (queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateFrom]) {
        values[FieldsEnum.lastTelematicUpdateDates] = {
            from: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateFrom]),
            to: null,
        };
    }

    if (queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateTo]) {
        values[FieldsEnum.lastTelematicUpdateDates] = {
            from: values[FieldsEnum.lastTelematicUpdateDates]?.from,
            to: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateTo]),
        };
    }

    if (queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom]) {
        values[FieldsEnum.lastModifyDates] = {
            from: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom]),
            to: null,
        };
    }

    if (queryFilters[QueryFiltersKeysEnum.lastModifyDateTo]) {
        values[FieldsEnum.lastModifyDates] = {
            from: values[FieldsEnum.lastModifyDates]?.from,
            to: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.lastModifyDateTo]),
        };
    }

    return values;
};

export default getInitialValues;
