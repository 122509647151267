import {
    ApiNotificationT,
    ApiOrderStatusT,
    ApiTourStatusT,
    ApiDocumentTypeT,
    FixPageableCommonQueryT,
} from 'common/utils/api/models';

import * as React from 'react';

export enum NotificationActionEnum {
    SHIPMENT_STATUS_CHANGED = 'SHIPMENT_STATUS_CHANGED',
    SHIPMENT_MISSED = 'SHIPMENT_MISSED',
    SHIPMENT_NO_SHOW = 'SHIPMENT_NO_SHOW',
    ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED',
    TOUR_STATUS_CHANGED = 'TOUR_STATUS_CHANGED',
    RUNNING_OUT_OF_TIME_ASSIGN_ASSETS = 'RUNNING_OUT_OF_TIME_ASSIGN_ASSETS',
    RUN_OUT_OF_TIME_ASSIGN_ASSETS = 'RUN_OUT_OF_TIME_ASSIGN_ASSETS',
    RUNNING_OUT_OF_TIME_ASSIGN_DRIVER = 'RUNNING_OUT_OF_TIME_ASSIGN_DRIVER',
    RUN_OUT_OF_TIME_ASSIGN_DRIVER = 'RUN_OUT_OF_TIME_ASSIGN_DRIVER',
    TRANSPORTATION_ORDER_STATUS_CHANGED = 'TRANSPORTATION_ORDER_STATUS_CHANGED',
    TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED = 'TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED',
    RFQ_ALERT = 'RFQ_ALERT',
    SPOT_PLACED = 'SPOT_PLACED',
    SPOT_CANCELLED = 'SPOT_CANCELLED',
    SPOT_ASSIGNED = 'SPOT_ASSIGNED',
    SPOT_EXPIRED = 'SPOT_EXPIRED',
    SPOT_BID_ACCEPTED = 'SPOT_BID_ACCEPTED',
    SPOT_BID_EXPIRED = 'SPOT_BID_EXPIRED',
    SPOT_BID_MISSED = 'SPOT_BID_MISSED',
    SPOT_BID_PLACED = 'SPOT_BID_PLACED',
    DOCUMENT_COMPLETED = 'DOCUMENT_COMPLETED',
    DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
    DOCUMENT_REVOKED = 'DOCUMENT_REVOKED',
    DOCUMENT_UPDATED = 'DOCUMENT_UPDATED',
    DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
    DOCUMENT_EXPIRATION_SOON = 'DOCUMENT_EXPIRATION_SOON',
    SHIPPER_CONTRACT_EXPIRED = 'SHIPPER_CONTRACT_EXPIRED',
    SHIPPER_CONTRACT_REVOKED = 'SHIPPER_CONTRACT_REVOKED',
    SHIPPER_CONTRACT_UPLOADED = 'SHIPPER_CONTRACT_UPLOADED',
    SHIPPER_CONTRACT_UPDATED = 'SHIPPER_CONTRACT_UPDATED',
    SHIPPER_CONTRACT_EXPIRATION_SOON = 'SHIPPER_CONTRACT_EXPIRATION_SOON',
    SHIPPER_CONTRACT_COMPLETED = 'SHIPPER_CONTRACT_COMPLETED',
    CARRIER_CONTRACT_EXPIRED = 'CARRIER_CONTRACT_EXPIRED',
    CARRIER_CONTRACT_REVOKED = 'CARRIER_CONTRACT_REVOKED',
    CARRIER_CONTRACT_UPDATED = 'CARRIER_CONTRACT_UPDATED',
    CARRIER_CONTRACT_EXPIRATION_SOON = 'CARRIER_CONTRACT_EXPIRATION_SOON',
    CARRIER_CONTRACT_COMPLETED = 'CARRIER_CONTRACT_COMPLETED',
    SHIPPER_CONTRACT_LANE_EXPIRED = 'SHIPPER_CONTRACT_LANE_EXPIRED',
    SHIPPER_CONTRACT_LANE_REVOKED = 'SHIPPER_CONTRACT_LANE_REVOKED',
    SHIPPER_CONTRACT_LANE_UPDATED = 'SHIPPER_CONTRACT_LANE_UPDATED',
    SHIPPER_CONTRACT_LANE_UPLOAD = 'SHIPPER_CONTRACT_LANE_UPLOAD',
    SHIPPER_CONTRACT_LANE_EXPIRATION_SOON = 'SHIPPER_CONTRACT_LANE_EXPIRATION_SOON',
    SHIPPER_CONTRACT_LANE_COMPLETED = 'SHIPPER_CONTRACT_LANE_COMPLETED',
    TOUR_RUN_OR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER = 'TOUR_RUN_OR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER',
    TOUR_RUN_OUT_OF_TIME_TO_ASSIGN_CARRIER = 'TOUR_RUN_OUT_OF_TIME_TO_ASSIGN_CARRIER',
    TOUR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER = 'TOUR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER',
    ERROR_WHEN_CREATE_INVOICE = 'ERROR_WHEN_CREATE_INVOICE',
    UNKNOWN_ACTION = 'unknown',
}

export enum NotificationIconEnum {
    success = 'success',
    attention = 'attention',
    fail = 'fail',
    new = 'new',
    expired = 'expired',
}

export type NotificationToastViewT = {
    icon: NotificationIconEnum | null;
    text: React.ReactNode;
};

export enum NotificationEmotionEnum {
    neutral = 'neutral',
    positive = 'positive',
    negative = 'negative',
    attention = 'attention',
}

export type NotificationViewT = {
    emotion: NotificationEmotionEnum | null;
    text: React.ReactNode;
};

type NotificationT<T extends NotificationActionEnum> = OverwriteT<
    NotificationDescriptorT[T],
    {
        action: T;
        relatedIds: string[];
        isNew: boolean;
    }
>;

export type AnyNotificationT =
    | NotificationT<NotificationActionEnum.SHIPMENT_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.SHIPMENT_MISSED>
    | NotificationT<NotificationActionEnum.SHIPMENT_NO_SHOW>
    | NotificationT<NotificationActionEnum.ORDER_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.TOUR_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS>
    | NotificationT<NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS>
    | NotificationT<NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER>
    | NotificationT<NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER>
    | NotificationT<NotificationActionEnum.TOUR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER>
    | NotificationT<NotificationActionEnum.TOUR_RUN_OUT_OF_TIME_TO_ASSIGN_CARRIER>
    | NotificationT<NotificationActionEnum.TOUR_RUN_OR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER>
    | NotificationT<NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED>
    | NotificationT<NotificationActionEnum.RFQ_ALERT>
    | NotificationT<NotificationActionEnum.SPOT_PLACED>
    | NotificationT<NotificationActionEnum.SPOT_CANCELLED>
    | NotificationT<NotificationActionEnum.SPOT_ASSIGNED>
    | NotificationT<NotificationActionEnum.SPOT_EXPIRED>
    | NotificationT<NotificationActionEnum.SPOT_BID_ACCEPTED>
    | NotificationT<NotificationActionEnum.SPOT_BID_EXPIRED>
    | NotificationT<NotificationActionEnum.SPOT_BID_MISSED>
    | NotificationT<NotificationActionEnum.SPOT_BID_PLACED>
    | NotificationT<NotificationActionEnum.DOCUMENT_EXPIRED>
    | NotificationT<NotificationActionEnum.DOCUMENT_REVOKED>
    | NotificationT<NotificationActionEnum.DOCUMENT_UPDATED>
    | NotificationT<NotificationActionEnum.DOCUMENT_UPLOAD>
    | NotificationT<NotificationActionEnum.DOCUMENT_COMPLETED>
    | NotificationT<NotificationActionEnum.DOCUMENT_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_REVOKED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_UPDATED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_EXPIRED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_REVOKED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_UPDATED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_COMPLETED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED>
    | NotificationT<NotificationActionEnum.ERROR_WHEN_CREATE_INVOICE>
    | NotificationT<NotificationActionEnum.UNKNOWN_ACTION>;

type NotificationConverterOptionsT = {
    isNew: boolean;
};

export type NotificationConverterT = (
    apiNotification: ApiNotificationT,
    options: NotificationConverterOptionsT,
) => AnyNotificationT;

export type PatchNotificationDataT<TNotification extends { data: {} }, TPatchData extends {}> = OverwriteT<
    TNotification,
    {
        data: OverwriteT<TNotification['data'], TPatchData>;
    }
>;

type TourStatusChangedNotificationDtoT = PatchNotificationDataT<
    Api2.Definitions.StatusChangedNotificationDto,
    {
        status: ApiTourStatusT;
    }
>;

type DocumentNotificationDtoT = PatchNotificationDataT<
    Api2.Definitions.DocumentNotificationDto,
    {
        documentType: ApiDocumentTypeT;
    }
>;

export type NotificationDescriptorT = {
    [NotificationActionEnum.SHIPMENT_STATUS_CHANGED]: Api2.Definitions.ShipmentStatusChangedNotificationDto;
    [NotificationActionEnum.SHIPMENT_MISSED]: Api2.Definitions.ShipmentMissedNotificationDto;
    [NotificationActionEnum.SHIPMENT_NO_SHOW]: Api2.Definitions.ShipmentNoShowNotificationDto;
    [NotificationActionEnum.ORDER_STATUS_CHANGED]: PatchNotificationDataT<
        Api2.Definitions.StatusChangedNotificationDto,
        {
            status: ApiOrderStatusT;
        }
    >;
    [NotificationActionEnum.TOUR_STATUS_CHANGED]: TourStatusChangedNotificationDtoT;
    [NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS]: TourStatusChangedNotificationDtoT;
    [NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS]: TourStatusChangedNotificationDtoT;
    [NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER]: TourStatusChangedNotificationDtoT;
    [NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER]: TourStatusChangedNotificationDtoT;
    [NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED]: Api2.Definitions.TransportationOrderStatusChangedNotificationDto;
    [NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED]: Api2.Definitions.TransportationOrderStatusChangedNotificationDto;
    [NotificationActionEnum.SPOT_CANCELLED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.SPOT_ASSIGNED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.SPOT_EXPIRED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.SPOT_BID_ACCEPTED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.SPOT_BID_EXPIRED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.SPOT_BID_MISSED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.SPOT_BID_PLACED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.SPOT_PLACED]: Api2.Definitions.SpotNotificationDto;
    [NotificationActionEnum.RFQ_ALERT]: Api2.Definitions.RFQNotificationDto;
    [NotificationActionEnum.DOCUMENT_COMPLETED]: DocumentNotificationDtoT;
    [NotificationActionEnum.DOCUMENT_EXPIRED]: DocumentNotificationDtoT;
    [NotificationActionEnum.DOCUMENT_EXPIRATION_SOON]: DocumentNotificationDtoT;
    [NotificationActionEnum.DOCUMENT_REVOKED]: DocumentNotificationDtoT;
    [NotificationActionEnum.DOCUMENT_UPDATED]: DocumentNotificationDtoT;
    [NotificationActionEnum.DOCUMENT_UPLOAD]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_REVOKED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_UPDATED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED]: DocumentNotificationDtoT;
    [NotificationActionEnum.CARRIER_CONTRACT_EXPIRED]: DocumentNotificationDtoT;
    [NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON]: DocumentNotificationDtoT;
    [NotificationActionEnum.CARRIER_CONTRACT_REVOKED]: DocumentNotificationDtoT;
    [NotificationActionEnum.CARRIER_CONTRACT_UPDATED]: DocumentNotificationDtoT;
    [NotificationActionEnum.CARRIER_CONTRACT_COMPLETED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON]: DocumentNotificationDtoT;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED]: DocumentNotificationDtoT;
    [NotificationActionEnum.TOUR_RUN_OR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER]: Api2.Definitions.TourRunOrRunningOutOfTimeToAssignCarrier;
    [NotificationActionEnum.TOUR_RUN_OUT_OF_TIME_TO_ASSIGN_CARRIER]: Api2.Definitions.TourRunOrRunningOutOfTimeToAssignCarrier;
    [NotificationActionEnum.TOUR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER]: Api2.Definitions.TourRunOrRunningOutOfTimeToAssignCarrier;
    [NotificationActionEnum.ERROR_WHEN_CREATE_INVOICE]: Api2.Definitions.InvoiceCreationExceptionNotificationDto;
    [NotificationActionEnum.UNKNOWN_ACTION]: Api2.Definitions.NotificationDto & { data: any };
};

export type FetchNotificationsPageQueryT = FixPageableCommonQueryT;
