import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    // eslint-disable-next-line react/no-unused-prop-types
    lat?: number;
    // eslint-disable-next-line react/no-unused-prop-types
    lng?: number;
    className?: string;
    isWrong: boolean;
};

const ServicePinIcon: React.FC<PropsT> = (props) => {
    const { className, isWrong } = props;

    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="40" height="45" viewBox="0 0 40 45">
            <g fill="none" fillRule="evenodd">
                <path
                    fill={isWrong ? StyleGuideColorsEnum.tomatoRed : StyleGuideColorsEnum.charcoal}
                    d="M25 0c8.284 0 15 6.716 15 15v10c0 8.284-6.716 15-15 15l-5 5-5-5C6.716 40 0 33.284 0 25V15C0 6.716 6.716 0 15 0h10z"
                />
                <path d="M10 10h20v20H10z" />
                <path d="M20 12.286A7.72 7.72 0 0012.286 20 7.72 7.72 0 0020 27.714 7.72 7.72 0 0027.714 20 7.72 7.72 0 0020 12.286zM22.597 24.5l-2.906-2.906c-1.08.335-2.301.103-3.15-.758a3.113 3.113 0 01-.552-3.652l2.095 2.096 1.325-1.324-2.096-2.096a3.113 3.113 0 014.268 4.076l2.79 2.79-1.774 1.774z" />
                <path
                    fill={isWrong ? StyleGuideColorsEnum.white : StyleGuideColorsEnum.orange}
                    d="M20 11c4.963 0 9 4.037 9 9s-4.037 9-9 9-9-4.037-9-9 4.037-9 9-9zm0 1.286A7.72 7.72 0 0012.286 20 7.72 7.72 0 0020 27.714 7.72 7.72 0 0027.714 20 7.72 7.72 0 0020 12.286zm-2.687 3.574a3.113 3.113 0 013.651.553c.952.951 1.145 2.366.617 3.523l2.79 2.79-1.774 1.774-2.906-2.906c-1.08.335-2.301.09-3.15-.758a3.113 3.113 0 01-.552-3.652l2.095 2.096 1.325-1.324z"
                />
            </g>
        </svg>
    );
};

ServicePinIcon.displayName = 'ServicePinIcon';

const storyProps: PropsT = {
    isWrong: false,
};

export { storyProps };
export default ServicePinIcon;
