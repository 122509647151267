import {
    COUNTRY_SETTINGS_DICT_REQUEST_BEGIN,
    COUNTRY_SETTINGS_DICT_REQUEST_ERROR,
    COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS,
    CountrySettingsDictActionT,
    CountrySettingsDictStateT,
} from './types';
import keyBy from 'lodash/keyBy';
import requestStatus from 'common/utils/request-status';

const initialState: CountrySettingsDictStateT = {
    byCountryCode: {},
    request: requestStatus.initial,
};

export default (state = initialState, action: CountrySettingsDictActionT): CountrySettingsDictStateT => {
    switch (action.type) {
        case COUNTRY_SETTINGS_DICT_REQUEST_BEGIN: {
            return {
                ...state,
                request: requestStatus.loading,
            };
        }

        case COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS: {
            const { list } = action;

            const byCountryCode = keyBy(list, 'country');

            return {
                ...state,
                byCountryCode,
                request: requestStatus.ok,
            };
        }

        case COUNTRY_SETTINGS_DICT_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                request: requestStatus.setError(error),
            };
        }

        default: {
            return state;
        }
    }
};
