import React from 'react';
import classNames from 'classnames/bind';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';

import styles from './FilterPills.scss';
import { useTranslation } from 'react-i18next';
import FilterPill, { FilterPillThemeEnum } from './FilterPill/FilterPill';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import times from 'lodash/times';

const cx = classNames.bind(styles);

export type FilterPillsConfigT<QueryFiltersT> = Array<
    {
        clearKeys: Array<keyof QueryFiltersT>;
        theme?: FilterPillThemeEnum;
        isMultiple?: boolean;
    } & (
        | {
              isMultiple?: false;
              render: (queryFilters: QueryFiltersT) => React.ReactNode;
              renderIcon?: (queryFilters: QueryFiltersT, isCompact?: boolean) => React.ReactNode;
              getTheme?: (queryFilters: QueryFiltersT) => FilterPillThemeEnum;
          }
        | {
              isMultiple: true;
              render: (queryFilters: QueryFiltersT, index: number) => React.ReactNode;
              renderIcon?: (queryFilters: QueryFiltersT, index: number, isCompact?: boolean) => React.ReactNode;
              getTheme?: (queryFilters: QueryFiltersT, index: number) => FilterPillThemeEnum;
          }
    )
>;

type PropsT<QueryFiltersT> = {
    config: FilterPillsConfigT<QueryFiltersT>;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: Partial<QueryFiltersT>) => void;
    isCompact?: boolean;
    isHideClearAll?: boolean;
    testSelector?: string;
};

type PillPropsT = {
    testSelector: string;
    label: React.ReactNode;
    icon: React.ReactNode;
    clearKeys: Array<string>;
    theme: FilterPillThemeEnum | null | undefined;
} & (
    | {
          isMultiple: true;
          clearIndex: number;
      }
    | {
          isMultiple?: false;
      }
);

const FilterPills = <QueryFiltersT extends {}>(props: PropsT<QueryFiltersT>): React.ReactElement => {
    const { queryFilters, setQueryFilters, config, isHideClearAll, isCompact, testSelector } = props;
    const { t } = useTranslation();

    const clearFilters = (clearKeys: Array<string>) => {
        const newQueryFilters = omit(queryFilters, clearKeys);

        setQueryFilters(newQueryFilters);
    };

    const clearMultipleFilters = (clearKeys: Array<string>, clearIndex: number) => {
        const newQueryFilters = clearKeys.reduce<typeof queryFilters>((acc, clearKey) => {
            // @ts-expect-error No index signature with a parameter of type 'string' was found on type '{}'.
            const value = acc[clearKey] as Array<string> | string | undefined;
            if (!value) {
                return acc;
            }

            if (isArray(value)) {
                value.splice(clearIndex, 1);
            } else {
                // @ts-expect-error No index signature with a parameter of type 'string' was found on type '{}'.
                delete acc[clearKey];
            }

            return acc;
        }, cloneDeep(queryFilters));

        setQueryFilters(newQueryFilters);
    };

    const pillProps = config.reduce<Array<PillPropsT>>((acc, configItem) => {
        const hasValues = configItem.clearKeys.some((key) => queryFilters[key] !== undefined);
        if (!hasValues) {
            return acc;
        }

        if (configItem.isMultiple) {
            const { renderIcon, render, theme, getTheme, clearKeys } = configItem;

            // @ts-expect-error Property 'length' does not exist on type 'NonNullable<QueryFiltersT[keyof QueryFiltersT]>'.
            const count = queryFilters[clearKeys?.[0]]?.length || 0;
            times(count).forEach((_, index) => {
                acc.push({
                    testSelector: `${testSelector}_${clearKeys.join('-')}_${index}`,
                    label: render(queryFilters, index),
                    icon: renderIcon ? renderIcon(queryFilters, index, isCompact) : null,
                    theme: getTheme?.(queryFilters, index) || theme,
                    clearKeys: clearKeys as Array<string>,
                    isMultiple: true,
                    clearIndex: index,
                });
            });
        } else {
            const { renderIcon, render, getTheme, theme, clearKeys } = configItem;

            acc.push({
                testSelector: `${testSelector}_${clearKeys.join('-')}`,
                label: render(queryFilters),
                icon: renderIcon ? renderIcon(queryFilters, isCompact) : null,
                theme: getTheme?.(queryFilters) || theme,
                clearKeys: clearKeys as Array<string>,
            });
        }

        return acc;
    }, []);

    const allKeys = flatten(config.map((configItem) => configItem.clearKeys)) as string[];

    const clearAllFilters = () => clearFilters(allKeys);

    return (
        <>
            {!!pillProps.length && !isHideClearAll && (
                <FilterPill
                    className={cx('pill')}
                    label={t('common:clear-all-filters')}
                    withCloser={false}
                    testSelector={`${testSelector}_clear-all`}
                    theme={FilterPillThemeEnum.charcoal}
                    onClick={clearAllFilters}
                    isCompact={isCompact}
                />
            )}
            {pillProps.map((pillProps, pillIndex) => (
                <FilterPill
                    key={pillIndex}
                    className={cx('pill')}
                    icon={pillProps?.icon}
                    label={pillProps?.label}
                    withCloser
                    testSelector={pillProps?.testSelector}
                    theme={pillProps?.theme || FilterPillThemeEnum.brandDark}
                    onClick={() => {
                        if (pillProps.isMultiple) {
                            clearMultipleFilters(pillProps.clearKeys, pillProps.clearIndex);
                        } else {
                            clearFilters(pillProps.clearKeys);
                        }
                    }}
                    isCompact={isCompact}
                />
            ))}
        </>
    );
};

export default React.memo(FilterPills) as typeof FilterPills;
