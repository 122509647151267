import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './StatusCell.scss';
import { ApiTrailerT } from 'common/store/trailers/models';
import TrailerStatusPill from 'common/components/status-pill/TrailerStatusPill/TrailerStatusPill';

type PropsT = {
    trailer: ApiTrailerT;
    className?: string;
};

const cx = classNames.bind(styles);

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { trailer, className } = props;
    return (
        <div className={cx('status')}>
            <TrailerStatusPill isSymmetrical className={className} status={trailer?.status} />
        </div>
    );
});

export default StatusCell;
