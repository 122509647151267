import { put, select } from 'redux-saga/effects';
import {
    fetchCountrySettingsDictBegin,
    fetchCountrySettingsDictError,
    fetchCountrySettingsDictSuccess,
} from './actions';
import { COUNTRY_SETTINGS_DICT_REQUEST } from './types';
import { selectCountrySettingsDictRequest } from './selectors';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { semaphoreSaga } from 'common/utils/semaphore-saga';

function* fetchCountrySettingsDictSaga(): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectCountrySettingsDictRequest> = yield select(
        selectCountrySettingsDictRequest,
    );
    if (requestStatus.ok || requestStatus.loading) {
        return;
    }

    yield put(fetchCountrySettingsDictBegin());

    const [error, list]: ReturnApiT<typeof commonTranziitApi.getCountrySettingsDict> =
        yield commonTranziitApi.getCountrySettingsDict();

    if (error) {
        yield put(fetchCountrySettingsDictError(error));
    }

    if (list) {
        yield put(fetchCountrySettingsDictSuccess(list));
    }
}

export function* countrySettingsDictSaga(): WrapGeneratorT<void> {
    yield semaphoreSaga(COUNTRY_SETTINGS_DICT_REQUEST, fetchCountrySettingsDictSaga);
}
