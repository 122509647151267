import React, { useMemo } from 'react';

export type SyncSetUnavailableFormMapStateContextValueT = {
    hasDropAssetPointError: boolean;
    setHasDropAssetPointError: (hasDropAssetPointError: boolean) => void;
};

export const usySyncMapState = (): SyncSetUnavailableFormMapStateContextValueT => {
    const [hasDropAssetPointError, setHasDropAssetPointError] = React.useState<boolean>(false);

    return useMemo(() => {
        return {
            hasDropAssetPointError,
            setHasDropAssetPointError,
        };
    }, [hasDropAssetPointError]);
};
