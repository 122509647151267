import React from 'react';
import classNames from 'classnames/bind';

import styles from './TruckDetailsForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import TruckModelDropdown from 'common/components/dropdowns/TruckModelDropdown/TruckModelDropdown';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import { uppercaseFormatter } from 'common/utils/form-formatters';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Input from 'common/components/Input/Input';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { AssetTypeEnum, CurrencyEnum, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import ActiveContractDropdown from 'common/components/dropdowns/ActiveContractDropdown/ActiveContractDropdown';
import { ApiTruckDetailsT } from 'common/store/trucks/models';
import FieldValue from 'common/components/FieldValue/FieldValue';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import GPSTrackingStatusAlert from 'common/components/GPSTrackingStatusAlert/GPSTrackingStatusAlert';
import { selectGPSIsChecking, selectGPSStatus } from 'common/store/gps-tracking-status/selectors';
import { GPStatusEnum } from 'common/store/gps-tracking-status/constants';
import { selectCarrierContractById } from 'common/store/carrier-contracts/selectors';
import { fetchCarrierContracts } from 'common/store/carrier-contracts/actions';
import { usePlateNumberUniqCheck } from 'common/components/AssetSidebarContent/hooks/usePlateNumberUniqCheck';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import ChangeRateWarning from '../ChangeRateWarning/ChangeRateWarning';
import useOnceShow from 'common/utils/hooks/useOnceShow';
import { selectTrucksDictById } from 'common/store/trucks-dict/selectors';
import values from 'lodash/values';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import LinkedTrailerAlert from 'common/layouts/CommonEditableTruckDetailsLayout/LinkedTrailerAlert/LinkedTrailerAlert';
import Money from 'common/components/Money/Money';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { DEPRECATED_EMISSION_CLASS_I18N_MAP } from 'common/components/EmissionClassLabel/EmissionClassLabel';
import EmissionIcon from 'common/icons/EmissionIcon';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { getTruckDictTypeLabel } from 'common/store/trucks-dict/utils';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import InfoIcon from 'common/icons/InfoIcon';
import { AlertGroup } from 'common/layouts/AlertGroup/AlertGroup';
import LastGPSPositionAlert from 'common/components/LastGPSPositionAlert/LastGPSPositionAlert';
import { checkIsApiCarrierTruckDetails } from 'common/store/trucks/type-guards';
import TruckActionHistory from 'common/layouts/CommonEditableTruckDetailsLayout/TruckActionHistory/TruckActionHistory';
import ChangeContractWarning from 'common/layouts/CommonEditableTruckDetailsLayout/EditTruckSidebarContent/ChangeContractWarning/ChangeContractWarning';
import { CountryCodeT } from 'common/utils/api/models';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    truckDetails: ApiTruckDetailsT | null;
    onSubmit: (values: FormValuesT) => void;
    onCancel: () => void;
    isNewTruck: boolean;
    isLoading: boolean;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;
    onOpenUserDetails: (userId: UserIdT, isBrokerUser?: boolean) => void;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
};

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.truckModel]: null,
    [FieldsEnum.cabinColor]: '',
    [FieldsEnum.countryCode]: null,
    [FieldsEnum.plateNumber]: '',
    [FieldsEnum.vin]: '',
    [FieldsEnum.contract]: null,
    [FieldsEnum.ratePerKm]: '',
};

const TruckDetailsForm: React.FC<PropsT> = (props) => {
    const {
        partnerId,
        onSubmit,
        truckDetails,
        isNewTruck,
        isLoading,
        onCancel,
        setNeedCloseConfirmation,
        onOpenTrailerDetails,
        onOpenTruckDetails,
        onOpenUserDetails,
    } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrucksDict());
    }, []);

    React.useEffect(() => {
        return () => {
            if (setNeedCloseConfirmation) {
                setNeedCloseConfirmation(false);
            }
        };
    }, []);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, { isCreation: isNewTruck });
    }, [t, isNewTruck]);

    React.useEffect(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);

    const countryByCode = useSelector(selectCountriesByCode);

    const contractById = useSelector(selectCarrierContractById(partnerId));

    const [initialValues, initialErrors] = React.useMemo(() => {
        let values = {
            ...INITIAL_VALUES,
        };

        if (truckDetails) {
            values = {
                ...values,
                [FieldsEnum.truckModel]: truckDetails.dictTruckInfo?.id || null,
                [FieldsEnum.cabinColor]: truckDetails.cabinColor || '',
                [FieldsEnum.countryCode]: truckDetails.countryCode || null,
                [FieldsEnum.plateNumber]: truckDetails.plateNumber || '',
                [FieldsEnum.vin]: truckDetails.vin || '',
                [FieldsEnum.contract]: truckDetails.contractId || null,
                [FieldsEnum.ratePerKm]: String(truckDetails.ratePerKm),
            };
        }

        const errors = validateForm(t, values, { isCreation: isNewTruck });

        return [values, errors];
    }, [t, truckDetails, isNewTruck]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            onSubmit(values);

            formikHelpers.setTouched({});
        },
    });

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (hasAnyFieldValueChanges && setNeedCloseConfirmation) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [setNeedCloseConfirmation, hasAnyFieldValueChanges]);

    const isCheckingGPSTracking = useSelector(selectGPSIsChecking);
    const gpsStatus = useSelector(selectGPSStatus);

    const isAllowContract = gpsStatus === GPStatusEnum.enabled || !gpsStatus;
    const contractValue = formik.values[FieldsEnum.contract];

    React.useEffect(() => {
        if (!isAllowContract && contractValue !== null) {
            formik.setFieldValue(FieldsEnum.contract, null);
        }
    }, [isAllowContract, contractValue]);

    const plateNumber = formik.values[FieldsEnum.plateNumber];
    const truckPlateNumberUniqCheck = usePlateNumberUniqCheck({
        plateNumber,
        companyId: partnerId,
        assetType: AssetTypeEnum.truck,
    });

    let plateNumberAsyncError: React.ReactNode = null;

    const duplicateTruckId = truckPlateNumberUniqCheck.duplicateId;
    if (duplicateTruckId) {
        plateNumberAsyncError = (
            <Link
                onClick={() => {
                    onOpenTruckDetails(duplicateTruckId);
                }}
                theme={LinkThemeEnum.boldTomatoRed}
            >
                {t('common:trucks-page.add-truck.errors.not-uniq-plate-number', { plateNumber })}
            </Link>
        );
    }

    const hasAsyncErrors = !!plateNumberAsyncError;

    const isDisabledSubmit = isLoading || isCheckingGPSTracking;

    const selectedContractId = formik.values[FieldsEnum.contract];
    const contract = selectedContractId ? contractById[selectedContractId] : null;

    const isAllowShowChanges = !isNewTruck;

    const isAllowChangeRate = formik.values[FieldsEnum.contract] === null;

    const isAvailableShowRateWarning = isAllowChangeRate;
    const isShowRateWarning =
        !isNewTruck && initialValues[FieldsEnum.ratePerKm] !== formik.values[FieldsEnum.ratePerKm];

    const rateWarning = useOnceShow(isShowRateWarning);

    const isAvailableShowContractWarning = !!formik.values[FieldsEnum.contract];
    const isShowContractWarning =
        !isNewTruck && initialValues[FieldsEnum.contract] !== formik.values[FieldsEnum.contract];

    const contractWarning = useOnceShow(isShowContractWarning);

    const trucksDictById = useSelector(selectTrucksDictById);
    const selectedTruckModel = formik.values[FieldsEnum.truckModel];
    const truckModel = trucksDictById[selectedTruckModel as string];

    const isAvailableSelectContract = isAllowContract || isCheckingGPSTracking;

    const contractTooltipNode = (
        <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
            {t('common:trucks-page.add-truck.fields.contract.tooltip')}
        </TooltipContent>
    );

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <AlertGroup className={cx('alerts')}>
                {truckDetails?.linkedTrailerId && (
                    <LinkedTrailerAlert
                        trailerId={truckDetails?.linkedTrailerId}
                        plateNumber={truckDetails?.linkedTrailerPlateNumber}
                        onOpenTrailerDetails={onOpenTrailerDetails}
                    />
                )}
                {duplicateTruckId && (
                    <Alert
                        icon={<InfoIcon fillColor={StyleGuideColorsEnum.white} />}
                        size={AlertSizeEnum.small}
                        theme={AlertThemeEnum.tomatoRed}
                    >
                        {t('common:trucks-page.add-truck.alerts.already-exist')}
                    </Alert>
                )}
                {isNewTruck ? (
                    <GPSTrackingStatusAlert
                        companyId={partnerId}
                        assetType={AssetTypeEnum.truck}
                        plateNumber={formik.values[FieldsEnum.plateNumber]}
                    />
                ) : (
                    <LastGPSPositionAlert
                        companyId={partnerId}
                        assetType={AssetTypeEnum.truck}
                        plateNumber={truckDetails?.plateNumber || ''}
                    />
                )}
            </AlertGroup>
            <div className={cx('fields')}>
                <FieldGroup>
                    {isNewTruck ? (
                        <FormikField
                            className={cx('field', 'field--plate-number')}
                            name={FieldsEnum.plateNumber}
                            error={formik.errors[FieldsEnum.plateNumber]}
                            meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                            asyncError={plateNumberAsyncError}
                            label={t('common:trucks-page.add-truck.fields.plate-number.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.plateNumber}
                                    value={formik.values[FieldsEnum.plateNumber]}
                                    placeholder={t('common:trucks-page.add-truck.fields.plate-number.placeholder')}
                                    formatter={uppercaseFormatter}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    isLoading={truckPlateNumberUniqCheck.requestStatus.loading}
                                    hasChanges={isAllowShowChanges && props.hasChanges}
                                />
                            )}
                        </FormikField>
                    ) : (
                        <FieldValue
                            className={cx('field', 'field--plate-number')}
                            label={t('common:trucks-page.add-truck.fields.plate-number.label')}
                            value={truckDetails?.plateNumber || ''}
                        />
                    )}
                    <FieldGroupEmptyItem className={cx('field', 'field--placeholder')} />
                </FieldGroup>
                <FieldGroup>
                    {isNewTruck ? (
                        <FormikField
                            className={cx('field', 'field--country-code')}
                            name={FieldsEnum.countryCode}
                            error={formik.errors[FieldsEnum.countryCode]}
                            label={t('common:trucks-page.add-truck.fields.country.label')}
                            meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <CountryDropdown
                                    value={formik.values[FieldsEnum.countryCode]}
                                    onChange={props.onChange}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={isAllowShowChanges && props.hasChanges}
                                />
                            )}
                        </FormikField>
                    ) : (
                        <FieldValue
                            className={cx('field', 'field--country-code')}
                            icon={<FlagIcon countryCode={truckDetails?.countryCode} />}
                            label={t('common:trucks-page.add-truck.fields.country.label')}
                            value={countryByCode?.[truckDetails?.countryCode as CountryCodeT]?.userLangDisplayName}
                        />
                    )}
                    {isNewTruck ? (
                        <FormikField
                            className={cx('field', 'field--vin')}
                            name={FieldsEnum.vin}
                            error={formik.errors[FieldsEnum.vin]}
                            meta={formik.getFieldMeta(FieldsEnum.vin)}
                            label={t('common:trucks-page.add-truck.fields.truck-vin.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.vin}
                                    value={formik.values[FieldsEnum.vin]}
                                    placeholder={t('common:trucks-page.add-truck.fields.truck-vin.placeholder')}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={isAllowShowChanges && props.hasChanges}
                                />
                            )}
                        </FormikField>
                    ) : (
                        <FieldValue
                            className={cx('field', 'field--vin')}
                            label={t('common:trucks-page.add-truck.fields.truck-vin.label')}
                            value={truckDetails?.vin}
                        />
                    )}
                </FieldGroup>
                {isNewTruck ? (
                    <FormikField
                        name={FieldsEnum.truckModel}
                        error={formik.errors[FieldsEnum.truckModel]}
                        meta={formik.getFieldMeta(FieldsEnum.truckModel)}
                        label={t('common:trucks-page.add-truck.fields.truck-model.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <TruckModelDropdown
                                placeholder={t('common:trucks-page.add-truck.fields.truck-model.placeholder')}
                                value={formik.values[FieldsEnum.truckModel]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasChanges={isAllowShowChanges && props.hasChanges}
                            />
                        )}
                    </FormikField>
                ) : (
                    <FieldValue
                        label={t('common:trucks-page.add-truck.fields.truck-model.label')}
                        value={getTruckDictTypeLabel(truckModel)}
                    />
                )}
                <FieldGroup>
                    <FieldValue
                        withInputLayout={isNewTruck}
                        className={cx('field', 'field--emission-standard')}
                        icon={<EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />}
                        label={t('common:trucks-page.add-truck.fields.emission-standard.label')}
                        placeholder={t('common:trucks-page.add-truck.fields.emission-standard.placeholder')}
                        value={
                            truckModel?.emissionClass
                                ? t(DEPRECATED_EMISSION_CLASS_I18N_MAP[truckModel.emissionClass])
                                : null
                        }
                    />
                    <FormikField
                        className={cx('field--cabin-color')}
                        name={FieldsEnum.cabinColor}
                        error={formik.errors[FieldsEnum.cabinColor]}
                        meta={formik.getFieldMeta(FieldsEnum.cabinColor)}
                        label={t('common:trucks-page.add-truck.fields.cabin-color.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.cabinColor}
                                value={formik.values[FieldsEnum.cabinColor]}
                                placeholder={t('common:trucks-page.add-truck.fields.cabin-color.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasChanges={isAllowShowChanges && props.hasChanges}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    {isAvailableSelectContract ? (
                        <FormikField
                            className={cx('field--contract')}
                            name={FieldsEnum.contract}
                            error={formik.errors[FieldsEnum.contract]}
                            meta={formik.getFieldMeta(FieldsEnum.contract)}
                            label={t('common:trucks-page.add-truck.fields.contract.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            tooltipPosition={TooltipPositionEnum.centerRight}
                            tooltipNode={contractTooltipNode}
                        >
                            {(props) => (
                                <ActiveContractDropdown
                                    hasNoneOption
                                    value={formik.values[FieldsEnum.contract]}
                                    isDisabled={false}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    companyId={partnerId}
                                    hasChanges={isAllowShowChanges && props.hasChanges}
                                />
                            )}
                        </FormikField>
                    ) : (
                        <FieldValue
                            withInputLayout
                            className={cx('field--contract')}
                            label={t('common:trucks-page.add-truck.fields.contract.label')}
                            value={t('common:dedicated.none')}
                            tooltipPosition={TooltipPositionEnum.centerRight}
                            tooltipNode={contractTooltipNode}
                        />
                    )}
                    {!isAllowChangeRate && (
                        <FieldValue
                            withInputLayout
                            className={cx('field--rate')}
                            label={t('common:trucks-page.add-truck.fields.rate.label')}
                            value={<Money amount={contract?.truckRateKm} currency={CurrencyEnum.EUR} />}
                        />
                    )}
                    {isAllowChangeRate && (
                        <FormikField
                            className={cx('field--rate')}
                            name={FieldsEnum.ratePerKm}
                            error={formik.errors[FieldsEnum.ratePerKm]}
                            meta={formik.getFieldMeta(FieldsEnum.ratePerKm)}
                            label={t('common:trucks-page.add-truck.fields.rate.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <NumberInput
                                    name={FieldsEnum.ratePerKm}
                                    unitType={UnitTypeEnum.euroAbbreviation}
                                    placeholder={t('common:trucks-page.add-truck.fields.rate.placeholder')}
                                    value={formik.values[FieldsEnum.ratePerKm]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasChanges={isAllowShowChanges && props.hasChanges}
                                    step={1}
                                />
                            )}
                        </FormikField>
                    )}
                </FieldGroup>
            </div>
            <TruckActionHistory
                onOpenUserDetails={onOpenUserDetails}
                createdDate={truckDetails?.createdDate}
                createdByBroker={checkIsApiCarrierTruckDetails(truckDetails) && truckDetails?.addedByBroker}
                createdByName={truckDetails?.createdBy?.fullName}
                createdById={truckDetails?.createdBy?.id}
                modifiedDate={truckDetails?.lastModifiedDate}
                modifiedByBroker={checkIsApiCarrierTruckDetails(truckDetails) && truckDetails?.lastModifiedByBroker}
                modifiedByName={truckDetails?.lastModifiedBy?.fullName}
                modifiedById={truckDetails?.lastModifiedBy?.id}
            />
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <FooterSideBarLayout
                hasPaddings
                topNode={
                    <>
                        {isAvailableShowRateWarning && rateWarning.isShow ? (
                            <ChangeRateWarning className={cx('footer-warning')} onClose={rateWarning.close} />
                        ) : null}
                        {isAvailableShowContractWarning && contractWarning.isShow ? (
                            <ChangeContractWarning className={cx('footer-warning')} onClose={contractWarning.close} />
                        ) : null}
                    </>
                }
            >
                {isNewTruck && (
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isLoading={isDisabledSubmit}
                        className={cx('button')}
                        isDisabled={hasAsyncErrors}
                        type="submit"
                    >
                        {t('common:trucks-page.add-truck.submit')}
                    </Button>
                )}
                {!isNewTruck && (
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('actions__action', 'actions__action--cancel')}
                            onClick={() => {
                                formik.resetForm();
                                onCancel();
                            }}
                        >
                            {t('common:trucks-page.actions.discard-changes')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            isLoading={isDisabledSubmit}
                            isDisabled={hasAsyncErrors}
                            className={cx('actions__action', 'actions__action--delete')}
                            type="submit"
                        >
                            {t('common:trucks-page.actions.save')}
                        </Button>
                    </div>
                )}
            </FooterSideBarLayout>
        </form>
    );
};

export default TruckDetailsForm;
