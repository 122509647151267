import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty, checkIsPositiveNumberValue, checkMaxValue } from 'common/utils/form-validators';

const CREATION_REQUIRED_FIELDS = [] as const;

const REQUIRED_FIELDS = [
    FieldsEnum.trailerType,
    FieldsEnum.trailerDictId,
    FieldsEnum.countryCode,
    FieldsEnum.plateNumber,
    FieldsEnum.vin,
] as const;

const RATE_FIELDS = [FieldsEnum.ratePerKm] as const;

const MAX_RATE = 10;

type MetaT = {
    isCreation: boolean;
};

const validate = (t: TFunction, values: FormValuesT, meta: MetaT): FormErrorsT => {
    let errors: FormErrorsT = {
        ...checkNotEmpty(t, CREATION_REQUIRED_FIELDS, values),
    };

    if (meta?.isCreation) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        };
    }

    if (values[FieldsEnum.contract] === null) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, RATE_FIELDS, values, t('common:trailers-page.add-trailer.errors.required-rate')),
            ...checkIsPositiveNumberValue(t, RATE_FIELDS, values),
            ...checkMaxValue(t, RATE_FIELDS, values, MAX_RATE),
        };
    }

    return errors;
};

export default validate;
