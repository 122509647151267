import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork, ForkEffect } from 'redux-saga/effects';

import authSaga from 'common/store/auth/sagas';
import userSaga from 'common/store/user/sagas';
import companySaga from 'common/store/company/sagas';
import trailersDictSaga from 'common/store/trailers-dict/sagas';
import trucksDictSaga from 'common/store/trucks-dict/sagas';
import errorsSaga from 'common/store/errors/sagas';
import trailersSaga from 'common/store/trailers/sagas';
import trucksSaga from 'common/store/trucks/sagas';
import countriesDictSaga from 'common/store/countries-dict/sagas';
import membersSaga from 'common/store/members/sagas';
import documentsDictSaga from 'common/store/documents-dict/sagas';
import documentsSaga from 'common/store/documents/sagas';
import vehicleSchedulesSaga from 'common/store/vehicle-schedules/sagas';
import legalFormsDictSaga from 'common/store/legal-forms-dict/sagas';
import transportOrdersSaga from 'common/store/transport-orders/sagas';
import transportOrdersStatsSaga from 'common/store/transport-orders-stats/sagas';
import transportOrderDetailsSaga from 'common/store/transport-order-details/sagas';
import driversSaga from 'common/store/drivers/sagas';
import driverAssignmentSaga from 'common/store/driver-assigment/sagas';
import notificationsSaga from 'common/store/notifications/sagas';
import notificationsAffectsSaga from './notifications/sagas';
import assetTrackSaga from 'common/store/asset-track/sagas';
import carrierContractsSaga from 'common/store/carrier-contracts/sagas';
import groupedNotificationCountsSaga from 'common/store/grouped-notification-counts/sagas';
import gpsTrackingStatusSaga from 'common/store/gps-tracking-status/sagas';
import alertsSaga from 'common/store/alerts/sagas';
import settingsSaga from 'common/store/settings/sagas';
import driverSearchSaga from 'common/store/driver-search/sagas';
import papersSaga from 'common/store/papers/sagas';
import spotInboxRequestsSaga from './spot-inbox-requests/sagas';
import spotLoadBoardRequestsSaga from './spot-load-board-requests/sagas';
import spotInboxRequestsStatsSaga from './spot-inbox-requests-stats/sagas';
import spotRequestDetailsSaga from './spot-request-details/sagas';
import bidsSaga from './bids/sagas';
import spotAssetsAssigmentSaga from 'common/store/spot-assets-assigment/sagas';
import spotCarrierLanesSaga from './spot-carrier-lanes/sagas';
import spotCarrierLaneChangesSaga from './spot-carrier-lane-changes/sagas';
import spotCarrierProfileSaga from './spot-carrier-profile/sagas';
import { countrySettingsDictSaga } from 'common/store/country-settings-dict/sagas';
import { createCompanySaga } from 'common/store/create-company/sagas';
import additionalServicesDictSaga from 'common/store/additional-services-dict/sagas';

import authReducer from 'common/store/auth/reducer';
import userReducer from 'common/store/user/reducer';
import companyReducer from 'common/store/company/reducer';
import errorsReducer from 'common/store/errors/reducer';
import trailersDictReducer from 'common/store/trailers-dict/reducer';
import trucksDictReducer from 'common/store/trucks-dict/reducer';
import trailersReducer from 'common/store/trailers/reducer';
import trucksReducer from 'common/store/trucks/reducer';
import membersReducer from 'common/store/members/reducer';
import countriesDictReducer from 'common/store/countries-dict/reducer';
import documentsDictReducer from 'common/store/documents-dict/reducer';
import documentsReducer from 'common/store/documents/reducer';
import vehicleSchedulesReducer from 'common/store/vehicle-schedules/reducer';
import legalFormsDictReducer from 'common/store/legal-forms-dict/reducer';
import transportOrdersReducer from 'common/store/transport-orders/reducer';
import transportOrdersStatsReducer from 'common/store/transport-orders-stats/reducer';
import transportOrderDetailsReducer from 'common/store/transport-order-details/reducer';
import driversReducer from 'common/store/drivers/reducer';
import driverAssignmentReducer from 'common/store/driver-assigment/reducer';
import notificationsReducer from 'common/store/notifications/reducer';
import assetTrackReducer from 'common/store/asset-track/reducer';
import carrierContractsReducer from 'common/store/carrier-contracts/reducer';
import groupedNotificationCountsReducer from 'common/store/grouped-notification-counts/reducer';
import gpsTrackingStatusReducer from 'common/store/gps-tracking-status/reducer';
import alertsReducer from 'common/store/alerts/reducer';
import settingsReducer from 'common/store/settings/reducer';
import driverSearchReducer from 'common/store/driver-search/reducer';
import papersReducer from 'common/store/papers/reducer';
import spotInboxRequestsReducer from './spot-inbox-requests/reducer';
import spotInboxRequestsStatsReducer from './spot-inbox-requests-stats/reducer';
import spotLoadBoardRequestsReducer from './spot-load-board-requests/reducer';
import spotRequestDetailsReducer from './spot-request-details/reducer';
import spotCarrierProfileReducer from './spot-carrier-profile/reducer';
import bidsReducer from './bids/reducer';
import spotAssetsAssigmentReducer from 'common/store/spot-assets-assigment/reducer';
import spotCarrierLanesReducer from './spot-carrier-lanes/reducer';
import spotCarrierLaneChangesReducer from './spot-carrier-lane-changes/reducer';
import createCompanyReducer from 'common/store/create-company/slice';
import routingGeometryReducer from 'common/store/routing-geometry/slice';
import additionalServicesDictReducer from 'common/store/additional-services-dict/reducer';

import { CompanyTypeEnum } from 'common/constants';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import carrierContractDetailsSaga from 'common/store/carrier-contract-details/sagas';
import carrierContractDetailsReducer from 'common/store/carrier-contract-details/reducer';
import { routingGeometrySaga } from 'common/store/routing-geometry/sagas';
import stateMachineReducer from 'common/store/state-machine/slice';
import { stateMachineSaga } from 'common/store/state-machine/sagas';
import countrySettingsDictReducer from 'common/store/country-settings-dict/reducer';

const rootReducer = combineReducers({
    auth: authReducer(AuthProfileTypeEnum.carrier),
    user: userReducer,
    company: companyReducer,
    errors: errorsReducer,
    countriesDict: countriesDictReducer,
    countrySettingsDict: countrySettingsDictReducer,
    trailersDict: trailersDictReducer,
    trucksDict: trucksDictReducer,
    trailers: trailersReducer,
    trucks: trucksReducer,
    members: membersReducer,
    additionalServicesDict: additionalServicesDictReducer,
    legalFormsDict: legalFormsDictReducer,
    documentsDict: documentsDictReducer,
    documents: documentsReducer,
    vehicleSchedules: vehicleSchedulesReducer,
    transportOrders: transportOrdersReducer,
    transportOrdersStats: transportOrdersStatsReducer,
    transportOrderDetails: transportOrderDetailsReducer,
    drivers: driversReducer,
    driverAssignment: driverAssignmentReducer,
    notifications: notificationsReducer,
    assetTrack: assetTrackReducer,
    carrierContracts: carrierContractsReducer,
    carrierContractDetails: carrierContractDetailsReducer,
    groupedNotificationCounts: groupedNotificationCountsReducer,
    gpsTrackingStatus: gpsTrackingStatusReducer,
    alerts: alertsReducer,
    settings: settingsReducer,
    driverSearch: driverSearchReducer,
    papers: papersReducer,
    spotInboxRequests: spotInboxRequestsReducer,
    spotLoadBoardRequests: spotLoadBoardRequestsReducer,
    spotInboxRequestsStats: spotInboxRequestsStatsReducer,
    spotRequestDetails: spotRequestDetailsReducer,
    spotCarrierProfile: spotCarrierProfileReducer,
    bids: bidsReducer,
    spotAssetsAssigment: spotAssetsAssigmentReducer,
    spotCarrierLanes: spotCarrierLanesReducer,
    spotCarrierLaneChanges: spotCarrierLaneChangesReducer,
    createCompany: createCompanyReducer,
    routingGeometry: routingGeometryReducer,
    stateMachine: stateMachineReducer,
});

export type RootStateT = ReturnType<typeof rootReducer>;

function* rootSaga(): Generator<ForkEffect<TODO>, void, TODO> {
    yield fork(authSaga, CompanyTypeEnum.carrier);
    yield fork(userSaga);
    yield fork(companySaga);
    yield fork(countriesDictSaga);
    yield fork(countrySettingsDictSaga);
    yield fork(trailersDictSaga);
    yield fork(trucksDictSaga);
    yield fork(trailersSaga);
    yield fork(trucksSaga);
    yield fork(errorsSaga);
    yield fork(membersSaga);
    yield fork(legalFormsDictSaga);
    yield fork(documentsDictSaga);
    yield fork(documentsSaga);
    yield fork(vehicleSchedulesSaga, CompanyTypeEnum.carrier);
    yield fork(transportOrdersSaga);
    yield fork(transportOrdersStatsSaga);
    yield fork(transportOrderDetailsSaga, CompanyTypeEnum.carrier);
    yield fork(driversSaga);
    yield fork(driverAssignmentSaga);
    yield fork(notificationsSaga);
    yield fork(notificationsAffectsSaga);
    yield fork(assetTrackSaga, CompanyTypeEnum.carrier);
    yield fork(carrierContractsSaga);
    yield fork(carrierContractDetailsSaga);
    yield fork(groupedNotificationCountsSaga);
    yield fork(gpsTrackingStatusSaga);
    yield fork(alertsSaga);
    yield fork(settingsSaga);
    yield fork(driverSearchSaga);
    yield fork(papersSaga);
    yield fork(spotInboxRequestsSaga);
    yield fork(spotLoadBoardRequestsSaga);
    yield fork(spotInboxRequestsStatsSaga);
    yield fork(spotRequestDetailsSaga);
    yield fork(bidsSaga);
    yield fork(spotAssetsAssigmentSaga);
    yield fork(spotCarrierLanesSaga);
    yield fork(spotCarrierLaneChangesSaga);
    yield fork(spotCarrierProfileSaga);
    yield fork(createCompanySaga, CompanyTypeEnum.carrier);
    yield fork(additionalServicesDictSaga);
    yield fork(routingGeometrySaga, CompanyTypeEnum.carrier);
    yield fork(stateMachineSaga, CompanyTypeEnum.carrier);
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

// for dev
window.store = store;

export default store;
