import React from 'react';

import classNames from 'classnames/bind';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { ApiTruckDictTypeT } from 'common/utils/api/models';

import styles from './TruckModelDropdown.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrucksDict, selectTrucksDictRequest } from 'common/store/trucks-dict/selectors';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import { getTruckDictTypeLabel } from 'common/store/trucks-dict/utils';
import TruckIcon, { TruckIconProps } from 'common/icons/TruckIcon';

export enum TruckIconTypesEnum {
    default = 'default',
}

const cx = classNames.bind(styles);

type ValueT = ApiTruckDictTypeT | null | undefined;

type PropsT = {
    placeholder?: string;
    value: ValueT;
    isDisabled?: boolean;
    onChange: (value: ValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
};

export type OptionT = {
    value: ValueT;
    label: string;
    iconType: TruckIconTypesEnum | null;
};

const TruckModelDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
    } = props;

    const trucksDict = useSelector(selectTrucksDict);
    const trucksDictList = Object.values(trucksDict || {});
    const fetchRequest = useSelector(selectTrucksDictRequest);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchTrucksDict());
    }, []);

    const trucksOptions = React.useMemo(
        () =>
            trucksDictList.map((truck) => {
                return {
                    value: truck.id,
                    iconType: null,
                    label: getTruckDictTypeLabel(truck),
                };
            }),
        [trucksDictList],
    );

    const renderOption = (option: OptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return (
            <div className={cx('option')} key={option.label}>
                {option.label}
            </div>
        );
    };

    const handleSelect = (value: ValueT): void => {
        onChange(value);
    };

    const handleReset = (): void => {
        onChange(null);
    };

    const getOptionValue = (option: OptionT): ValueT => option.value;

    return (
        <DropdownInput
            selectedValue={value}
            placeholder={placeholder}
            options={trucksOptions}
            onSelect={handleSelect}
            isDisabled={isDisabled || fetchRequest.loading}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            hasClearControl={hasClearControl}
            renderLeftIcon={(iconMeta) => <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />}
            renderRightIcon={() =>
                fetchRequest.loading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
            }
            onReset={handleReset}
        />
    );
});

export default TruckModelDropdown;
