import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './AlertGroup.scss';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const AlertGroup: React.FC<PropsT> = React.memo((props) => {
    return <div className={cs(cx('alerts'), props.className)}>{props.children}</div>;
});

export { AlertGroup };
