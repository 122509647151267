import { ApiCarrierTrailerDetailsT, ApiTrailerDetailsT } from 'common/store/trailers/models';

export const checkIsApiCarrierTrailerDetails = (
    details: ApiTrailerDetailsT | null,
): details is ApiCarrierTrailerDetailsT => {
    if (!details) {
        return false;
    }

    // TODO add condition
    return !!details;
};
