import { FieldsEnum, FormValuesT } from './constants';
import { formateEndDateForQuery, formateStartDateForQuery } from 'common/utils/query';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'common/layouts/TrucksPageLayout/query-models';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.model]: values[FieldsEnum.model] || undefined,
        [QueryFiltersKeysEnum.contractId]: values[FieldsEnum.contractId] || undefined,
        [QueryFiltersKeysEnum.contractName]: values[FieldsEnum.contractName] || undefined,
        [QueryFiltersKeysEnum.status]: values[FieldsEnum.status] || undefined,
        [QueryFiltersKeysEnum.emissionClass]: values[FieldsEnum.emissionClass] || undefined,
        [QueryFiltersKeysEnum.country]: values[FieldsEnum.country] || undefined,
        [QueryFiltersKeysEnum.createdByUserId]: values[FieldsEnum.createdByUserId] || undefined,
        [QueryFiltersKeysEnum.createdByUserName]: values[FieldsEnum.createdByUserName] || undefined,
        [QueryFiltersKeysEnum.lastModifyByUserId]: values[FieldsEnum.lastModifyByUserId] || undefined,
        [QueryFiltersKeysEnum.lastModifyByUserName]: values[FieldsEnum.lastModifyByUserName] || undefined,
    };

    if (values[FieldsEnum.plateNumber]) {
        queryFilters[QueryFiltersKeysEnum.plateNumber] = simpleStringFormatter(values[FieldsEnum.plateNumber]);
    }

    const createDates = values[FieldsEnum.createDates];
    if (createDates?.from) {
        queryFilters[QueryFiltersKeysEnum.createdDateFrom] = formateStartDateForQuery(createDates.from);
    }
    if (createDates?.to) {
        queryFilters[QueryFiltersKeysEnum.createdDateTo] = formateEndDateForQuery(createDates.to);
    }

    const lastModifyDates = values[FieldsEnum.lastModifyDates];
    if (lastModifyDates?.from) {
        queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom] = formateStartDateForQuery(lastModifyDates.from);
    }
    if (lastModifyDates?.to) {
        queryFilters[QueryFiltersKeysEnum.lastModifyDateTo] = formateEndDateForQuery(lastModifyDates.to);
    }

    const lastTelematicUpdateDates = values[FieldsEnum.lastTelematicUpdateDates];
    if (lastTelematicUpdateDates?.from) {
        queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateFrom] = formateStartDateForQuery(
            lastTelematicUpdateDates.from,
        );
    }
    if (lastTelematicUpdateDates?.to) {
        queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateTo] = formateEndDateForQuery(
            lastTelematicUpdateDates.to,
        );
    }

    return queryFilters;
};

export default getQueryFilters;
