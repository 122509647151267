import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from './TrailersPageLayout.scss';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import Pagination from 'common/components/Table/Pagination/Pagination';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { AssetTypeEnum, DEFAULT_CARRIER_COMPANY_ID, StyleGuideColorsEnum } from 'common/constants';
import { TrailersSortEnum } from 'common/utils/api/models';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAddTrailerRequestStatus,
    selectTrailersByIds,
    selectTrailersExportXLSRequest,
    selectTrailersImportXLSRequest,
    selectTrailersPages,
    selectTrailersStats,
    selectTrailersTotal,
    selectUpdateTrailersRequestStatus,
} from 'common/store/trailers/selectors';
import {
    activationTrailersRequest,
    archiveTrailersRequest,
    exportXLSTrailers,
    fetchTrailersPage,
    fetchTrailersStats,
    importXLSTrailers,
} from 'common/store/trailers/actions';
import { ApiTrailerT } from 'common/store/trailers/models';
import TrailersTable from './TrailersTable/TrailersTable';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import TableActions, { TableActionOptionT } from 'common/components/Table/TableActions/TableActions';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';

import DeleteIcon from 'common/icons/DeleteIcon';
import { isNonNil } from 'common/utils';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import TableError from 'common/components/Table/TableError/TableError';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import map from 'lodash/map';
import { QueryFiltersT, QueryKeysEnum } from './query-models';
import { TabEnum } from './constants';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import ArchiveAssetConfirmationModal, {
    ArchiveAssetConfirmationDataT,
} from 'common/layouts/dialogs/ArchiveAssetConfirmationModal/ArchiveAssetConfirmationModal';
import ActivateAssetDialogModal, {
    ActivateAssetConfirmationDataT,
} from 'common/layouts/dialogs/ActivateAssetDialogModal/ActivateAssetDialogModal';
import { selectSetUnavailableVehicleRequestStatus } from 'common/store/vehicle-schedules/selectors';
import CircleIcon from 'common/icons/CircleIcon';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { trailersPaginationChannel, trailersRefreshChannel } from 'common/store/trailers/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import ActiveFleetCount from './ActiveFleetCount/ActiveFleetCount';
import { useQueryParams, withDefault } from 'use-query-params';
import { createEnumParam } from 'serialize-query-params/src/params';
import { useCheckOpenedSidebar, useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { selectPermissions } from 'common/store/auth/selectors';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkFilters } from 'common/components/Table/utils/check-filters';
import ButtonGroup from 'design-system/components/ButtonGroup/ButtonGroup';
import AssetsXLSDropdownControl from 'common/components/controls/AssetsXLSDropdownControl/AssetsXLSDropdownControl';
import { useImportXLSAssetsModalDialog } from 'common/layouts/dialogs/ImportXLSAssetsDialogModal/hooks/useImportXLSAssetsModalDialog';
import ImportXLSAssetsDialogModal, {
    ImportXLSAssetsDialogModalDataT,
} from 'common/layouts/dialogs/ImportXLSAssetsDialogModal/ImportXLSAssetsDialogModal';

const cx = classNames.bind(styles);

type PropsT = {
    onOpenUserDetails: (userId: UserIdT | null) => void;
};

const getTrailerIds = (selectedTrailersSet: Set<ApiTrailerT>): Array<string> => {
    return [...selectedTrailersSet].map((trailer) => trailer.id).filter(isNonNil);
};

const TAB_LABEL_T: Record<TabEnum, string> = {
    [TabEnum.active]: 'common:trailers-page.tabs.active',
    [TabEnum.archive]: 'common:trailers-page.tabs.archive',
};

const SORT_LABELS_T: Record<TrailersSortEnum, string> = {
    [TrailersSortEnum.added]: 'common:trailers-page.sorts.triggers.added',
    [TrailersSortEnum.type]: 'common:trailers-page.sorts.triggers.type',
    [TrailersSortEnum.subtype]: 'common:trailers-page.sorts.triggers.subtype',
    [TrailersSortEnum.plateNumber]: 'common:trailers-page.sorts.triggers.plateNumber',
    [TrailersSortEnum.status]: 'common:trailers-page.sorts.triggers.status',
};

const TrailersPageLayout: React.FC<PropsT> = React.memo((props) => {
    const { onOpenUserDetails } = props;

    const { partnerId } = usePartnerContext();

    const permissions = useSelector(selectPermissions);
    const { canEditTrailers } = permissions;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.trailersPage]: createPageNumberParam(),
        [QueryKeysEnum.trailersSort]: createSortParams<TrailersSortEnum>({
            value: TrailersSortEnum.added,
            direction: SortDirectionEnum.DESC,
        }),
        [QueryKeysEnum.trailersFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.trailersTab]: withDefault(createEnumParam<TabEnum>(Object.values(TabEnum)), TabEnum.active),
    });

    const [selectedTrailersSet, setSelectedTrailersSet] = React.useState<Set<ApiTrailerT>>(new Set());

    const total = useSelector(selectTrailersTotal(partnerId));
    const trailersPages = useSelector(selectTrailersPages(partnerId));
    const trailerById = useSelector(selectTrailersByIds(partnerId));
    const updateTrailersRequestStatus = useSelector(selectUpdateTrailersRequestStatus(partnerId));

    const selectedSort = query[QueryKeysEnum.trailersSort];
    const pageNumber = query[QueryKeysEnum.trailersPage];
    const queryFilters = query[QueryKeysEnum.trailersFilters];

    const activeTab = query[QueryKeysEnum.trailersTab];
    const page = trailersPages[pageNumber];
    const { ids, requestStatus } = page || {};

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort, activeTab);
        dispatch(fetchTrailersPage(pageNumber, query, partnerId));

        dispatch(fetchTrailersStats(partnerId));
    }, [pageNumber, selectedSort, queryFilters, activeTab]);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort, activeTab);
        dispatch(fetchTrailersPage(pageNumber, query, partnerId, { isForceUpdate: true }));

        dispatch(fetchTrailersStats(partnerId, { isForceUpdate: true }));
    }, [pageNumber, queryFilters, selectedSort, activeTab, partnerId]);
    useChannelSubscribe(trailersRefreshChannel, refreshPageHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort, activeTab);
        dispatch(fetchTrailersPage(pageNumber, query, partnerId, { isForceUpdate: false }));

        dispatch(fetchTrailersStats(partnerId, { isForceUpdate: false }));
    }, [pageNumber, queryFilters, selectedSort, activeTab, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const trailers = React.useMemo(() => {
        return (ids || []).map((id): ApiTrailerT => trailerById[id as string]).filter(isNonNil);
    }, [ids, trailerById]);

    React.useEffect(() => {
        setSelectedTrailersSet(new Set());
    }, [ids]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            setSelectedTrailersSet(new Set());

            changeQuery({
                [QueryKeysEnum.trailersPage]: pageNumber,
            });
        },
        [setSelectedTrailersSet, query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof trailersPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(trailersPaginationChannel, setPageHandler);

    const sortOptions: Array<SortDropdownOptionT<TrailersSortEnum>> = React.useMemo(() => {
        return [
            TrailersSortEnum.added,
            TrailersSortEnum.type,
            TrailersSortEnum.subtype,
            TrailersSortEnum.plateNumber,
            TrailersSortEnum.status,
        ].map((sort) => {
            return {
                label: t(SORT_LABELS_T[sort]),
                value: sort,
            };
        });
    }, [t]);

    const handleSelectSort = (sort: PageSortT<TrailersSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.trailersPage]: 0,
            [QueryKeysEnum.trailersSort]: sort,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.trailersPage]: 0,
            [QueryKeysEnum.trailersFilters]: selectedQueryFilters,
        });
    };

    const resetQueryFilters = () => {
        changeQuery({
            [QueryKeysEnum.trailersFilters]: undefined,
        });
    };

    const addRequestStatus = useSelector(selectAddTrailerRequestStatus(partnerId));
    React.useEffect(() => {
        if (addRequestStatus.ok) {
            changeQuery({
                [QueryKeysEnum.trailersFilters]: undefined,
                [QueryKeysEnum.trailersPage]: 0,
            });
        }
    }, [addRequestStatus.ok]);

    const archiveAssetConfirmation = useModalDialog<ArchiveAssetConfirmationDataT>();
    const activateAssetConfirmation = useModalDialog<ActivateAssetConfirmationDataT>();

    const trailersStats = useSelector(selectTrailersStats(partnerId));

    const tableActionOptions: Array<TableActionOptionT | null> = [
        activeTab === TabEnum.active
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                          label={t('common:trailers-page.actions.archive')}
                      />
                  ),
                  onSelect: () => {
                      const ids = getTrailerIds(selectedTrailersSet);

                      archiveAssetConfirmation.setData({
                          assets: ids.map((id) => {
                              return {
                                  id,
                                  plateNumber: trailerById[id]?.plateNumber || '',
                              };
                          }),
                          companyId: partnerId,
                      });
                  },
              }
            : null,
        activeTab === TabEnum.archive
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<CircleIcon borderColor={StyleGuideColorsEnum.brandAccent} />}
                          label={t('common:trailers-page.actions.activate')}
                      />
                  ),
                  onSelect: () => {
                      const ids = getTrailerIds(selectedTrailersSet);

                      activateAssetConfirmation.setData({
                          assets: ids.map((id) => {
                              const trailer = trailerById[id];

                              return {
                                  id,
                                  plateNumber: trailer?.plateNumber || '',
                                  ratePerKm: null,
                              };
                          }),
                          companyId: partnerId,
                      });
                  },
              }
            : null,
    ];

    const importXLSAssetsModalDialog = useImportXLSAssetsModalDialog();

    const isDisabledTableActions = updateTrailersRequestStatus.loading;

    const handleSelectRow = (event: React.MouseEvent, trailer: ApiTrailerT) => {
        if (!trailer.id) {
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.trailerDetails,
            trailerId: trailer.id,
            partnerId,
        });
    };

    const isPartner = partnerId !== DEFAULT_CARRIER_COMPANY_ID;

    const handleSelectTab = (tab: TabEnum): void => {
        changeQuery({
            [QueryKeysEnum.trailersPage]: 0,
            [QueryKeysEnum.trailersTab]: tab,
        });
    };

    const tabsNode = (
        <ButtonGroup
            className={cx('tabs', {
                'tabs--isPartner': isPartner,
            })}
        >
            {map(TabEnum, (tab) => (
                <Button
                    isNarrow
                    key={tab}
                    className={cx('tabs__tab')}
                    leftIcon={tab === TabEnum.active && !!trailersStats?.actualTotalCount ? <ActiveFleetCount /> : null}
                    theme={ButtonThemeEnum.transparent}
                    onClick={() => {
                        handleSelectTab(tab);
                    }}
                    isToggled={tab === activeTab}
                >
                    {t(TAB_LABEL_T[tab])}
                </Button>
            ))}
        </ButtonGroup>
    );

    const setUnavailableStatus = useSelector(selectSetUnavailableVehicleRequestStatus);
    const updateRequestStatus = useSelector(selectUpdateTrailersRequestStatus(partnerId));

    useEffect(() => {
        setSelectedTrailersSet(new Set());
    }, [setUnavailableStatus, updateRequestStatus]);

    useCloseModalDialogAfterRequest(updateRequestStatus, [activateAssetConfirmation, archiveAssetConfirmation]);

    const importXLSRequestStatus = useSelector(selectTrailersImportXLSRequest(partnerId));

    const handleImportXLS = (data: ImportXLSAssetsDialogModalDataT) => {
        dispatch(importXLSTrailers(partnerId, data.file));
    };

    const exportXLSRequestStatus = useSelector(selectTrailersExportXLSRequest(partnerId));

    const handleExportXLS = () => {
        dispatch(exportXLSTrailers(partnerId, {}));
    };

    const handleFilteredExportXLS = () => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort, activeTab);
        dispatch(exportXLSTrailers(partnerId, query));
    };

    const openLeftSidebar = useOpenLeftSidebar();
    const isOpenedFilters = useCheckOpenedSidebar(CommonSidebarsTypeEnum.trailerFilters);
    const openFilters = React.useCallback(() => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.trailerFilters,
        });
    }, [openLeftSidebar]);

    const handleConfirmActivate = (data: ActivateAssetConfirmationDataT, ratesPerKm: Array<number | null>): void => {
        const ids = data.assets.map((asset) => asset.id);
        dispatch(activationTrailersRequest(ids, ratesPerKm, partnerId));
    };

    const handleConfirmArchive = (data: ArchiveAssetConfirmationDataT, reason: string): void => {
        const ids = data.assets.map((asset) => asset.id);
        dispatch(archiveTrailersRequest(ids, data.companyId, reason));
    };

    const showAddSidebar = () => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.addTrailer,
            partnerId,
        });
    };

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(trailers, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasPrimaryQueryFilters, hasSecondaryQueryFilters } = checkFilters(
            [query[QueryKeysEnum.trailersTab] !== TabEnum.active ? query[QueryKeysEnum.trailersTab] : null],
            [query[QueryKeysEnum.trailersFilters]],
        );

        if (requestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('common:trailers-page.table.messages.not-found.title')}
                    description={t('common:trailers-page.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('common:trailers-page.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={resetQueryFilters}
                    testSelector="empty-state-has-secondary-filters"
                />
            );
        }

        if (requestStatus?.ok && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('common:trailers-page.table.messages.empty-quick-filters.title')}
                    description=""
                    testSelector="empty-state-has-primary-filters"
                    isShowAction={false}
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('common:trailers-page.table.messages.empty.title')}
                description={t('common:trailers-page.table.messages.empty.description')}
                isShowAction={canEditTrailers}
                actionTitle={t('common:trailers-page.table.messages.empty.action')}
                onActionClick={showAddSidebar}
                testSelector="empty"
            />
        );
    };

    return (
        <>
            <ContentMargins>
                {!isPartner && tabsNode}
                <ListPageLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                {isPartner && tabsNode}
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('common:trailers-page.filters.trigger')}
                                    isActive={isOpenedFilters}
                                    onClick={openFilters}
                                />
                                <SortDropdown
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                            </>
                        }
                        rightToolsNode={
                            <>
                                {!!selectedTrailersSet.size && (
                                    <TableActions
                                        className={cx('actions')}
                                        options={tableActionOptions}
                                        trigger={t('common:trailers-page.actions-trigger')}
                                        isDisabled={isDisabledTableActions}
                                    />
                                )}
                                <AssetsXLSDropdownControl
                                    className={cx('xls-actions')}
                                    isAllowExport
                                    isAllowImport
                                    isLoading={exportXLSRequestStatus.loading}
                                    onExportFilteredAssets={handleFilteredExportXLS}
                                    onExportAssets={handleExportXLS}
                                    onImportAssets={importXLSAssetsModalDialog.onStartImportXLS}
                                />
                                {canEditTrailers ? (
                                    <Button theme={ButtonThemeEnum.primary} type="submit" onClick={showAddSidebar}>
                                        {t('common:trailers-page.add-trailer.trigger')}
                                    </Button>
                                ) : null}
                            </>
                        }
                        filterTagsNode={
                            <SelectedFilters
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                            />
                        }
                    />
                    {renderTableMessage()}
                    <TrailersTable
                        onOpenUserDetails={onOpenUserDetails}
                        trailers={trailers}
                        onSelectRow={handleSelectRow}
                        selectedTrailersSet={selectedTrailersSet}
                        onSelectTrailers={setSelectedTrailersSet}
                        isLoading={requestStatus?.loading}
                        allowedCheckboxes={canEditTrailers}
                        activeTab={activeTab}
                    />
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <ArchiveAssetConfirmationModal
                assetType={AssetTypeEnum.trailer}
                data={archiveAssetConfirmation.data}
                onConfirm={handleConfirmArchive}
                onCancel={archiveAssetConfirmation.onCancel}
                onClose={archiveAssetConfirmation.onClose}
                requestStatus={updateRequestStatus}
            />
            <ActivateAssetDialogModal
                assetType={AssetTypeEnum.trailer}
                data={activateAssetConfirmation.data}
                onConfirm={handleConfirmActivate}
                onCancel={activateAssetConfirmation.onCancel}
                onClose={activateAssetConfirmation.onClose}
                requestStatus={updateRequestStatus}
            />
            <ImportXLSAssetsDialogModal
                assetType={AssetTypeEnum.trailer}
                requestStatus={importXLSRequestStatus}
                data={importXLSAssetsModalDialog.data}
                onClose={importXLSAssetsModalDialog.onClose}
                onCancel={importXLSAssetsModalDialog.onCancel}
                onImportXLS={handleImportXLS}
            />
        </>
    );
});

export default TrailersPageLayout;
