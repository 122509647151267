import * as React from 'react';
import PillLabel, { PillLabelPropsT, PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiEmissionClassT, EmissionClassEnum } from 'common/constants';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';

type PropsT = Omit<PillLabelPropsT, 'theme'> & {
    emissionClass: ApiEmissionClassT | null | undefined;
};

export const PILL_THEME_BY_DRIVER_STATUS: Record<ApiEmissionClassT, PillLabelThemeEnum> = {
    [EmissionClassEnum.euro5]: PillLabelThemeEnum.slate,
    [EmissionClassEnum.euro6]: PillLabelThemeEnum.brandAccent,
};

const EmissionClassPill: React.FC<PropsT> = React.memo((props) => {
    const { emissionClass, ...restProps } = props;

    if (!emissionClass) {
        return null;
    }

    return (
        <PillLabel isNoWrap {...restProps} theme={PILL_THEME_BY_DRIVER_STATUS[emissionClass]}>
            <EmissionClassLabel emissionClass={emissionClass} />
        </PillLabel>
    );
});

export default EmissionClassPill;
