import React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const XLSIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.979 2H2v8h2V4h8v4h4v2h2V6l-4.021-4zm-9.11 11.729 1.047-2.127h1.762l-1.789 3.172L7.726 18h-1.78L4.87 15.833 3.793 18H2.018l1.832-3.226-1.784-3.172h1.758l1.046 2.127zm4.9 3.084h2.686V18H8.227v-6.398H9.77v5.211zm6.636-.514a.634.634 0 0 0-.24-.527c-.159-.126-.44-.256-.84-.391a8.074 8.074 0 0 1-.985-.391c-.83-.407-1.244-.967-1.244-1.679 0-.354.103-.667.31-.938.207-.271.499-.482.877-.633.378-.15.802-.226 1.274-.226.46 0 .872.082 1.237.246.365.164.648.397.85.7.203.304.304.65.304 1.04H16.41c0-.26-.08-.463-.24-.606-.16-.144-.376-.216-.648-.216-.275 0-.493.061-.653.183a.56.56 0 0 0-.24.463c0 .164.089.313.265.446.175.134.484.271.927.413a5.68 5.68 0 0 1 1.09.46c.691.398 1.037.947 1.037 1.648 0 .56-.211.999-.633 1.318-.422.32-1 .479-1.736.479-.518 0-.988-.093-1.408-.28-.42-.185-.737-.44-.95-.764a1.992 1.992 0 0 1-.318-1.118h1.547c0 .343.088.595.266.758.177.162.465.244.863.244.255 0 .456-.055.604-.165a.544.544 0 0 0 .222-.464z"
                fill={fillColor}
                fillRule="evenodd"
            />
        </svg>
    );
};

XLSIcon.displayName = 'XLSIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };

export default XLSIcon;
