import * as React from 'react';
import { DEFAULT_ICON_SIZE } from 'common/constants';

type PropsT = {
    className?: string;
    size: number;
};

const LogoIcon: React.FC<PropsT> = (props) => {
    const { className, size } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={size} height={size} viewBox="0 0 31 30">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-25 -20) translate(25 20)">
                        <ellipse cx="15.591" cy="15" fill="#8ABA2E" rx="15.118" ry="15" />
                        <path
                            fill="#FFF"
                            fillRule="nonzero"
                            d="M20.428 22.8L16.195 22.8 16.195 7.2 20.428 7.2zM14.986 22.8L10.753 22.8 10.753 7.2 14.986 7.2zM14.986 5.4L10.753 5.4 10.753 1.2 14.986 1.2zM20.428 28.8L16.195 28.8 16.195 24.6 20.428 24.6z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

LogoIcon.displayName = 'LogoIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
};

export { storyProps };
export default LogoIcon;
