import React from 'react';
import { SyncSetUnavailableFormMapStateContextValueT } from '../hooks/usySyncMapState';

const SyncSetUnavailableFormMapStateContext = React.createContext<SyncSetUnavailableFormMapStateContextValueT>({
    hasDropAssetPointError: false,
    setHasDropAssetPointError: () => {
        // nothing
    },
});

export { SyncSetUnavailableFormMapStateContext };
