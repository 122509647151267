import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './SpotLanesTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import { SpotCarrierLaneT } from 'carrier/store/spot-carrier-lanes/models';
import CountryCell from 'common/components/Table/cell-renderers/CountryCell/CountryCell';
import EventAuthorCell from 'common/components/Table/cell-renderers/EventAuthorCell/EventAuthorCell';
import CheckboxHeader from 'common/components/Table/header-renderers/CheckboxHeader/CheckboxHeader';
import CheckboxCell from 'common/components/Table/cell-renderers/CheckboxCell/CheckboxCell';
import ControlsCell from './cell-renderers/ControlsCell/ControlsCell';
import TrailerVariantCell from 'carrier/layouts/ProfilePage/SpotLaneListPage/SpotLanesTable/cell-renderers/TrailerVariantCell/TrailerVariantCell';
import { SpotCarrierLanesTrailerVariantEnum } from 'carrier/store/spot-carrier-lane-changes/models';

const cx = classNames.bind(styles);

const UPDATE_DATE_FORMAT = 'DD MMM YYYY';

type PropsT = {
    spotLanes: Array<SpotCarrierLaneT>;
    className: string;
    selectedLanesSet: Set<SpotCarrierLaneT>;
    onSelectLanes: (selectedLanesSet: Set<SpotCarrierLaneT>) => void;
    onDeleteSpotLane: (spotLane: SpotCarrierLaneT) => void;
    onOpenSpotLaneDetails: (spotLane: SpotCarrierLaneT) => void;
    onChangeTrailerVariant: (spotLane: SpotCarrierLaneT, trailerVariant: SpotCarrierLanesTrailerVariantEnum) => void;
    onOpenUserDetails: (userId: UserIdT | null) => void;
    isLoading: boolean;
};

const SpotLanesTable: React.FC<PropsT> = React.memo((props) => {
    const {
        spotLanes,
        className,
        onOpenSpotLaneDetails,
        onOpenUserDetails,
        onChangeTrailerVariant,
        onDeleteSpotLane,
        isLoading,
        selectedLanesSet,
        onSelectLanes,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<SpotCarrierLaneT, void>> = React.useMemo(
        () => [
            {
                renderHeader: () => (
                    <CheckboxHeader<SpotCarrierLaneT>
                        selectedRowsSet={selectedLanesSet}
                        rows={spotLanes}
                        onChange={onSelectLanes}
                    />
                ),
                headerClassName: cx('table__header', 'table__header--checkbox'),
                render: (spotLane: SpotCarrierLaneT) => (
                    <CheckboxCell<SpotCarrierLaneT>
                        row={spotLane}
                        selectedRowsSet={selectedLanesSet}
                        onChange={onSelectLanes}
                    />
                ),
                className: cx('table__column', 'table__column--checkbox'),
                testSelector: 'checkbox',
            },
            {
                renderHeader: () => <span>{t('spot-carrier-lanes.list.table.columns.from')}</span>,
                headerClassName: cx('table__header'),
                render: (spotLane: SpotCarrierLaneT) => <CountryCell countryCode={spotLane.from} />,
                className: cx('table__column'),
                testSelector: 'from',
            },
            {
                renderHeader: () => <span>{t('spot-carrier-lanes.list.table.columns.to')}</span>,
                headerClassName: cx('table__header'),
                render: (spotLane: SpotCarrierLaneT) => <CountryCell countryCode={spotLane.to} />,
                className: cx('table__column'),
                testSelector: 'to',
            },
            {
                renderHeader: () => <span>{t('spot-carrier-lanes.list.table.columns.trailer-type')}</span>,
                headerClassName: cx('table__header', 'table__header--trailer-variant'),
                render: (spotLane: SpotCarrierLaneT) => (
                    <TrailerVariantCell spotLane={spotLane} onChangeTrailerVariant={onChangeTrailerVariant} />
                ),
                className: cx('table__column', 'table__column--trailer-variant'),
                testSelector: 'trailer-type',
            },
            {
                renderHeader: () => <span>{t('spot-carrier-lanes.list.table.columns.updated')}</span>,
                headerClassName: cx('table__header'),
                render: (spotLane: SpotCarrierLaneT) => {
                    return <DateCell isBigFont isBold date={spotLane.updatedDate} dateFormat={UPDATE_DATE_FORMAT} />;
                },
                className: cx('table__column'),
                testSelector: 'update-date',
            },
            {
                renderHeader: () => <span>{t('spot-carrier-lanes.list.table.columns.updated-by')}</span>,
                headerClassName: cx('table__header'),
                render: (spotLane: SpotCarrierLaneT) => {
                    return (
                        <EventAuthorCell
                            userId={spotLane.updatedById}
                            userFullName={spotLane.updatedByName}
                            onOpenUserDetails={onOpenUserDetails}
                        />
                    );
                },
                className: cx('table__column', 'table__column--updated'),
                testSelector: 'update-author',
            },
            {
                renderHeader: () => null,
                headerClassName: cx('table__header', 'table__header--controls'),
                render: (spotLane: SpotCarrierLaneT) => (
                    <ControlsCell spotLane={spotLane} onDeleteSpotLane={onDeleteSpotLane} />
                ),
                className: cx('table__column', 'table__column--controls'),
                testSelector: 'controls',
            },
        ],
        [t, selectedLanesSet],
    );

    const handleSelectRow = (event: React.MouseEvent, spotLane: SpotCarrierLaneT) => {
        if (!spotLane.id) {
            return;
        }

        onOpenSpotLaneDetails(spotLane);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRowMods = (meta: TableRowMetaT, spotLane: SpotCarrierLaneT) => {
        return {};
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<SpotCarrierLaneT> tableName="spot-carrier-lanes" isLoading={isLoading} rows={spotLanes}>
                {(rows, isUsedPrevRows) => (
                    <Table<SpotCarrierLaneT, void>
                        testSelector="spot-carrier-lanes"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default SpotLanesTable;
