import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './DropdownSearchMultipleInput.scss';
import SearchIcon from 'common/icons/SearchIcon';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseLayout from '../base/DropdownBaseLayout/DropdownBaseLayout';
import DropdownBaseButtonTrigger from '../base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import cs from 'classnames';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import useDebouncedEffect from 'common/utils/hooks/useDebouncedEffect';
import filter from 'lodash/filter';
import {
    checkIsSpecialOption,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';

const cx = classNames.bind(styles);

export type IconMetaT = {
    isDisabled: boolean;
    isLoading: boolean;
    hasChanges: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    hasValue: boolean;
};

export type PropsT<OptionT, ValueT> = {
    className?: string;
    selectedValues: Array<ValueT>;
    placeholder?: string;
    inputPlaceholder?: string;
    isInline?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    hasWarning?: boolean;
    hasError?: boolean;
    hasSuccess?: boolean;
    hasChanges?: boolean;
    options: Array<OptionT | SpecialOptionEnum>;
    onSelect: (values: Array<ValueT>) => void;
    overlayPosition: DropdownOverlayPositionEnum;
    triggerClassName?: string;
    overlayClassName?: string;
    renderTrigger: (options: Array<OptionT>, placeholder: string | undefined) => React.ReactNode;
    renderOption: (option: OptionT) => React.ReactNode;
    getOptionValue: (option: OptionT) => ValueT;
    onFocus?: () => void;
    onBlur?: () => void;
    testSelector?: string;
    renderLeftIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightInputIcon?: (meta: IconMetaT) => React.ReactNode;
    onChangeQuery: (query: string) => void;
    onReset?: () => void;
};

const COLD_TIME_MS = 300;

const DropdownSearchMultipleInput = <OptionT, ValueT>(props: PropsT<OptionT, ValueT>) => {
    const {
        className,
        selectedValues,
        placeholder,
        inputPlaceholder,
        onSelect,
        options,
        isInline,
        renderOption,
        getOptionValue,
        isDisabled,
        overlayPosition,
        triggerClassName,
        overlayClassName,
        renderTrigger,
        onFocus,
        onBlur,
        testSelector,
        isLoading,
        hasWarning,
        hasError,
        hasSuccess,
        hasChanges,
        onChangeQuery,
        onReset,
        renderRightInputIcon,
        renderLeftIcon,
        renderRightIcon,
    } = props;

    const [inputValue, setInputValue] = React.useState<string>('');

    useDebouncedEffect(
        (): void => {
            onChangeQuery(inputValue);
        },
        COLD_TIME_MS,
        [inputValue],
    );

    const [isOpen, toggleOpen] = React.useState<boolean>(false);

    useEffect(() => {
        if (isDisabled) {
            toggleOpen(false);
        }
    }, [isDisabled]);

    const handleOpen = (): void => {
        if (isDisabled) {
            return;
        }

        toggleOpen(true);

        if (onFocus) {
            onFocus();
        }
    };

    const handleClose = (): void => {
        toggleOpen(false);

        if (onBlur) {
            onBlur();
        }
    };

    const handleOuterEvent = (): void => {
        handleClose();
    };

    const selectedValuesSet = new Set<ValueT>(selectedValues);

    const inputProps = {
        onChange: (event: TODO) => setInputValue(event.target.value),
        value: inputValue || '',
        type: 'text',
        className: cx('search-input', {
            'search-input--isEmpty': !inputValue,
            'search-input--isFocus': isOpen,
        }),
        placeholder: inputPlaceholder,
        disabled: isDisabled,
    };

    const handleChange = (value: ValueT): void => {
        if (isDisabled) {
            return;
        }

        const newSelectedValuesSet = new Set(selectedValuesSet);
        const isAlreadySelected = newSelectedValuesSet.has(value);
        if (isAlreadySelected) {
            newSelectedValuesSet.delete(value);
        } else {
            newSelectedValuesSet.add(value);
        }
        onSelect([...newSelectedValuesSet]);
    };

    const selectedOptionsRef = React.useRef<Array<OptionT>>([]);
    if (selectedValues) {
        const findedSelectedOptions =
            filter(options, (option) => {
                if (checkIsSpecialOption(option)) {
                    return false;
                }

                return selectedValuesSet.has(getOptionValue(option));
            }) || null;
        if (findedSelectedOptions?.length === selectedValuesSet?.size) {
            selectedOptionsRef.current = findedSelectedOptions as Array<OptionT>;
        }
    } else {
        selectedOptionsRef.current = [];
    }

    const selectedOptions = selectedOptionsRef.current;

    const hasValue = !!selectedValues?.length;

    return (
        <DropdownBaseLayout
            isInline={isInline}
            isOpen={isOpen}
            className={className}
            onClose={handleOuterEvent}
            triggerNode={
                <DropdownBaseButtonTrigger
                    isEmpty={!selectedOptions.length}
                    isPressed={isOpen}
                    isDisabled={isDisabled}
                    renderLeftIcon={
                        renderLeftIcon
                            ? (iconMeta) =>
                                  renderLeftIcon({
                                      ...iconMeta,
                                      isLoading: !!isLoading,
                                      hasValue,
                                  })
                            : undefined
                    }
                    renderRightIcon={
                        renderRightIcon
                            ? (iconMeta) =>
                                  renderRightIcon({
                                      ...iconMeta,
                                      isLoading: !!isLoading,
                                      hasValue,
                                  })
                            : undefined
                    }
                    className={triggerClassName}
                    testSelector={testSelector}
                    onClick={handleOpen}
                    hasChanges={hasChanges}
                    hasWarning={hasWarning}
                    hasError={hasError}
                    hasSuccess={hasSuccess}
                    isShowClearControl={!!onReset && hasValue}
                    onReset={() => {
                        if (onReset) {
                            onReset();
                        }
                    }}
                >
                    {renderTrigger(selectedOptions, placeholder)}
                </DropdownBaseButtonTrigger>
            }
            overlayPosition={overlayPosition}
            overlayClassName={cs(cx('overlay'), overlayClassName)}
            overlayNode={
                <>
                    <div className={cx('search-input-wrap')}>
                        <input ref={(node) => node?.focus()} {...inputProps} />
                        {renderRightInputIcon && (
                            <div className={cx('icon', 'icon--right-icon')}>
                                {renderRightInputIcon({
                                    isDisabled: !!isDisabled,
                                    hasChanges: !!hasChanges,
                                    hasWarning: !!hasWarning,
                                    hasSuccess: !!hasSuccess,
                                    hasError: !!hasError,
                                    isLoading: !!isLoading,
                                    hasValue,
                                })}
                            </div>
                        )}
                        <div className={cx('icon', 'icon--search')}>
                            {isLoading ? (
                                <ControlLoaderIcon
                                    fillColor={StyleGuideColorsEnum.brandDark}
                                    size={DEFAULT_ICON_SIZE}
                                />
                            ) : (
                                <SearchIcon fillColor={StyleGuideColorsEnum.gray} />
                            )}
                        </div>
                    </div>
                    <div className={cx('options')}>
                        {options.map((option, index): React.ReactElement | null => {
                            if (checkIsSpecialOption(option)) {
                                const isLastOption = index === options.length - 1;
                                if (isLastOption) {
                                    return null;
                                }

                                return <div key={index} className={cx('separator')} />;
                            }

                            const value = getOptionValue(option);

                            const isSelected = selectedValuesSet.has(value);

                            return (
                                <div key={index} className={cx('option')}>
                                    <Checkbox
                                        checked={isSelected}
                                        className={cx('option__checkbox')}
                                        label={null}
                                        onChange={() => {
                                            handleChange(value);
                                        }}
                                    />
                                    <div
                                        className={cx('option__label')}
                                        onClick={() => {
                                            handleChange(value);
                                        }}
                                    >
                                        {renderOption(option)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            }
        />
    );
};

export default DropdownSearchMultipleInput;
