import { clientConfig } from 'common/utils/client-config';

export enum ClientFeatureEnum {
    errorDetails = 'error-details',
    i18nLanguageSwitcher = 'local-switcher',
    debugAlertForm = 'debug-alert-form',
    debugNotificationsForm = 'debug-notifications-form',
    brokerOrders = 'broker-orders',
    partnerOrders = 'partner-orders',
    partnerInvoices = 'partner-invoices',
    partnerDrivers = 'partner-drivers',
    assignmentExcludeCountries = 'assignment-exclude-countries',
}

export type ClientFeaturesConfigT = Partial<Record<ClientFeatureEnum, boolean>>;

const LOCAL_STORAGE_KEY = 'client-features/v1.0';

export const saveClientFeaturesConfig = (config: ClientFeaturesConfigT) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(config));
};

export const clearClientFeaturesConfig = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
};

const defaultConfig: ClientFeaturesConfigT = clientConfig.isProduction
    ? {}
    : {
          [ClientFeatureEnum.errorDetails]: true,
          [ClientFeatureEnum.brokerOrders]: true,
          [ClientFeatureEnum.partnerOrders]: true,
          [ClientFeatureEnum.partnerInvoices]: true,
          [ClientFeatureEnum.partnerDrivers]: true,
      };

export const getClientFeaturesConfig = (): ClientFeaturesConfigT => {
    const parsedConfig = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

    return {
        ...defaultConfig,
        ...parsedConfig,
    };
};
