import classNames from 'classnames/bind';
import styles from './MapTooltip.scss';
import cs from 'classnames';
import * as React from 'react';
import isNil from 'lodash/isNil';
import MapPin, { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';

const cx = classNames.bind(styles);

type PropsT = {
    lat: number | undefined;
    lng: number | undefined;
    className?: string;
    contentClassName?: string;
    hasPaddings?: boolean;
    theme?: MapPinThemeEnum;
    children: () => React.ReactElement;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    isHover?: boolean;
    onHover?: (isHover: boolean) => void;
    footerNode?: React.ReactNode;
    mapLayers?: {
        regular: number;
        hovered: number;
    };
};

const MapTooltip: React.FC<PropsT> = React.memo((props) => {
    const { isHover, onHover, hasPaddings, lat, lng, theme, footerNode, className, mapLayers } = props;

    if (isNil(lat) || isNil(lng)) {
        return null;
    }

    return (
        <div
            data-map-layer={isHover ? mapLayers?.hovered : mapLayers?.regular}
            className={cs(cx('map-tooltip-wrap'), className)}
            onMouseMove={() => {
                if (!isHover && onHover) {
                    onHover(true);
                }
            }}
            onMouseLeave={() => {
                if (isHover && onHover) {
                    onHover(false);
                }
            }}
            onClick={props.onClick}
        >
            <MapPin className={props.contentClassName} hasPaddings={hasPaddings} theme={theme}>
                {props.children()}
            </MapPin>
            {!!footerNode && <div className={cx('footer-wrap')}>{footerNode}</div>}
        </div>
    );
});

export default MapTooltip;
