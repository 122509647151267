import React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import { TrailerStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import DropdownMultipleInput, {
    RenderTriggerPropsT,
} from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DropdownBaseButtonTrigger from 'design-system/components/dropdowns/base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { TRAILER_STATUS_T_MAP } from 'common/components/status/TrailerStatus/TrailerStatus';
import TrailerStatusPill from 'common/components/status-pill/TrailerStatusPill/TrailerStatusPill';

type OptionValueT = TrailerStatusEnum | null;

type PropsT = {
    placeholder?: string;
    value: Array<OptionValueT>;
    isDisabled?: boolean;
    onChange: (value: Array<OptionValueT>) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
    overlayPosition?: DropdownOverlayPositionEnum;
    excludeStatuses?: Array<TrailerStatusEnum>;
};

type TrailerStatusDropdownOptionT = {
    value: OptionValueT;
    label: React.ReactNode;
};

const TrailerStatusMultipleDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
        overlayPosition,
        excludeStatuses,
    } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): TrailerStatusDropdownOptionT[] => {
        return [
            TrailerStatusEnum.free,
            TrailerStatusEnum.inTransit,
            TrailerStatusEnum.archived,
            TrailerStatusEnum.unavailable,
        ]
            .filter((status) => {
                if (excludeStatuses) {
                    return !excludeStatuses.includes(status);
                }

                return true;
            })
            .map((status: TrailerStatusEnum): TrailerStatusDropdownOptionT => {
                return {
                    label: t(TRAILER_STATUS_T_MAP[status]),
                    value: status,
                };
            });
    }, [t]);

    const renderTrigger = (
        props: RenderTriggerPropsT,
        options: Array<TrailerStatusDropdownOptionT>,
        placeholder: string | undefined,
    ): React.ReactNode => {
        if (!options?.length) {
            return <DropdownBaseButtonTrigger {...props}>{placeholder || ''}</DropdownBaseButtonTrigger>;
        }

        if (options.length === 1) {
            const firstOption = options?.[0];

            return <DropdownBaseButtonTrigger {...props}>{firstOption?.label}</DropdownBaseButtonTrigger>;
        }

        return (
            <DropdownBaseButtonTrigger
                {...props}
                renderRightIcon={() => (
                    <PillLabel isNoWrap theme={PillLabelThemeEnum.charcoal} testSelector="number" isSymmetrical>
                        {options.length}
                    </PillLabel>
                )}
            >
                {t('common:dropdown-multiple-input.multiple-values-selected')}
            </DropdownBaseButtonTrigger>
        );
    };

    const renderOption = (option: TrailerStatusDropdownOptionT | null | undefined): React.ReactNode => {
        if (!option) {
            return null;
        }

        return (
            <DropdownControlOptionLabel
                withoutPaddings
                label={<TrailerStatusPill status={option.value} isSymmetrical />}
            />
        );
    };

    const handleSelect = (value: Array<OptionValueT>): void => {
        onChange(value);
    };

    const getOptionValue = (option: TrailerStatusDropdownOptionT): OptionValueT => option.value;

    return (
        <DropdownMultipleInput<TrailerStatusDropdownOptionT, OptionValueT>
            selectedValues={value}
            placeholder={placeholder}
            options={options}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            renderOption={renderOption}
            renderTrigger={renderTrigger}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            testSelector="trailer-statuses"
            onReset={
                hasClearControl
                    ? () => {
                          onChange([]);
                      }
                    : undefined
            }
        />
    );
});

export default TrailerStatusMultipleDropdown;
