import * as React from 'react';
import { useContext, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './AssetDetailsMap.scss';
import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import { AssetTypeEnum } from 'common/constants';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import { parseDateString } from 'common/utils/time';
import AssetServiceLocationContext from 'common/components/AssetSidebarContent/contexts/asset-service-location-context';
import GoogleMapContext, { GoogleMapContextT } from 'common/contexts/google-map-context';
import { GoogleMapsGeocoderApi } from 'common/utils/google-maps-api/google-maps-geocoder-api';
import { GoogleMapsPlacesApi } from 'common/utils/google-maps-api/google-maps-places-api';
import { getGeoCodingLocation } from 'common/utils/google-places';
import ServicePinIcon from 'common/icons/ServicePinIcon';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import { SyncSetUnavailableFormMapStateContext } from 'common/components/AssetSidebarContent/SetUnavailableForm/contexts/sync-map-state';

const cx = classNames.bind(styles);

type PropsT = {
    type: AssetTypeEnum;
    isLoading: boolean;
    currentPosition: GeoPointT | null;
    telematicUpdatedDate: string | undefined;
};

const MAP_PIN_KEY = {
    serviceLocationPoint: 'service-location-point',
};

const geocodeByPoint = async (
    googleMapContext: GoogleMapContextT | null,
    point: GeoPointT,
): Promise<LocationT | null> => {
    const geocoderApi = new GoogleMapsGeocoderApi(googleMapContext?.googleMaps?.maps);
    const geocodeResult = await geocoderApi.geocode(point);
    if (!geocodeResult?.place_id) {
        return null;
    }

    const placesApi = new GoogleMapsPlacesApi(googleMapContext?.googleMaps?.map, googleMapContext?.googleMaps?.maps);
    const result = await placesApi.getPlaceDetails(geocodeResult?.place_id);
    if (!result) {
        return null;
    }

    const location = getGeoCodingLocation(result);
    return location || null;
};

const AssetDetailsMap: React.FC<PropsT> = React.memo((props) => {
    const { type, isLoading, currentPosition, telematicUpdatedDate } = props;

    const { hasDropAssetPointError } = useContext(SyncSetUnavailableFormMapStateContext);

    const { location: contextLocations, setLocation: setContextLocation } = useContext(AssetServiceLocationContext);

    const googleMapContext = React.useContext(GoogleMapContext);

    const apiIsLoaded = (api: TODO) => {
        const { map, maps } = api;

        // eslint-disable-next-line no-unused-expressions
        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const [draggablePin, setDraggablePin] = useState<{
        point: GeoPointT;
        childKey: string;
    } | null>(null);

    const handleDragMove = (childKey: string, childProps: any, mouse: GeoPointT) => {
        if (childKey !== MAP_PIN_KEY.serviceLocationPoint) {
            return;
        }

        setDraggablePin({
            childKey,
            point: {
                lat: mouse.lat,
                lng: mouse.lng,
            },
        });
    };

    const handleDragEnd = async (childKey: string, childProps: any, mouse: GeoPointT) => {
        const pointToDrop = await geocodeByPoint(googleMapContext, mouse);
        if (!pointToDrop) {
            return;
        }

        if (childKey === MAP_PIN_KEY.serviceLocationPoint) {
            setContextLocation(pointToDrop);
        }

        setDraggablePin(null);
    };

    const viewServiceLocationPoint =
        draggablePin?.childKey === MAP_PIN_KEY.serviceLocationPoint ? draggablePin?.point : contextLocations?.point;

    return (
        <>
            {isLoading && <MapLoader />}
            <GoogleMapReact
                draggable={!draggablePin}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
                onChildMouseDown={handleDragMove}
                onChildMouseUp={handleDragEnd}
                onChildMouseMove={handleDragMove}
            >
                <LastAssetTrackPointPin
                    key="trailer-last-point"
                    iconType={type}
                    lat={currentPosition?.lat}
                    lng={currentPosition?.lng}
                    timestamp={parseDateString(telematicUpdatedDate)}
                />
                {viewServiceLocationPoint && (
                    <ServicePinIcon
                        key={MAP_PIN_KEY.serviceLocationPoint}
                        className={cx('location')}
                        lat={viewServiceLocationPoint?.lat}
                        lng={viewServiceLocationPoint?.lng}
                        isWrong={hasDropAssetPointError}
                    />
                )}
            </GoogleMapReact>
        </>
    );
});

export default AssetDetailsMap;
