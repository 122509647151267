import * as React from 'react';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { StyleGuideColorsEnum } from 'common/constants';
import InfoIcon from 'common/icons/InfoIcon';

type PropsT = {
    onClose: () => void;
    className?: string;
};

const ChangeContractWarning: React.FC<PropsT> = React.memo((props) => {
    const { className, onClose } = props;

    const { t } = useTranslation();

    return (
        <Alert
            className={className}
            icon={<InfoIcon fillColor={StyleGuideColorsEnum.white} />}
            size={AlertSizeEnum.small}
            theme={AlertThemeEnum.orange}
            onClose={onClose}
        >
            {t('common:trucks-page.warnings.change-contact')}
        </Alert>
    );
});

export default ChangeContractWarning;
