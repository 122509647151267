import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './EventCell.scss';
import { formatDate, formatHumanReadableDate, formatHumanReadableFullDate } from 'common/utils/time';
import { LinkPropsT, LinkThemeEnum, LinkToT } from 'common/components/Link/Link';
import { useTranslation } from 'react-i18next';
import { clientConfig } from 'common/utils/client-config';
import TableCellLink from 'common/components/Table/cell-renderers/TableCellLink/TableCellLink';

type PropsT = {
    userFullName: string | null | undefined;
    userId: UserIdT | null | undefined;
    // deprecated TODO remove
    getUserLink?: (userId: UserIdT) => LinkToT;
    openUserDetails?: (userId: UserIdT, isBrokerUser?: boolean) => void;
    date: string | null | undefined;
    isSmallFont?: boolean;
    byBroker?: boolean;

    // one of
    isHumanReadableFullDate?: boolean;
    isHumanReadableDate?: boolean;
    dateFormat?: string;
};

const cx = classNames.bind(styles);

const DATE_FORMAT = 'DD MMM YYYY';

const EventCell: React.FC<PropsT> = React.memo((props) => {
    const {
        userFullName,
        userId,
        getUserLink,
        openUserDetails,
        date,
        dateFormat,
        isSmallFont,
        isHumanReadableDate,
        isHumanReadableFullDate,
        byBroker,
    } = props;

    const { t } = useTranslation();

    const dateNode = useMemo(() => {
        if (!date) {
            return null;
        }

        if (isHumanReadableDate) {
            return formatHumanReadableDate(t, date);
        }

        if (isHumanReadableFullDate) {
            return formatHumanReadableFullDate(t, date);
        }

        return formatDate(dateFormat || DATE_FORMAT, date);
    }, [t, date, dateFormat, isHumanReadableDate, isHumanReadableFullDate]);

    const linkProps: Partial<LinkPropsT> = {};
    if (openUserDetails && userId) {
        linkProps.onClick = () => openUserDetails(userId, byBroker);
    }
    if (getUserLink && userId) {
        linkProps.to = getUserLink(userId);
    }

    let linkNode: React.ReactNode = null;

    if (byBroker && !clientConfig.isBrokerProject) {
        linkNode = <div className={cx('link')}>{t('common:broker-name')}</div>;
    } else if (userId) {
        linkNode = (
            <TableCellLink {...linkProps} theme={LinkThemeEnum.boldBrandDark}>
                {userFullName}
            </TableCellLink>
        );
    }

    return (
        <>
            <div
                className={cx('wrap', {
                    'wrap--isSmallFont': isSmallFont,
                })}
            >
                {dateNode}
                {linkNode}
            </div>
        </>
    );
});

export default EventCell;
