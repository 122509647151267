import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    ACTIVATION_TRAILERS_REQUEST,
    ActivationTrailersRequestActionT,
    ADD_TRAILER_REQUEST,
    AddTrailerRequestActionT,
    ARCHIVE_TRAILERS_REQUEST,
    ArchiveTrailersRequestActionT,
    ASSIGN_TO_CONTRACT_TRAILERS_REQUEST,
    AssignToContractRequestActionT,
    DELETE_TRAILERS_REQUEST,
    DeleteTrailersRequestActionT,
    EXPORT_XLS_TRAILERS_REQUEST,
    ExportXLSTrailersActionT,
    FETCH_TRAILER_DETAILS_REQUEST,
    FETCH_TRAILERS_PAGE_REQUEST,
    FETCH_TRAILERS_STATS_REQUEST,
    FetchTrailerDetailsActionT,
    FetchTrailersPageActionT,
    FetchTrailersStatsActionT,
    IMPORT_XLS_TRAILERS_REQUEST,
    ImportXLSTrailersActionT,
    UPDATE_TRAILERS_REQUEST,
    UpdateTrailerActionT,
} from './types';
import {
    addTrailerRequestBegin,
    addTrailerRequestError,
    addTrailerRequestSuccess,
    deleteTrailersRequestBegin,
    deleteTrailersRequestError,
    deleteTrailersRequestSuccess,
    exportXLSTrailersRequestBegin,
    exportXLSTrailersRequestError,
    exportXLSTrailersRequestSuccess,
    fetchTrailerDetails,
    fetchTrailerDetailsRequestBegin,
    fetchTrailerDetailsRequestError,
    fetchTrailerDetailsRequestSuccess,
    fetchTrailersPageBegin,
    fetchTrailersPageError,
    fetchTrailersPageSuccess,
    fetchTrailersStatsRequestBegin,
    fetchTrailersStatsRequestError,
    fetchTrailersStatsRequestSuccess,
    importXLSTrailersRequestBegin,
    importXLSTrailersRequestError,
    importXLSTrailersRequestSuccess,
    resetTrailers,
    updateTrailersRequestBegin,
    updateTrailersRequestError,
    updateTrailersRequestSuccess,
} from './actions';
import {
    selectFetchTrailersStatsRequestStatus,
    selectTrailersByIds,
    selectTrailersDetailsByIds,
    selectTrailersPages,
    selectTrailersQuery,
} from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsDefaultCompanyId } from 'common/utils';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { selectCarrierMainContract } from 'common/store/carrier-contracts/selectors';
import { trailersPaginationChannel, trailersRefreshChannel } from 'common/store/trailers/channels';
import { CompanyTrailersStatsT } from 'common/store/trailers/models';
import downloadFile from 'common/utils/download-file';
import moment from 'moment';

function* fetchTrailersSaga(action: FetchTrailersPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options, companyId } = action;

    const prevQuery: ReReturnT<typeof selectTrailersQuery> = yield select(selectTrailersQuery(companyId));
    const pages: ReReturnT<typeof selectTrailersPages> = yield select(selectTrailersPages(companyId));
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);

    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetTrailers(companyId, { savingPageNumber: pageNumber }));
    }

    yield put(fetchTrailersPageBegin(query, pageNumber, companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.fetchTrailersPage | typeof carrierTranziitApi.fetchTrailersPage
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.fetchTrailersPage({
            ...query,
            page: pageNumber,
        });
    } else {
        result = yield brokerTranziitApi.fetchTrailersPage(
            {
                ...query,
                page: pageNumber,
            },
            companyId,
        );
    }

    if (!result) {
        return;
    }

    const [error, response] = result;
    if (error) {
        yield put(fetchTrailersPageError(query, pageNumber, error, companyId));
        return;
    }

    if (!response) {
        return;
    }

    yield put(fetchTrailersPageSuccess(query, pageNumber, companyId, response));

    checkShouldEmitChangePage(pageNumber, response, trailersPaginationChannel);
}

function* refreshTrailerDetailsIfNeedSaga(companyId: CompanyIdT, trailerId: TrailerIdT): WrapGeneratorT<void> {
    const detailsByIds: ReReturnT<typeof selectTrailersDetailsByIds> = yield select(
        selectTrailersDetailsByIds(companyId),
    );

    if (detailsByIds[trailerId]) {
        yield put(fetchTrailerDetails(trailerId, companyId, { isForceUpdate: true }));
    }
}

function* addTrailerSaga(action: AddTrailerRequestActionT): WrapGeneratorT<void> {
    const { data, companyId } = action;

    yield put(addTrailerRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.fetchTrailersPage | typeof carrierTranziitApi.fetchTrailersPage
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.addTrailer(data);
    } else {
        result = yield brokerTranziitApi.addPartnerTrailer(data, companyId);
    }

    if (!result) {
        return;
    }
    const [error] = result;
    if (error) {
        yield put(addTrailerRequestError(error, companyId));
    } else {
        yield put(addTrailerRequestSuccess(companyId));
    }

    if (!error) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerAdded,
                    data: {
                        plateNumber: data.plateNumber || '',
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});
}

function* deleteTrailersSaga(action: DeleteTrailersRequestActionT): WrapGeneratorT<void> {
    const { trailerIds, companyId } = action;

    yield put(deleteTrailersRequestBegin(companyId));

    let error: ReturnApiT<typeof brokerTranziitApi.deleteTrailer | typeof carrierTranziitApi.deleteTrailer>[0] | null =
        null;

    for (const trailerId of trailerIds) {
        let result: ReturnApiT<
            typeof brokerTranziitApi.deleteTrailer | typeof carrierTranziitApi.deleteTrailer
        > | null = null;
        if (checkIsDefaultCompanyId(companyId)) {
            result = yield carrierTranziitApi.deleteTrailer(trailerId);
        } else {
            result = yield brokerTranziitApi.deleteTrailer(companyId, trailerId);
        }

        error = error || result?.[0] || null;
    }

    if (error) {
        yield put(deleteTrailersRequestError(error, companyId));
    } else {
        yield put(deleteTrailersRequestSuccess(companyId));
    }

    trailersRefreshChannel.emit({});
}

function* activateTrailersSaga(action: ActivationTrailersRequestActionT): WrapGeneratorT<void> {
    const { trailerIds, ratesPerKm, companyId } = action;

    yield put(updateTrailersRequestBegin(companyId));

    let firstError;
    let failedCount = 0;
    let successCount = 0;

    for (let index = 0; index < trailerIds.length; index += 1) {
        const ratePerKm = ratesPerKm[index];
        const trailerId = trailerIds[index];

        let result: ReturnApiT<
            typeof brokerTranziitApi.activateTrailer | typeof carrierTranziitApi.activateTrailer
        > | null = null;
        const activationParams = {
            ids: [trailerId],
            ratePerKm: ratePerKm || undefined,
        };
        if (checkIsDefaultCompanyId(companyId)) {
            result = yield carrierTranziitApi.activateTrailer(activationParams);
        } else {
            result = yield brokerTranziitApi.activateTrailer(companyId, activationParams);
        }

        const error = result?.[0];
        if (error) {
            firstError = error;
            failedCount += 1;
        } else {
            successCount += 1;
        }
    }

    if (firstError) {
        yield put(updateTrailersRequestError(firstError, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    if (trailerIds.length === 1) {
        if (!failedCount && successCount === 1) {
            const byId: ReReturnT<typeof selectTrailersByIds> = yield select(selectTrailersByIds(companyId));
            const trailer = byId[trailerIds[0]];

            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.trailerSuccessfullyActivated,
                        data: {
                            plateNumber: trailer?.plateNumber || '',
                        },
                    }),
                ),
            );
        }
    } else {
        if (failedCount > 0) {
            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.trailersFailedActivate,
                        data: {
                            count: failedCount,
                        },
                    }),
                ),
            );
        }

        if (successCount > 0 && !failedCount) {
            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.trailersSuccessfullyActivated,
                        data: {
                            count: successCount,
                        },
                    }),
                ),
            );
        }
    }

    trailersRefreshChannel.emit({});

    for (const trailerId of trailerIds) {
        yield call(refreshTrailerDetailsIfNeedSaga, companyId, trailerId);
    }
}

function* assignToContractSaga(action: AssignToContractRequestActionT): WrapGeneratorT<void> {
    const { isContracted, trailerIds, companyId } = action;

    const mainContract: ReReturnT<typeof selectCarrierMainContract> = yield select(
        selectCarrierMainContract(companyId),
    );
    const contractId = isContracted ? mainContract?.id : undefined;
    if (!contractId) {
        return;
    }

    yield put(updateTrailersRequestBegin(companyId));

    let error:
        | ReturnApiT<
              typeof brokerTranziitApi.assignTrailerToContract | typeof carrierTranziitApi.assignTrailerToContract
          >[0]
        | null = null;

    for (const trailerId of trailerIds) {
        let result: ReturnApiT<
            typeof brokerTranziitApi.assignTrailerToContract | typeof carrierTranziitApi.assignTrailerToContract
        > | null = null;
        const data = { ids: [trailerId], contractId };
        if (checkIsDefaultCompanyId(companyId)) {
            result = yield carrierTranziitApi.assignTrailerToContract(data);
        } else {
            result = yield brokerTranziitApi.assignTrailerToContract(companyId, data);
        }

        error = error || result?.[0] || null;
    }

    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    trailersRefreshChannel.emit({});
}

function* archiveTrailersSaga(action: ArchiveTrailersRequestActionT): WrapGeneratorT<void> {
    const { trailerIds, companyId, reason } = action;

    yield put(updateTrailersRequestBegin(companyId));

    const data = { ids: trailerIds, comment: reason };

    let result: ReturnApiT<typeof brokerTranziitApi.archiveTrailer | typeof carrierTranziitApi.archiveTrailer> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.archiveTrailer(data);
    } else {
        result = yield brokerTranziitApi.archiveTrailer(companyId, data);
    }

    const error = result?.[0];
    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    if (!error) {
        if (trailerIds.length === 1) {
            const byId: ReReturnT<typeof selectTrailersByIds> = yield select(selectTrailersByIds(companyId));
            const trailer = byId[trailerIds[0]];

            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.trailerSuccessfullyArchived,
                        data: {
                            plateNumber: trailer?.plateNumber || '',
                        },
                    }),
                ),
            );
        } else {
            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.trailersSuccessfullyArchived,
                        data: {
                            count: trailerIds.length,
                        },
                    }),
                ),
            );
        }
    } else {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersFailedArchived,
                    data: {
                        count: trailerIds.length,
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});

    for (const trailerId of trailerIds) {
        yield call(refreshTrailerDetailsIfNeedSaga, companyId, trailerId);
    }
}

function* fetchTrailersStatsSaga(action: FetchTrailersStatsActionT): WrapGeneratorT<void> {
    const { companyId, options } = action;

    const fetchStatsRequestStatus: ReReturnT<typeof selectFetchTrailersStatsRequestStatus> = yield select(
        selectFetchTrailersStatsRequestStatus(companyId),
    );

    const isNeedRequest = checkNeedRequest(fetchStatsRequestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchTrailersStatsRequestBegin(companyId));

    let error: Error | null = null;
    let stats: CompanyTrailersStatsT | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        const result: ReturnApiT<typeof carrierTranziitApi.fetchTrailersActiveFleetCount> =
            yield carrierTranziitApi.fetchTrailersActiveFleetCount();
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            stats = {
                actualTotalCount: data,
            };
        }
    } else {
        const result: ReturnApiT<typeof brokerTranziitApi.fetchTrailersActiveFleetCount> =
            yield brokerTranziitApi.fetchTrailersActiveFleetCount(companyId);
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            stats = {
                actualTotalCount: data,
            };
        }
    }

    if (error) {
        yield put(fetchTrailersStatsRequestError(error, companyId));
        return;
    }

    yield put(fetchTrailersStatsRequestSuccess(stats, companyId));
}

function* fetchTrailerDetailsSaga(action: FetchTrailerDetailsActionT): WrapGeneratorT<void> {
    const { trailerId, companyId, options } = action;

    const detailsById: ReReturnT<typeof selectTrailersDetailsByIds> = yield select(
        selectTrailersDetailsByIds(companyId),
    );
    if (detailsById[trailerId] && !options?.isForceUpdate) {
        return;
    }

    yield put(fetchTrailerDetailsRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.fetchTrailerDetails | typeof carrierTranziitApi.fetchTrailerDetails
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.fetchTrailerDetails(trailerId);
    } else {
        result = yield brokerTranziitApi.fetchTrailerDetails(companyId, trailerId);
    }
    if (!result) {
        return;
    }

    const [error, details] = result;
    if (error) {
        yield put(fetchTrailerDetailsRequestError(error, companyId));
        return;
    }

    yield put(fetchTrailerDetailsRequestSuccess(trailerId, details, companyId));
}

function* updateTrailerSaga(action: UpdateTrailerActionT): WrapGeneratorT<void> {
    const { companyId, trailerId, updateTrailer } = action;

    yield put(updateTrailersRequestBegin(companyId));

    let result: ReturnApiT<typeof brokerTranziitApi.updateTrailer | typeof carrierTranziitApi.updateTrailer> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.updateTrailer(trailerId, updateTrailer);
    } else {
        result = yield brokerTranziitApi.updateTrailer(companyId, trailerId, updateTrailer);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    if (!error) {
        const detailsById: ReReturnT<typeof selectTrailersDetailsByIds> = yield select(
            selectTrailersDetailsByIds(companyId),
        );
        const trailer = detailsById[trailerId];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerSuccessfullyEdited,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});

    yield put(fetchTrailerDetails(trailerId, companyId, { isForceUpdate: true }));
}

function* importXLSTrailersSaga(action: ImportXLSTrailersActionT): WrapGeneratorT<void> {
    const { companyId, file } = action;

    yield put(importXLSTrailersRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.importPartnerXLSTrucks | typeof carrierTranziitApi.importXLSTrucks
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.importXLSTrailers(file);
    } else {
        result = yield brokerTranziitApi.importPartnerXLSTrailers(companyId, file);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(importXLSTrailersRequestError(error, companyId));
    } else {
        yield put(importXLSTrailersRequestSuccess(companyId));
    }
}

function* exportXLSTrailersSaga(action: ExportXLSTrailersActionT): WrapGeneratorT<void> {
    const { companyId, query } = action;

    yield put(exportXLSTrailersRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.exportPartnerXLSTrailers | typeof carrierTranziitApi.exportXLSTrailers
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.exportXLSTrailers(query);
    } else {
        result = yield brokerTranziitApi.exportPartnerXLSTrailers(companyId, query);
    }
    if (!result) {
        return;
    }
    const [error, data] = result;

    if (data) {
        downloadFile({
            name: `trailers-export-${moment().format('YYYY-MM-DD_HH:mm')}.xlsx`,
            data,
            type: 'application/octet-stream',
        });
    }

    if (error) {
        yield put(exportXLSTrailersRequestError(error, companyId));
    } else {
        yield put(exportXLSTrailersRequestSuccess(companyId));
    }
}

function* trailersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_TRAILERS_PAGE_REQUEST, fetchTrailersSaga);
    yield takeEvery(ADD_TRAILER_REQUEST, addTrailerSaga);
    yield takeEvery(DELETE_TRAILERS_REQUEST, deleteTrailersSaga);
    yield takeEvery(ACTIVATION_TRAILERS_REQUEST, activateTrailersSaga);
    yield takeEvery(ARCHIVE_TRAILERS_REQUEST, archiveTrailersSaga);
    yield takeEvery(ASSIGN_TO_CONTRACT_TRAILERS_REQUEST, assignToContractSaga);
    yield takeEvery(FETCH_TRAILER_DETAILS_REQUEST, fetchTrailerDetailsSaga);
    yield takeEvery(UPDATE_TRAILERS_REQUEST, updateTrailerSaga);
    yield takeEvery(FETCH_TRAILERS_STATS_REQUEST, fetchTrailersStatsSaga);
    yield takeEvery(IMPORT_XLS_TRAILERS_REQUEST, importXLSTrailersSaga);
    yield takeEvery(EXPORT_XLS_TRAILERS_REQUEST, exportXLSTrailersSaga);
}

export default trailersSaga;
