import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { QueryFiltersT, QueryFiltersKeysEnum } from '../query-models';
import { useTranslation } from 'react-i18next';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { useSelector } from 'react-redux';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import { formatDateRangeFilterValue, pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';
import TrailerStatus from 'common/components/status/TrailerStatus/TrailerStatus';
import CountriesFilterPill from 'common/components/filter-pills/CountriesFilterPill/CountriesFilterPill';
import { ALL_BROKER_USERS_VALUE } from 'common/components/dropdowns/UserMultipleDropdown/constants';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const dictTrailerById = useSelector(selectTrailersDictById);

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.blazeOrange,
                isMultiple: true,
                render: (queryFilters, index) => {
                    const selectedTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerTypeId]?.[index];

                    return <TrailerDictIdFilterPill isShortLabel trailerDictId={selectedTrailerId} />;
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerTypeId],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:trailers-page.filters.pills.plate-number', {
                        value: queryFilters[QueryFiltersKeysEnum.plateNumber],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.plateNumber],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                isMultiple: true,
                render: (queryFilters, index) => {
                    const countryCode = queryFilters[QueryFiltersKeysEnum.country]?.[index];

                    return <CountriesFilterPill countryCodes={countryCode ? [countryCode] : null} />;
                },
                clearKeys: [QueryFiltersKeysEnum.country],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                isMultiple: true,
                render: (queryFilters, index) => {
                    const status = queryFilters[QueryFiltersKeysEnum.status]?.[index];

                    return <TrailerStatus status={status} />;
                },
                clearKeys: [QueryFiltersKeysEnum.status],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                isMultiple: true,
                renderIcon: (queryFilters, index, isCompact) => {
                    if (queryFilters[QueryFiltersKeysEnum.createdByUserId]?.[index] === ALL_BROKER_USERS_VALUE) {
                        return pillIconRendererMap.logo();
                    }

                    return pillIconRendererMap.avatar(
                        queryFilters[QueryFiltersKeysEnum.createdByUserName]?.[index],
                        isCompact,
                    );
                },
                render: (queryFilters, index) => {
                    return t('common:trailers-page.filters.pills.added-by-user', {
                        value: queryFilters[QueryFiltersKeysEnum.createdByUserName]?.[index],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdByUserName, QueryFiltersKeysEnum.createdByUserId],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('common:trailers-page.filters.pills.added-dates', {
                        value: formatDateRangeFilterValue(createdDateFrom, createdDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                isMultiple: true,
                renderIcon: (queryFilters, index, isCompact) => {
                    if (queryFilters[QueryFiltersKeysEnum.lastModifyByUserId]?.[index] === ALL_BROKER_USERS_VALUE) {
                        return pillIconRendererMap.logo();
                    }

                    return pillIconRendererMap.avatar(
                        queryFilters[QueryFiltersKeysEnum.lastModifyByUserName]?.[index],
                        isCompact,
                    );
                },
                render: (queryFilters, index) => {
                    return t('common:trailers-page.filters.pills.last-modify-by-user', {
                        value: queryFilters[QueryFiltersKeysEnum.lastModifyByUserName]?.[index],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.lastModifyByUserName, QueryFiltersKeysEnum.lastModifyByUserId],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const lastModifyDateFrom = queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom];
                    const lastModifyDateTo = queryFilters[QueryFiltersKeysEnum.lastModifyDateTo];

                    return t('common:trailers-page.filters.pills.last-modify-dates', {
                        value: formatDateRangeFilterValue(lastModifyDateFrom, lastModifyDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.lastModifyDateFrom, QueryFiltersKeysEnum.lastModifyDateTo],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                isMultiple: true,
                render: (queryFilters, index) => {
                    const contractName = queryFilters[QueryFiltersKeysEnum.contractName]?.[index];
                    return <span>{contractName}</span>;
                },
                clearKeys: [QueryFiltersKeysEnum.contractId, QueryFiltersKeysEnum.contractName],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const lastTelematicUpdateDateFrom = queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateFrom];
                    const lastTelematicUpdateDateTo = queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateTo];

                    return t('common:trailers-page.filters.pills.last-telematic-update-dates', {
                        value: formatDateRangeFilterValue(lastTelematicUpdateDateFrom, lastTelematicUpdateDateTo),
                    });
                },
                clearKeys: [
                    QueryFiltersKeysEnum.lastTelematicUpdateDateFrom,
                    QueryFiltersKeysEnum.lastTelematicUpdateDateTo,
                ],
            },
        ],
        [t, dictTrailerById],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;
