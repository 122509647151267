import * as React from 'react';
import { useCallback, useMemo } from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import isEmpty from 'lodash/isEmpty';

import styles from './InfoTable.scss';
import ExpandIcon from 'common/icons/ExpandIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';

export type InfoTableRowT = {
    icon: React.ReactNode;
    name: React.ReactNode;
    hasBottomBorder?: boolean;
    isBoldName?: boolean;
    value?: React.ReactNode;
    isBoldValue?: boolean;
    emptyValue?: React.ReactNode;
    testSelector?: string;
    openedClassName?: string;
    closedClassName?: string;
    rightNode?: React.ReactNode;
    rightNodeEmptyValue?: React.ReactNode;
    isBoldRightNode?: boolean;
    isLoading?: boolean;
    isInitOpenRows?: boolean;
    rows?: Array<InfoTableRowT | null>;
};

export type InfoTableRowPropsT = {
    row: InfoTableRowT;
    className?: string;
    shouldRenderIcons?: boolean;
    nestIndex: number;
    shouldRenderIcon: boolean;
};

const InfoTableRow: React.FC<InfoTableRowPropsT> = React.memo((props) => {
    const { row, shouldRenderIcon, className, nestIndex } = props;

    const filteredSubRows = useMemo(() => {
        return row?.rows?.filter(isNonNil) || [];
    }, [row?.rows]);

    const isCollapsable = filteredSubRows.length > 0;

    const [isOpen, setOpen] = React.useState<boolean>(!!row?.isInitOpenRows);

    const toggle = useCallback(() => {
        setOpen((isOpen) => !isOpen);
    }, [setOpen]);

    const isEmptyValue = isEmpty(row.value) && isEmpty(row.rightNode);

    const rowFullTestSelector = `table_${row.testSelector}_row`;

    const isFullNameWidth = !row.emptyValue && !row.value;

    return (
        <>
            <div
                className={cs(
                    cx('grid__row', {
                        'grid__row--is-opened-first-row': isCollapsable && isOpen,
                    }),
                    className,
                    isOpen ? row.openedClassName : row.closedClassName,
                )}
                style={{
                    paddingLeft: `${30 * nestIndex}px`,
                }}
            >
                {shouldRenderIcon && (
                    <div
                        className={cx('grid__cell', 'grid__cell--isIcon')}
                        data-test-selector={`${rowFullTestSelector}_icon`}
                    >
                        {row.icon}
                    </div>
                )}
                <div
                    className={cx('grid__cell', 'grid__cell--isName', {
                        'grid__cell--hasBottomBorder': row.hasBottomBorder,
                        'grid__cell--isBold': row.isBoldName,
                        'grid__cell--isFixedName': !isFullNameWidth,
                    })}
                    data-test-selector={`${rowFullTestSelector}_name`}
                >
                    {row.name}
                </div>
                {(row.emptyValue || row.value || row.isLoading) && (
                    <div
                        className={cx('grid__cell', 'grid__cell--isValue', {
                            'grid__cell--hasBottomBorder': row.hasBottomBorder,
                            'grid__cell--isEmpty': isEmptyValue,
                            'grid__cell--isBold': row.isBoldValue,
                        })}
                        data-test-selector={`${rowFullTestSelector}_value`}
                    >
                        {row.isLoading ? (
                            <ControlLoaderIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.brandAccent} />
                        ) : (
                            <>{isEmptyValue ? row.emptyValue : row.value}</>
                        )}
                    </div>
                )}
                {(row.rightNodeEmptyValue || row.rightNode) && (
                    <div
                        className={cx('grid__cell', 'grid__cell--isRightNode', {
                            'grid__cell--isFlexContainer': true,
                            'grid__cell--hasBottomBorder': row.hasBottomBorder,
                            'grid__cell--isEmpty': isEmptyValue,
                            'grid__cell--isBold': row.isBoldRightNode,
                        })}
                        data-test-selector={`${rowFullTestSelector}_right-node`}
                    >
                        <>{isEmptyValue ? row.rightNodeEmptyValue : row.rightNode}</>
                    </div>
                )}
                {isCollapsable && (
                    <div
                        className={cx('grid__cell', 'grid__cell--isTrigger', {
                            'grid__cell--hasBottomBorder': row.hasBottomBorder,
                        })}
                        data-test-selector={`table_trigger-${row.testSelector}`}
                    >
                        <TransparentTrigger
                            spaces="xs"
                            onClick={toggle}
                            leftIcon={<ExpandIcon fillColor={StyleGuideColorsEnum.brandAccent} isInvert={isOpen} />}
                            reflectionTheme={ReflectionThemeEnum.light}
                        />
                    </div>
                )}
            </div>
            {isOpen && isCollapsable && <InfoTableRows rows={filteredSubRows} nestIndex={nestIndex + 1} />}
        </>
    );
});

export type InfoTableRowsPropsT = {
    shouldRenderIcons?: boolean;
    rows: Array<InfoTableRowT | null>;
    nestIndex: number;
};

const InfoTableRows: React.FC<InfoTableRowsPropsT> = React.memo((props) => {
    const { shouldRenderIcons, nestIndex, rows } = props;

    const filteredRows = useMemo(() => {
        return rows.filter(isNonNil);
    }, [rows]);

    const shouldRenderIcon = useMemo(() => {
        return shouldRenderIcons || filteredRows.some((row) => !!row.icon);
    }, [filteredRows, shouldRenderIcons]);

    if (isEmpty(filteredRows)) {
        return null;
    }

    return (
        <>
            {filteredRows.map((row, rowIndex) => {
                return (
                    <InfoTableRow key={rowIndex} row={row} nestIndex={nestIndex} shouldRenderIcon={shouldRenderIcon} />
                );
            })}
        </>
    );
});

export type PropsT = {
    shouldRenderIcons?: boolean;
    rows: Array<InfoTableRowT | null>;
    className?: string;
    testSelector?: string;
};

const cx = classNames.bind(styles);

const InfoTable: React.FC<PropsT> = React.memo((props) => {
    const { rows, shouldRenderIcons, className, testSelector } = props;

    const tableFullTestSelector = `${testSelector}_info-table`;

    const filteredRows = useMemo(() => {
        return rows.filter(isNonNil);
    }, [rows]);

    if (isEmpty(filteredRows)) {
        return null;
    }

    return (
        <div className={cs(cx('grid'), className)} data-test-selector={tableFullTestSelector}>
            <InfoTableRows shouldRenderIcons={shouldRenderIcons} rows={filteredRows} nestIndex={0} />
        </div>
    );
});

export default InfoTable;
