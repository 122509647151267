import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
};

const LinkDropOffIcon: React.FC<PropsT> = (props) => {
    const { strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path
                fill={strokeColor}
                fillRule="evenodd"
                d="M14.75 6.25c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75h-3v-1.425h3A2.327 2.327 0 0017.075 10a2.327 2.327 0 00-2.325-2.325h-3V6.25zm-.75 3v1.5h-3.001v-1.5H14zm-11.448 3.5h1.192V9c0-.412.338-.75.75-.75h3c.413 0 .75.338.75.75v3.75h1.193c.667 0 1.005.81.532 1.283l-3.442 3.442a.747.747 0 01-1.058 0l-3.442-3.443c-.473-.472-.143-1.282.525-1.282zm4.942-6.056a.752.752 0 00.75-.739V4a.752.752 0 00-.75-.75h-3a.752.752 0 00-.75.75v1.944c0 .413.338.75.75.75h3z"
            />
        </svg>
    );
};

LinkDropOffIcon.displayName = 'LinkDropOffIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.brandAccent,
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getLinkDropOffIconProps = (
    preset:
        | 'default'
        | 'assignment-normal'
        | 'assignment-error'
        | 'assignment-selected'
        | 'assignment-input-normal'
        | 'assignment-input-selected',
): PropsT => {
    switch (preset) {
        case 'assignment-normal': {
            return {
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
        case 'assignment-error': {
            return {
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-selected': {
            return {
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'assignment-input-normal': {
            return {
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
        case 'assignment-input-selected': {
            return {
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'default':
        default: {
            return {
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
    }
};

export { storyProps };
export default LinkDropOffIcon;
