import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './FullNameCell.scss';

type PropsT = {
    name?: string;
    surname?: string;
    fullName?: string;
};

const cx = classNames.bind(styles);

const FullNameCell: React.FC<PropsT> = React.memo((props) => {
    const { name, surname, fullName } = props;

    const content = [name, surname, fullName].filter(Boolean).join(' ');

    return <div className={cx('content')}>{content}</div>;
});

export default FullNameCell;
