import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    className?: string;
};

const FewCountriesIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, className } = props;

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M18 4a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h4zm-6 0a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h10z"
                fill={fillColor}
                fillRule="evenodd"
            />
        </svg>
    );
};

FewCountriesIcon.displayName = 'FewCountriesIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default FewCountriesIcon;
