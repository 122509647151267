import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import UpArrowIcon from 'common/icons/UpArrowIcon';
import DownArrowIcon from 'common/icons/DownArrowIcon';

import MaskedInput from 'react-text-mask';

import styles from './NumberInput.scss';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import UnitType from 'common/components/units/UnitType/UnitType';
import { simpleNumberFormatter } from 'common/utils/form-formatters';
import { safeArithmeticOperations } from 'common/utils/safe-arithmetic';

const cx = classNames.bind(styles);

type ValueT = string;

export type IconMetaT = {
    isDisabled: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasChanges: boolean;
};

export type PropsT = {
    name: string;
    value: ValueT;
    unitType?: UnitTypeEnum;
    step?: number;
    renderLeftIcon?: (meta: IconMetaT) => React.ReactNode;
    placeholder?: string;
    onChange: (value: ValueT) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    isDisabled?: boolean;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChanges?: boolean;
    mask?: MaskT;
    className?: string;
    inputClassName?: string;
};

const getControlColor = (isEmpty: boolean, isFocused: boolean): StyleGuideColorsEnum => {
    if (isFocused) {
        return StyleGuideColorsEnum.brandDark;
    }

    return StyleGuideColorsEnum.gray;
};

const CONTROL_SIZE = 19;

const NumberInput: React.FC<PropsT> = (props) => {
    const {
        value,
        name,
        hasChanges,
        unitType,
        step,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        renderLeftIcon,
        isDisabled,
        hasError,
        hasWarning,
        mask,
        className,
        inputClassName,
    } = props;

    const [isFocused, setFocused] = React.useState<boolean>(false);
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    const [isHovered, setHovered] = React.useState<boolean>(false);
    const handleMouseMove = (): void => {
        if (!isHovered) {
            setHovered(true);
        }
    };

    const handleMouseLeave = (): void => {
        setHovered(false);
    };

    const handleBlur = (): void => {
        if (isDisabled) {
            return;
        }

        setFocused(false);

        if (onBlur) {
            onBlur();
        }
    };

    const handleFocus = (): void => {
        if (isDisabled) {
            return;
        }

        setFocused(true);

        if (onFocus) {
            onFocus();
        }
    };

    const isEmpty = !value;
    const controlsColor = getControlColor(isEmpty, isFocused);

    const valueStep = step || 1;
    const handleIncrement = (): void => {
        if (isDisabled) {
            return;
        }

        const parsedValue = parseSimpleNumber(value) || 0;
        const nextValue = safeArithmeticOperations.plus(parsedValue, valueStep);
        const stringNextValue = simpleNumberFormatter(nextValue);
        if (onBlur) {
            onBlur();
        }
        onChange(stringNextValue);
    };

    const handleDecrement = (): void => {
        if (isDisabled) {
            return;
        }

        const parsedValue = parseSimpleNumber(value) || 0;
        const nextValue = safeArithmeticOperations.minus(parsedValue, valueStep);
        const stringNextValue = simpleNumberFormatter(nextValue);
        if (onBlur) {
            onBlur();
        }
        onChange(stringNextValue);
    };

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        name,
        type: mask ? 'text' : 'number',
        step: 'any',
        value: value || '',
        className: cx('input-inner', {
            'input-inner--isEmpty': isEmpty,
            'input-inner--isDisabled': isDisabled,
        }),
        placeholder,
        disabled: isDisabled,
    };

    const isHideValueControl = !step;
    return (
        <div className={cs(cx('wrap'), className)}>
            <div className={cx('content')}>
                <div
                    className={cx('input-wrap', {
                        'input-wrap--withValueControl': !isHideValueControl,
                    })}
                >
                    <div
                        className={cx(
                            'input',
                            {
                                'input--hasWarning': hasWarning,
                                'input--isChanged': hasChanges,
                                'input--hasError': hasError,
                                'input--isEmpty': isEmpty,
                                'input--hasIcon': !!renderLeftIcon,
                                'input--isFocused': isFocused,
                                'input--isHovered': isHovered,
                                'input--isDisabled': isDisabled,
                            },
                            inputClassName,
                        )}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        {renderLeftIcon && (
                            <div className={cx('icon')}>
                                {renderLeftIcon({
                                    isDisabled: !!isDisabled,
                                    hasError: !!hasError,
                                    hasWarning: !!hasWarning,
                                    hasChanges: !!hasChanges,
                                })}
                            </div>
                        )}
                        {mask ? <MaskedInput mask={mask} {...inputProps} /> : <input {...inputProps} />}
                        <div
                            className={cx('units', {
                                'units--isEmpty': isEmpty,
                                'units--isDisabled': isDisabled,
                                'units--isHideValueControl': isHideValueControl,
                            })}
                        >
                            {unitType && <UnitType type={unitType} />}
                        </div>
                    </div>
                </div>
                {!isHideValueControl && (
                    <div className={cx('controls')}>
                        <div
                            className={cx('controls__control', {
                                'controls__control--increment': true,
                                'controls__control--hasWarning': hasWarning,
                                'controls__control--hasError': hasError,
                                'controls__control--isEmpty': isEmpty,
                                'controls__control--isFocused': isFocused,
                                'controls__control--isHovered': isHovered,
                                'controls__control--isDisabled': isDisabled,
                            })}
                            onClick={handleIncrement}
                        >
                            <UpArrowIcon fillColor={controlsColor} width={CONTROL_SIZE} height={CONTROL_SIZE} />
                        </div>
                        <div
                            className={cx('controls__control', {
                                'controls__control--decrement': true,
                                'controls__control--hasWarning': hasWarning,
                                'controls__control--hasError': hasError,
                                'controls__control--isEmpty': isEmpty,
                                'controls__control--isFocused': isFocused,
                                'controls__control--isHovered': isHovered,
                                'controls__control--isDisabled': isDisabled,
                            })}
                            onClick={handleDecrement}
                        >
                            <DownArrowIcon fillColor={controlsColor} width={CONTROL_SIZE} height={CONTROL_SIZE} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NumberInput;
