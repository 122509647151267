import { TrailerStatusEnum } from 'common/utils/api/models';
import { CountryCodeT } from 'common/store/countries-dict/models';

export enum QueryFiltersKeysEnum {
    plateNumber = 'plateNumber',

    dictTrailerTypeId = 'dictTrailerTypeId',
    status = 'status',
    country = 'country',

    contractId = 'contractId',
    contractName = 'contractName',

    createdDateFrom = 'createdDateFrom',
    createdDateTo = 'createdDateTo',
    createdByUserId = 'createdByUserId',
    createdByUserName = 'createdByUserName',

    lastModifyDateFrom = 'lastModifyDateFrom',
    lastModifyDateTo = 'lastModifyDateTo',
    lastModifyByUserId = 'lastModifyByUserId',
    lastModifyByUserName = 'lastModifyByUserName',

    lastTelematicUpdateDateFrom = 'lastTelematicUpdateDateFrom',
    lastTelematicUpdateDateTo = 'lastTelematicUpdateDateTo',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.dictTrailerTypeId]: QueryParam.ArrayT<string>;

    [QueryFiltersKeysEnum.plateNumber]: QueryParam.StringT;
    [QueryFiltersKeysEnum.status]: QueryParam.ArrayT<TrailerStatusEnum>;

    [QueryFiltersKeysEnum.country]: QueryParam.ArrayT<CountryCodeT>;

    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByUserId]: QueryParam.ArrayT<string>;
    [QueryFiltersKeysEnum.createdByUserName]: QueryParam.ArrayT<string>;

    [QueryFiltersKeysEnum.contractId]: QueryParam.ArrayT<string>;
    [QueryFiltersKeysEnum.contractName]: QueryParam.ArrayT<string>;

    [QueryFiltersKeysEnum.lastModifyDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.lastModifyDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.lastModifyByUserId]: QueryParam.ArrayT<string>;
    [QueryFiltersKeysEnum.lastModifyByUserName]: QueryParam.ArrayT<string>;

    [QueryFiltersKeysEnum.lastTelematicUpdateDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.lastTelematicUpdateDateTo]: QueryParam.StringT;
}>;

export enum QueryKeysEnum {
    trailersPage = 'trailers-page',
    trailersSort = 'trailers-sort',
    trailersFilters = 'trailers-filters',
    trailersTab = 'trailers-tab',
}
