import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ManagementControl.scss';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useTranslation } from 'react-i18next';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { StyleGuideColorsEnum, UNIT } from 'common/constants';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import MoreIcon from 'common/icons/MoreIcon';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CloseIcon from 'common/icons/CloseIcon';
import EditIcon from 'common/icons/EditIcon';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { isNonNil } from 'common/utils';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    isShowAssignAssets: boolean;
    isAllowAssignAssets: boolean;
    onUnassignedAssets: () => void;
    onStartAssignAssets: () => void;
    isShowAssignDrivers: boolean;
    isAllowAssignDrivers: boolean;
    onUnassignedDrivers: () => void;
    onStartAssignDrivers: () => void;
};

const ManagementControl: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        transportOrderDetails,
        isShowAssignAssets,
        isAllowAssignAssets,
        onUnassignedAssets,
        onStartAssignAssets,
        isShowAssignDrivers,
        isAllowAssignDrivers,
        onUnassignedDrivers,
        onStartAssignDrivers,
    } = props;

    const { t } = useTranslation();

    const isSpotOrder = !!transportOrderDetails?.spotInfo;

    const disableChangeAssetsTooltipNode = (
        <TooltipContent width={30 * UNIT} isCenter theme={TooltipContentThemeEnum.black}>
            {t(
                isSpotOrder
                    ? 'common:transport-order-details.disabled-change-status-spot-order-tooltip'
                    : 'common:transport-order-details.disabled-change-status-dedicated-order-tooltip',
            )}
        </TooltipContent>
    );

    const disableChangeDriverTooltipNode = (
        <TooltipContent width={30 * UNIT} isCenter theme={TooltipContentThemeEnum.black}>
            {t('common:transport-order-details.disabled-change-drivers-tooltip')}
        </TooltipContent>
    );

    const managementOptions: Array<DropdownControlOptionT | SpecialOptionEnum | null> = [
        isShowAssignAssets
            ? {
                  label: (
                      <Tooltip
                          position={TooltipPositionEnum.centerRight}
                          theme={TooltipThemeEnum.black}
                          tooltipNode={isAllowAssignAssets ? null : disableChangeAssetsTooltipNode}
                      >
                          {() => (
                              <DropdownControlOptionLabel
                                  isDisabled={!isAllowAssignAssets}
                                  icon={<EditIcon />}
                                  label={
                                      <span className={cx('option')}>
                                          {t('common:transport-order-details.actions.manage-assets')}
                                      </span>
                                  }
                              />
                          )}
                      </Tooltip>
                  ),
                  onSelect: () => {
                      if (isAllowAssignAssets) {
                          onStartAssignAssets();
                      }
                  },
              }
            : null,
        isShowAssignAssets
            ? {
                  label: (
                      <Tooltip
                          position={TooltipPositionEnum.centerRight}
                          theme={TooltipThemeEnum.black}
                          tooltipNode={isAllowAssignAssets ? null : disableChangeAssetsTooltipNode}
                      >
                          {() => (
                              <DropdownControlOptionLabel
                                  isDisabled={!isAllowAssignAssets}
                                  icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                                  label={
                                      <span className={cx('option', 'option--clear')}>
                                          {t('common:transport-order-details.actions.clear-assets')}
                                      </span>
                                  }
                              />
                          )}
                      </Tooltip>
                  ),
                  onSelect: () => {
                      if (isAllowAssignAssets) {
                          onUnassignedAssets();
                      }
                  },
              }
            : null,
        isShowAssignAssets && isShowAssignDrivers ? SpecialOptionEnum.separator : null,
        isShowAssignDrivers
            ? {
                  label: (
                      <Tooltip
                          position={TooltipPositionEnum.centerRight}
                          theme={TooltipThemeEnum.black}
                          tooltipNode={isAllowAssignDrivers ? null : disableChangeDriverTooltipNode}
                      >
                          {() => (
                              <DropdownControlOptionLabel
                                  isDisabled={!isAllowAssignDrivers}
                                  icon={<EditIcon />}
                                  label={
                                      <span className={cx('option')}>
                                          {t('common:transport-order-details.actions.manage-drivers', {
                                              postProcess: 'interval',
                                              count: transportOrderDetails?.drivers?.length || 0,
                                          })}
                                      </span>
                                  }
                              />
                          )}
                      </Tooltip>
                  ),
                  onSelect: () => {
                      if (isAllowAssignDrivers) {
                          onStartAssignDrivers();
                      }
                  },
              }
            : null,
        isShowAssignDrivers
            ? {
                  label: (
                      <Tooltip
                          position={TooltipPositionEnum.centerRight}
                          theme={TooltipThemeEnum.black}
                          tooltipNode={isAllowAssignDrivers ? null : disableChangeDriverTooltipNode}
                      >
                          {() => (
                              <DropdownControlOptionLabel
                                  isDisabled={!isAllowAssignDrivers}
                                  icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                                  label={
                                      <span className={cx('option', 'option--clear')}>
                                          {t('common:transport-order-details.actions.clear-drivers', {
                                              postProcess: 'interval',
                                              count: transportOrderDetails?.drivers?.length || 0,
                                          })}
                                      </span>
                                  }
                              />
                          )}
                      </Tooltip>
                  ),
                  onSelect: () => {
                      if (isAllowAssignDrivers) {
                          onUnassignedDrivers();
                      }
                  },
              }
            : null,
    ].filter(isNonNil);

    if (!managementOptions?.length) {
        return null;
    }

    return (
        <DropdownControl
            className={className}
            options={managementOptions}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    leftIcon={<MoreIcon />}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.topRight}
        />
    );
});

export default ManagementControl;
