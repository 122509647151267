import React from 'react';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import debounce from 'lodash/debounce';
import { TranziitApiResultT } from 'common/utils/api/tranziit/base-tranziit-api';

export type AsyncRequestFactoryResultT<T extends {}> = TranziitApiResultT<Array<T>>;
export type AsyncRequestFactoryT<T extends {}> = (query: string) => Promise<AsyncRequestFactoryResultT<T>>;

export type UseAsyncOptionsParamsT<T extends {}> = {
    initialOptions?: Array<T> | null;
    requestFactory: AsyncRequestFactoryT<T>;
    allowEmptyQuery?: boolean;
};

export type UseAsyncOptionsResultT<T extends {}> = {
    options: Array<T>;
    requestStatus: RequestStatusT;
    onChangeQuery: (query?: string | null | undefined) => void;
};

const COLD_TIME = 300;

export const useAsyncOptionsRequest = <T extends {}>({
    initialOptions,
    requestFactory,
    allowEmptyQuery,
}: UseAsyncOptionsParamsT<T>): UseAsyncOptionsResultT<T> => {
    const lastSuggestionsQueryRef = React.useRef<string>('');
    const [currentRequestStatus, setCurrentRequestStatus] = React.useState<RequestStatusT>(requestStatus.initial);
    const [options, setOptions] = React.useState<T[]>(initialOptions || []);

    const handleChangeQuery = React.useCallback(
        (query?: string | null | undefined): void => {
            if (!query && !allowEmptyQuery) {
                setOptions([]);
                return;
            }

            setCurrentRequestStatus(requestStatus.loading);
            lastSuggestionsQueryRef.current = query || '';

            requestFactory(query || '')
                .then(([error, options]) => {
                    if (lastSuggestionsQueryRef.current !== query) {
                        // ignore lag requests
                        return;
                    }

                    if (error) {
                        setOptions([]);
                        setCurrentRequestStatus(requestStatus.setError(error));
                        return;
                    }

                    setOptions(options || []);
                    setCurrentRequestStatus(requestStatus.ok);
                })
                .catch((error) => {
                    if (lastSuggestionsQueryRef.current !== query) {
                        // ignore lag requests
                        return;
                    }

                    setOptions([]);
                    setCurrentRequestStatus(requestStatus.setError(error));
                });
        },
        [requestFactory, allowEmptyQuery],
    );

    return {
        options,
        requestStatus: currentRequestStatus,
        onChangeQuery: debounce(handleChangeQuery, COLD_TIME),
    };
};
