import React from 'react';

import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import TruckDetailsForm from './TruckDetailsForm/TruckDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAddTruckRequestStatus,
    selectTrucksDetailsByIds,
    selectUpdateTruckRequestStatus,
} from 'common/store/trucks/selectors';
import { addTruckRequest, updateTruck } from 'common/store/trucks/actions';
import { FormValuesT as DetailsFormValuesT } from './TruckDetailsForm/constants';
import { prepareCreateApiTruck, prepareUpdateApiTruck } from './prepare-api-truck';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import TruckStatusPill from 'common/components/status-pill/TruckStatusPill/TruckStatusPill';

type PropsT = {
    partnerId: PartnerIdT;
    truckId: TruckIdT | null;
    onGoBack?: () => void;
    onClose?: () => void;
    onCancel: () => void;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
    onOpenUserDetails: (userId: UserIdT, isBrokerUser?: boolean) => void;
};

const EditTruckSidebarContent: React.FC<PropsT> = (props) => {
    const {
        partnerId,
        onGoBack,
        onClose,
        truckId,
        onCancel,
        setNeedCloseConfirmation,
        onOpenTruckDetails,
        onOpenTrailerDetails,
        onOpenUserDetails,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updateRequestStatus = useSelector(selectUpdateTruckRequestStatus(partnerId));
    const addRequestStatus = useSelector(selectAddTruckRequestStatus(partnerId));
    const detailsById = useSelector(selectTrucksDetailsByIds(partnerId));

    const isNewTruck = !truckId;

    const handleSubmit = (detailsFormValues: DetailsFormValuesT) => {
        if (isNewTruck) {
            const truckRequest = prepareCreateApiTruck(detailsFormValues);
            if (!truckRequest) {
                return;
            }
            dispatch(addTruckRequest(truckRequest, partnerId));
        } else if (truckId) {
            const truckRequest = prepareUpdateApiTruck(detailsFormValues);
            if (!truckRequest) {
                return;
            }
            dispatch(updateTruck(truckId, truckRequest, partnerId));
        }
    };

    const truckDetails = detailsById[truckId as string];

    const title = isNewTruck
        ? t('common:trucks-page.add-truck.title')
        : t('common:trucks-page.edit-truck.title', {
              plateNumber: truckDetails?.plateNumber || '',
          });

    return (
        <>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={title}
                    afterTitleNode={<TruckStatusPill isSymmetrical status={truckDetails?.status || null} />}
                    onClose={onClose}
                    onGoBack={onGoBack}
                />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <TruckDetailsForm
                    partnerId={partnerId}
                    isLoading={updateRequestStatus.loading || addRequestStatus.loading}
                    isNewTruck={isNewTruck}
                    truckDetails={truckDetails}
                    onSubmit={handleSubmit}
                    onCancel={onCancel}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                    onOpenTruckDetails={onOpenTruckDetails}
                    onOpenTrailerDetails={onOpenTrailerDetails}
                    onOpenUserDetails={onOpenUserDetails}
                />
            </SideBarLayout>
        </>
    );
};

export default EditTruckSidebarContent;
